import React from 'react';
import LoginFormContainer from "../../login/LoginFormContainer";
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";


export const ConfirmationComplete = () => (
    <div className="container text-center margin-top-2x margin-bottom-4x">
        <Header title="Deriva / Confirmação / Sucesso"
                description="E-mail confirmado com sucesso"
        />
        <div className="panel no-pointer-events">
            <div className="panel-heading">
                <div className="panel-title"><i className="material-icons check_circle"/>&nbsp;E-mail confirmado com sucesso!</div>
            </div>
            <div className="panel-body">
                Seu e-mail foi confirmado com sucesso. Para entrar na sua conta, utilize o seu e-mail e senha abaixo.
            </div>
        </div>

        <div className="row">
            <div className="col-md-8 col-md-offset-2">
                <LoginFormContainer/>
            </div>
        </div>
    </div>
);