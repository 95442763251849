import AccountMenu from "../AccountMenu";
import Order from "./Order";
import React from "react";
import {useSelector} from "react-redux";
import OrderSelector from "../../../../selectors/OrderSelector";


export const OrderPage = ({match}) => {

    const order = useSelector((state) => OrderSelector.order(state, match.params.id));

    return <main>
        <section className="fw-section">
            <div className="container margin-top-2x">
                <div className="row">
                    <AccountMenu/>
                    <div className="col-md-10 col-sm-12">
                        <Order order={order}/>
                    </div>
                </div>
            </div>
        </section>
    </main>
}