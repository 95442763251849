import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import CartAction from "../../../actions/CartAction";
import {PayPalClient} from "../../../paypal/PayPalClient";
import {ClipLoader} from "react-spinners";
import {useSelector} from "react-redux";
import UserSelector from "../../../selectors/UserSelector";
import CartSelector from "../../../selectors/CartSelector";

const paypal = window.PAYPAL;

// function addEventListeners(callback)
// {
//     if (window.addEventListener) {
//         window.addEventListener("message", callback, false);
//     } else if (window.attachEvent) {
//         window.attachEvent("onmessage", callback);
//     } else {
//         throw new Error("Can't attach message listener");
//     }
// }


export const PayPalCard = forwardRef(({onComplete, onError}, ref) => {
    const user = useSelector(UserSelector.currentUser);
    const cart = useSelector(CartSelector.currentDetailed);
    const shipping = useSelector(CartSelector.shipping);
    const coupon = useSelector(CartSelector.coupon);

    const [loading, setLoading] = useState(false);
    const [paymentId, setPaymentId] = useState(null);
    const [approvalUrl, setApprovalUrl] = useState(null);
    const [ppp, setPpp] = useState(null);

    useEffect(() => {
        if (approvalUrl == null) {
            if (ppp != null) {
                setPpp(null);
            }

            if (!loading) {
                if (user == null || cart == null || shipping == null) return;

                PayPalClient.createPaymentRequest(user, cart, shipping, coupon)
                    .then(value => {
                        console.log(value);
                        setPaymentId(value.paymentId);
                        setApprovalUrl(value.approvalUrl);
                        setLoading(false);
                    });

                setLoading(true);
            }
        } else if (ppp == null) {
            // addEventListeners(handleMessage);

            const name = user.name.split(" ");

            const firstName = name.length === 0 ? user.name : name[0];
            const lastName = name.length === 0 ? user.name : name[name.length - 1];

            // const style = {
            //     "pppLabel": {
            //         "color": "#505050",
            //         "font-size": "15px",
            //         "font-family": "Arial",
            //         "font-style": "italic"
            //     },
            // }


            const mode = (process.env.NODE_ENV === 'production') ? "live" : "sandbox";

            setPpp(paypal.apps.PPP({
                approvalUrl: approvalUrl,
                placeholder: "ppplus",
                mode: mode,
                payerEmail: user.email,
                payerFirstName: firstName,
                payerLastName: lastName,
                payerTaxId: user.cpf,
                country: "BR",
                language: "pt_BR",
                merchantInstallmentSelectionOptional: true,
                rememberedCards: user.rememberedCards,
                onContinue: handleContinue,
                onError: handleError
                // css: style
            }));
        }
    });

    useImperativeHandle(ref, () => ({
        process() {
            ppp.doContinue();
        }
    }));


    // const handleMessage = function(event)
    // {
    //     try {
    //         let data = event.data;
    //
    //         if (typeof data === 'string' || data instanceof String)
    //         {
    //             data = JSON.parse(data);
    //             console.log(data);
    //
    //             if (data.action == "checkout")
    //             {
    //                 if (data.result.payment_approved)
    //                 {
    //                     onComplete(data.result);
    //                 }
    //             }
    //         }
    //
    //     } catch (exc)
    //     {
    //         console.error(exc);
    //     }
    // }

    const handleContinue = function (rememberedCards, payerId, token) {
        // console.log("onComplete");
        // console.log("rememberedCards:", rememberedCards);
        // console.log("payerId:", payerId);
        // console.log("token:", token);
        // console.log("paymentId:", paymentId);

        PayPalClient.executePayment(paymentId, payerId)
            .then(result => {
                if (!result)
                {
                    return;
                }

                onComplete({paymentId, payerId, rememberedCards});
            })
            .catch(handleError);
    }

    const handleError = function (error) {
        console.log("onError", error);
        onError(error);
    }


    return <>
        Para pagar com cartão de crédito, insira os dados abaixo.
        <div className="payment-option-loader">
            <ClipLoader
                color={"#aaa"}
                size={20}
                sizeUnits="px"
                loading={loading}
            />
        </div>
        <div id="ppplus">
        </div>
    </>;
});