import {GoogleAnalytics} from "./GoogleAnalytics";
import {MetaAnalytics} from "./MetaAnalytics";

export class Analytics {
    static providers = [];

    static GoogleId = "";
    static MetaId = "";

    static lastPage = "";

    static Init() {

        if (process.env.NODE_ENV === 'production') {
            this.GoogleId = 'G-KKSLHZCSNN';
            this.MetaId = '794070475869258';
        }
        else
        {
            this.GoogleId = 'G-HNF8BZDCH5'
            this.MetaId = '2128002270900699';
        }

        Analytics.providers = [
            new GoogleAnalytics(this.GoogleId),
            new MetaAnalytics(this.MetaId)
        ];
    }


    static PageView(title, path) {
        if (this.lastPage === path) return;
        this.lastPage = path;

        for (let i in Analytics.providers) {
            Analytics.providers[i].PageView(title, path);
        }
    }

    static Event(name) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].Event(name);
        }
    }

    static Login(method) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].Login(method);
        }
    }

    static Register(method) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].Register(method);
        }
    }


    static AddToCart(productVariationId, amount) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].AddToCart(productVariationId, amount);
        }
    }


    static RemoveFromCart(productVariationId, amount) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].RemoveFromCart(productVariationId, amount);
        }
    }

    static Contact(method) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].Contact(method);
        }
    }


    static SelectCoupon(code) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].SelectCoupon(code);
        }
    }


    static ViewCart() {
        for (let i in Analytics.providers) {
            Analytics.providers[i].ViewCart();
        }
    }


    static ViewProduct(product) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].ViewProduct(product);
        }
    }


    static SelectVariation(productVariationId) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].SelectVariation(productVariationId);
        }
    }


    static InitiateCheckout() {
        for (let i in Analytics.providers) {
            Analytics.providers[i].InitiateCheckout();
        }
    }


    static AddShippingInfo() {
        for (let i in Analytics.providers) {
            Analytics.providers[i].AddShippingInfo();
        }
    }


    static AddPaymentInfo(type) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].AddPaymentInfo(type);
        }
    }


    static Purchase(orderId) {
        for (let i in Analytics.providers) {
            Analytics.providers[i].Purchase(orderId);
        }
    }
}