import React from 'react';
import PropTypes from 'prop-types'

import {Url} from "../../util/Url";
import {Link} from "react-router-dom";


class CartTooltipProduct extends React.Component {

    static propTypes = {
        product: PropTypes.object,
        onClose: PropTypes.func,
    };


    render() {
        let product = this.props.product;

        let picture = product == null ? null : product.picturesShop[0];
        let link = product == null ? "" : Url.Shop.Product(product.link);
        let name = product == null ? null : product.name;
        let price = product == null ? null : product.price;
        let category = product == null ? null : product.patternString;


        return (
            <div className="widget cart-widget">
                <div className="widget-title">
                    Item adicionado ao carrinho!
                </div>

                <ul className="cart-list margin-bottom-2x">
                    <li>
                        <a href="product-gallery-left.html" className="cart-thumb">
                            <img src={picture} alt=""/>
                        </a>

                        <div className="info-cont">
                            <Link to={link} onClick={this.close} className="item-title tile-title">{name}</Link>

                            <div className="category">
                                {category}

                                <span className="cost">R$ {price}</span>
                            </div>
                        </div>
                    </li>
                </ul>

                <Link to={Url.Shop.Cart} onClick={this.props.onClose}
                      className="btn btn-default btn-block margin-right-none">
                    Ver Carrinho
                </Link>
                <Link to="#" onClick={this.props.onClose}
                      className="btn btn-default btn-block margin-right-none margin-bottom-1x">
                    Continuar Comprando
                </Link>
            </div>
        );
    };
}


export default CartTooltipProduct;
