

class ApplicationSelector {


    static initialized = state => {
        return state.application.initialized;
    };


    static instagram = state => {
        return state.application.instagram;
    };


    static formNewsletter = state => {
        return state.application.forms.newsletter;
    };


    static formContact = state => {
        return state.application.forms.contact;
    };


    static formLogin = state => {
        return state.application.forms.login;
    };


    static formRegistration = state => {
        return state.application.forms.registration;
    };


    static formConfirmation = state => {
        return state.application.forms.confirmation;
    };


    static formPasswordForgot = state => {
        return state.application.forms.passwordForgot;
    };


    static formPasswordReset = state => {
        return state.application.forms.passwordReset;
    };


    // static formCart = state => {
    //     return state.application.forms.cart;
    // };


    static formCartAddress = state => {
        return state.application.forms.cartAddress;
    };


    static formCartPayment = state => {
        return state.application.forms.cartPayment;
    };


}


export default ApplicationSelector;