import React from 'react';
import Media from 'react-media';
import {MediaCarousel} from "./MediaCarousel";



class Carousel extends React.Component {

    render() {
        return (
            <>
                <Media query="(max-width: 768px)" render={() => <MediaCarousel media="xs"/>}/>
                <Media query="(min-width: 768px) and (max-width: 991px)" render={() => <MediaCarousel media="sm"/>}/>
                <Media query="(min-width: 992px) and (max-width: 1199px)" render={() => <MediaCarousel media="md"/>}/>
                <Media query="(min-width: 1200px)" render={() => <MediaCarousel media="lg"/>}/>
            </>
        );
    }
}


export default Carousel;