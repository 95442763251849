import React from 'react';
import {PersonalEditor} from "./PersonalEditor";
import AccountMenu from "../AccountMenu";
import {Helmet} from "react-helmet";
import {Header} from "../../../../headers/Header";


class Main extends React.Component {

    render() {
        return (
            <main>
                <Header title="Deriva / Conta"
                        description="Gerencie sua conta"
                />
                <section className="fw-section">
                    <div className="container margin-top-2x">
                        <div className="row">
                            <AccountMenu/>
                            <div className="col-md-10 col-sm-12 margin-bottom-2x">
                                <h2 className="margin-bottom-1x">
                                    Dados pessoais
                                </h2>
                                <PersonalEditor/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default Main;