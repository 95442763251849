import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import SessionAction from "../../actions/SessionAction";
import UserSelector from "../../selectors/UserSelector";
import AccountMenu from "./AccountMenu";
import {History} from "../routes/History";
import {Url} from "../../util/Url";


class AccountMenuContainer extends React.Component {

    static propTypes = {
        currentUser: PropTypes.object,
        logout: PropTypes.func
    };


    render() {
        return <AccountMenu {...this.props}/>
    }
}


const mapStateToProps = (state) => {
    return {
        currentUser: UserSelector.currentUser(state)
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
        logout: () => {
            dispatch(SessionAction.logout()).then(() => {
                History.push(Url.Home);
            })
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(AccountMenuContainer);

