import React from 'react'
import {ProductDisplay} from "../../views/sections/product/ProductDisplay";


export const ShopItem = ({product}) => {

    return (
        <div className="col-xs-6 col-sm-4">
            <ProductDisplay product={product} variations={true}/>
        </div>
    );
}


export default ShopItem;