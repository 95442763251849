import React from "react";
import PropTypes from 'prop-types'
import {Redirect, Route} from "react-router-dom";
import Login from "../login/Login";


class AuthenticatedRoute extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object
    };


    static defaultProps = {
        currentUser: null
    };


    constructor(props)
    {
        super();

        this.state = { authenticated: props.currentUser != null };

        this.onLogin = this.onLogin.bind(this);
    }


    onLogin()
    {
        this.setState({ authenticated: true });
    }


    render() {
        const { authenticated } = this.state;
        const { component: Component, ...rest } = this.props;

        return (
            <Route
                {...rest}

                render={ props => authenticated ?
                    <Component {...props} />
                    :
                    <Login onSuccess={this.onLogin} />
                }
            />
        );
    }
}


export default AuthenticatedRoute;