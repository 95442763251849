import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ApplicationSelector from "../../../selectors/ApplicationSelector";

import Newsletter from "./Newsletter";
import ApplicationAction from "../../../actions/ApplicationAction";


class NewsletterContainer extends React.Component {

    static propTypes = {
        subscribed: PropTypes.bool
    };


    componentDidMount() {
    }


    componentDidUpdate(prevProps) {
        if (prevProps.subscribed !== this.props.subscribed) {
            this.render();
        }
    }


    render() {
        return <Newsletter onSubmit={this.props.onSubmit} subscribed={this.props.subscribed}/>;
    }
}


const mapStateToProps = (state) => {
    return {
        subscribed: ApplicationSelector.formNewsletter(state) === true
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
        onSubmit: (values) => {
            return dispatch(ApplicationAction.subscribeNewsletter(values.name, values.email))
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(NewsletterContainer);

