import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ApplicationSelector from "../../selectors/ApplicationSelector";
import ApplicationAction from "../../actions/ApplicationAction";

import Contact from "./Contact";
import Result from "./Result";


class ContactContainer extends React.Component {

    static propTypes = {
        contacted: PropTypes.bool,
        onSubmit: PropTypes.func,
        clear: PropTypes.func,
    };


    componentDidMount() {
    }


    componentWillUnmount() {
        this.props.clear();
    }


    componentDidUpdate(prevProps) {
        if (prevProps.contacted !== this.props.contacted) {
            this.render();
        }
    }


    render() {
        if (!this.props.contacted) {
            return <Contact onSubmit={this.props.onSubmit} contacted={this.props.contacted}/>;
        }

        return <Result/>;
    }
}


const mapStateToProps = (state) => {
    return {
        contacted: ApplicationSelector.formContact(state) === true
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
        onSubmit: (values) => {
            dispatch(ApplicationAction.contact(values.name, values.email, values.message))
        },
        clear: () => {
            dispatch(ApplicationAction.resetContact())
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(ContactContainer);

