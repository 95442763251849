import {createApiAction} from "./api/util";

import ApplicationActionType from "./ApplicationActionType";
import CollectionAction from "./CollectionAction";
import SessionAction from "./SessionAction";
import {Analytics} from "../analytics/Analytics";


class ApplicationAction {
    static isInitialized = false;

    static initialize = () => (dispatch, getState) => {

        if (ApplicationAction.isInitialized) {
            return dispatch(ApplicationAction.initialized());
        }

        return dispatch(CollectionAction.loadAll()).then(() => {
            dispatch(SessionAction.getCurrentUser()).then(() => {
                return dispatch(ApplicationAction.initialized());
            });
        });
    };


    static initialized = () => {
        ApplicationAction.isInitialized = true;

        return {
            type: ApplicationActionType.INITIALIZED
        }
    };


    static subscribeNewsletter(name, email) {

        let data = {
            name,
            email
        };

        Analytics.Event("subscribe");

        return createApiAction(ApplicationActionType.NEWSLETTER_SUBSCRIBE_PREFIX, 'POST', 'newsletter/subscribe', data);
    }


    static contact(name, email, message) {

        let data = {
            name,
            email,
            message
        };

        Analytics.Contact("Site");

        return createApiAction(ApplicationActionType.CONTACT_PREFIX, 'POST', 'contact', data);
    }


    static resetContact() {
        return {
            type: ApplicationActionType.CONTACT_RESET
        }
    }
}

export default ApplicationAction;