import {attr, fk, Model} from 'redux-orm';
import {schema} from "normalizr";

import {updateEntities} from "../reducers/util";
import SessionActionType from "../actions/SessionActionType";
import OrderActionType from "../actions/OrderActionType";
import {belongsTo} from "./util";



export class OrderItem extends Model
{
    static Schema = new schema.Entity('orderItems',
        {
        },
        belongsTo(['order', 'productVariation'])
    );


    static reducer(action, OrderItem, session)
    {
        const {payload, type} = action;

        switch (type) {
            case SessionActionType.LOGIN_SUCCESS:
                OrderItem.all().delete();
                updateEntities(payload, 'orderItems', OrderItem);
                break;

            case SessionActionType.LOGOUT_SUCCESS:
                OrderItem.all().delete();
                break;

            case OrderActionType.CREATE_SUCCESS:
            case OrderActionType.AUTHORIZE_SUCCESS:
                updateEntities(payload, 'orderItems', OrderItem);
                break;

            default:
                break;
        }
    }


    toString() {
        return `OrderItem: ${this.id}`;
    }


    getPriceTotal() {
        return this.amount * this.price;
    }
}


OrderItem.modelName = 'OrderItem';


OrderItem.fields = {
    id: attr(),
    orderId: attr(),
    productVariationId: attr(),
    amount: attr(),
    price: attr(),
    order: fk('Order', 'orderItems'),
    productVariation: fk('ProductVariation', 'orderItems'),
};


export default OrderItem;