import {createSelector} from 'redux-orm';

import orm from "../models";
import {entitiesSelector} from "./index";


class CollectionSelector {


    static featuredCollection = createSelector(orm, entitiesSelector,
        (session) => {

            const {Collection} = session;

            let collection = Collection.filter({featured : true}).first();

            if (collection == null) {
                return null;
            }

            return parseCollection(collection);
        }
    );


    static all = createSelector(orm, entitiesSelector,
        (session) => {

            const {Collection} = session;

            let collections = Collection.all();

            if (collections == null ) {
                return null;
            }

            return collections.toModelArray().map(collection => parseCollection(collection));
        }
    );


    // static collection = (state, id) => createSelector(schema, entitiesSelector, (session) => {
    //
    //     const {CollectionVoa} = session;
    //
    //     let collection = CollectionVoa.withId(id);
    //
    //     if (collection == null)
    //     {
    //         return null;
    //     }
    //
    //     return collection;
    // });
}

export default CollectionSelector;


const parseCollection = (collection) =>
{
    return Object.assign({}, collection.ref, {

            patterns: collection.patterns.toModelArray().map(pattern =>

                Object.assign({}, pattern.ref, {

                        products: pattern.products.toModelArray().map(product =>

                            Object.assign({}, product.ref)
                        )
                    }
                )
            )
        }
    );
};