import React from 'react'
import {connect} from 'react-redux'
import Orders from "./Orders";
import OrderSelector from "../../../../selectors/OrderSelector";


class OrdersContainer extends React.Component {

    static propTypes = {
    };


    render() {
        return <Orders {...this.props}/>;
    }
}


const mapStateToProps = (state) => {
    return {
        orders: OrderSelector.all(state)
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
    }
};


export default connect(mapStateToProps, mapPropsToDispatch)(OrdersContainer);

