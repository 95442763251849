import React from 'react';
import Media from 'react-media';
import {NavLink} from "react-router-dom";

import {Menu} from "semantic-ui-react";
import {Url} from "../../../util/Url";


class AccountMenu extends React.Component {

    render() {
        const items = [
            {
                name: "Meus dados",
                link: Url.Account.Main,
                exact: true
            },
            {
                name: "Alterar senha",
                link: Url.Account.Password,
                exact: true
            },
            {
                name: "Endereços",
                link: Url.Account.Addresses,
                exact: false
            },
            {
                name: "Pedidos",
                link: Url.Account.Orders,
                exact: false
            },
        ];

        const menuItems = (
            <>
                {items.map(x => (
                    <Menu.Item
                        key={x.link}
                        as={NavLink} exact={x.exact} to={x.link}
                        style={{lineHeight: "15px"}}
                    >
                        {x.name}
                    </Menu.Item>
                ))}
            </>
        );

        return (
            <div className="col-md-2 col-sm-12 margin-bottom-2x">
                <Media query="(min-width: 992px)">
                    {(matches) => {
                        const widths = matches ? null : 4;

                        return (
                            <Menu widths={widths} pointing={true} vertical={matches}>
                                {menuItems}
                            </Menu>
                        )
                    }}
                </Media>
            </div>
        );
    }
}

export default AccountMenu;