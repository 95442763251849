import FieldNormalizer from "../containers/util/FieldNormalizer";


export default class Postmon {

    static API_URL = "https://api.postmon.com.br/v1/";


    static zipcode(value)
    {
        value = FieldNormalizer.numeric(value);

        const url = Postmon.API_URL + "cep/" + value;

        return fetch(url).then(response => response.json());
    }


    static tracking(value)
    {
        const url = Postmon.API_URL + "rastreio/ect/" + value;

        return fetch(url).then(response => response.json());
    }
}