import React from 'react';
import {Header} from "../../headers/Header";

export const About = (props) => (
    <main>
        <Header title="Deriva / Sobre nós"
                description="Podemos dizer que essa história começa lá em 1989, ano de nascimento das gêmeas Lara e Gabriela. Nascimento também de uma relação de parceria e irmandade que deu origem à Deriva."
                image="https://www.somosderiva.com/img/about/current.jpg"
                keywords="deriva gabi lara estampa"
        />
        <div className="container margin-bottom-2x margin-top-2x">
            <h2 className="text-right margin-bottom-1x">
                Sobre nós
            </h2>

            <div className="row">
                <div className="col-md-6 margin-bottom-2x">
                    <img src="/img/about/old.jpg" alt=""/>
                    <img src="/img/about/current.jpg" alt=""/>
                </div>
                <div className="col-md-6">
                    <p>
                        Podemos dizer que essa história começa lá em 1989, ano de nascimento das gêmeas Lara e Gabriela.
                    </p>
                    <p>
                        Nascimento também de uma relação de parceria e irmandade que deu origem à Deriva.
                    </p>
                    <p>
                        Arquiteta de formação, Lara, desde a infância desenvolveu o gosto pelos trabalhos manuais e
                        artísticos.
                    </p>
                    <p>
                        O contato com a estamparia artesanal, durante uma disciplina eletiva na faculdade, revelou um
                        meio perfeito de expressão artística: a união entre a potência da arte e a leveza do tecido. A
                        partir daí, explorando diversas técnicas e possibilidades, estampar tecidos manualmente se
                        transformou em uma verdadeira paixão.
                    </p>
                    <p>
                        Foi quando Gabi enxergou potência e força na produção da irmã. Administradora, sempre teve
                        fascínio e admiração por produtos autorais com alma e com histórias para contar. Num domingo na
                        praia do Pina vestindo um dos tecidos pintados à mão pela irmã amarrado a cintura, Gabi teve
                        certeza do significado e propósito de transformar toda essa história em trabalho.
                    </p>
                    <p>
                        Juntas, as irmãs passaram então a se dedicar com paixão à criação da Deriva.
                    </p>
                    <p>
                        "Somos uma equipe pequena, uma dupla na maior parte do tempo. É puxado sim, mas vale a pena!
                        Através do nosso trabalho encontramos e nos conectamos com um monte de gente massa, uma rede de
                        profissionais e amigos que colaboram juntos para fazer tudo virar realidade."
                    </p>
                    <p>
                        Poder compartilhar com o mundo a nossa maneira de vestir e viver é o que faz vibrar nossos
                        corações.
                    </p>
                </div>
            </div>

            <div className="row margin-top-2x">
                <div className="col-md-12">
                    <h2 className="margin-bottom-1x">
                        O Conceito
                    </h2>
                    <p>
                        É como um passeio que flui sem pressa, sem destino predefinido ou objetivo aparente. No caminho
                        é onde tudo o que importa acontece. Basta se abrir às experiencias propiciadas pelo percurso e
                        descobrir um mundo de possibilidades. Não existem certezas. A Deriva é infinita....
                    </p>
                    <p>
                        A idéia de adaptação e movimento podem ser facilmente lidas nas nossas peças: a mesma camisa que
                        se usa para ir à praia, poder ser também vestida em outras ocasiões do dia a dia. Assim como o
                        panô pode enfeitar o sofá, a parede, a cintura ou a alma. Nada é estático nem engessado.
                    </p>
                    <p>
                        São peças feitas especialmente para pessoas inspiradas e que buscam afeto, emoção e liberdade no
                        vestir. Queremos inspirar a conexão da arte com o coletivo e também com a individualidade de
                        cada um. É valorizando as escolhas pessoais que podemos ser quem desejamos ser.
                    </p>
                    <p>
                        A Deriva se alicerça na diversidade de estampas, de cores, de pensamentos e de escolhas,
                        conciliando a leveza e a pluralidade de ser. Assim, o nosso trabalho só está completo com a
                        intervenção de quem escolhe usar Deriva - peças únicas que respiram VIDA.
                    </p>
                </div>
            </div>


            <div className="row margin-top-2x margin-bottom-1x">
                <div className="col-md-12">
                    <h2>
                        O Fazer
                    </h2>
                </div>
            </div>
            <div className="row margin-bottom-2x margin-top-1x">
                <div className="col-md-6 hidden-md hidden-lg margin-bottom-2x">
                    <img src="/img/about/work.jpg" alt=""/>
                </div>
                <div className="col-md-6">
                    <p>
                        Para nós, estampar tecidos é uma declaração do que a gente sente e quer comunicar ao mundo. É a
                        linguagem que usamos para expressar nossas crenças, ideias, gostos e estados de espírito.
                    </p>
                    <p>
                        Tudo começa com papel, tinta, tecido, pincéis, canetas... e muita inspiração. É a força criativa
                        em ação - de dentro para fora e de fora para dentro - através de experimentações e liberdade
                        criativa. Respeitando o tempo que o trabalho manual exige as estampas vão tomando forma e
                        ganhando vida.
                    </p>
                    <p>
                        A criação autoral permite misturar o repertório particular da marca a uma visão atual, sempre
                        buscando novas referências e bebendo de diferentes fontes. Procurando encontrar o equilíbrio
                        entre a nossa essência e o que acontece no momento ao nosso redor.
                    </p>
                    <p>
                        Deriva é liberdade de escolhas traduzidas em estampas.
                    </p>
                </div>
                <div className="col-md-6 hidden-sm hidden-xs">
                    <img src="/img/about/work.jpg" alt=""/>
                </div>
            </div>
        </div>
    </main>
);