import React from 'react'
import VideoFullscreen from "./VideoFullscreen";
import {AmuletosCarousel} from "./AmuletosCarousel";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";


export const CollectionAmuletos = () => (
    <main>
        <Header title="Deriva / Coleções / Amuletos"
                description="No clima de fim de ano preparamos a coleção AMULETOS: confiantes no desejo de sorte, proteção e prosperidade! FÉ é antídoto. AMOR é escudo. ESPERANÇA é dom."
                image="https://www.somosderiva.com/img/collection/amuletos/carousel/1.jpg"
                keywords="deriva amuleto estampa sorte camisa"
        />
        <AmuletosCarousel/>
        <div className="container margin-top-2x">
            <div className="row margin-bottom-1x">
                <div className="col-xs-12">
                    <h2>Amuletos</h2>
                </div>
            </div>
            <div className="row margin-bottom-1x">
                <div className="col-md-12">
                    <p>
                        De repente esse mundo já vai completar mais uma volta em torno do Sol...
                        E assim entendemos que todo fim também é recomeço; é energia revigorada; é folego novo.
                        Nos despedimos deste ano com os nossos corações repletos de amor e prontas para renovar nossos sonhos.
                    </p>
                    <p>
                        Mais um ano passou rapidinho, e a gente só consegue dizer muito obrigada! Estamos esperando um novo ano de peito aberto pra gente cuidar das coisas, das pessoas e, principalmente, da gente mesmo.
                    </p>
                </div>
            </div>
        </div>
        <div className="container-fluid margin-bottom-1x margin-top-2x padding-none">
            <div className="">
                <div className="col-md-6 col-xs-6 margin-bottom-1x col-fluid-left">
                    <img src="/img/collection/amuletos/01.jpg" alt=""/>
                </div>
                <div className="col-md-6 col-xs-6 margin-bottom-1x col-fluid-right">
                    <img src="/img/collection/amuletos/02.jpg" alt=""/>
                </div>
            </div>
        </div>
        <div className="container margin-top-2x">
            <div className="row margin-bottom-2x">
                <div className="col-md-12">
                    <p>
                        Foi nesse clima de fim de ano que preparamos nossa coleção AMULETOS: confiantes no desejo de sorte, proteção e prosperidade!
                        FÉ é antídoto.
                        AMOR é escudo.
                        ESPERANÇA é dom.
                    </p>
                    <p>
                        Amuletos, patuás... eles ilustram nossas estampas não apenas para nos lembrar de ter fé mas, principalmente, para que não nos esqueçamos: o talismã mais precioso que podemos carregar conosco são os nossos sonhos, nossas pessoas e sentimentos que habitam nosso coração e alma...
                        Abrace seu amuleto. Seja ele qual for.
                        Acredite. Um novo ano é sempre gigante.
                    </p>
                </div>
            </div>
        </div>
        <div className="container-fluid padding-none">
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/amuletos/bottom/1.jpg" alt=""/>
                </div>
            </div>
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/amuletos/bottom/2.jpg" alt=""/>
                </div>
            </div>
            <div className="margin-bottom-none">
                <div className="col-xs-12 padding-none">
                    <img src="/img/collection/amuletos/bottom/3.jpg" alt=""/>
                </div>
            </div>
        </div>
    </main>
);