import React from "react";
import {useDispatch, useSelector} from "react-redux";
import CartSelector from "../../../selectors/CartSelector";
import CartAction from "../../../actions/CartAction";
import {validateApiResponse} from "../../../actions/api/util";
import {CouponForm} from "./CouponForm";
import {CouponDescription} from "./CouponDescription";
import {History} from "../../routes/History";
import {Url} from "../../../util/Url";
import {CartTotalValues} from "./CartTotalValues";
import ButtonLoader from "../../util/ButtonLoader";
import {Analytics} from "../../../analytics/Analytics";


export const CartTotal = ({step, onContinue, paymentMethod, loading, disabled}) => {
    const cart = useSelector(CartSelector.current);
    const coupon = useSelector(CartSelector.coupon);
    const shipping = useSelector(CartSelector.shipping);
    const dispatch = useDispatch();

    if (cart == null) {
        return null;
    }

    const addCoupon = (values) => {
        return dispatch(CartAction.validateCoupon(values.code))
            .then(validateApiResponse);
    }

    const removeCoupon = () => {
        return dispatch(CartAction.removeCoupon());
    }

    const submitAction = coupon == null ? addCoupon : removeCoupon;

    const next = () => {
        switch (step) {
            case 0:
                Analytics.InitiateCheckout();
                History.push(Url.Shop.CartShipping);
                break;
            case 1:
                Analytics.AddShippingInfo();
                History.push(Url.Shop.CartPayment);
                break;
        }

        if (onContinue != null) {
            onContinue();
        }
    };

    loading = loading === true;
    disabled = disabled === true;

    return <div className="cart-total-container">
        <div className="row bg-gray padding-top-1x margin-top-1x">
            <div className="col-xs-12 col-md-10 col-md-offset-1">
                <div className="cart-total">
                    <div className="row">
                        <div className="col-xs-6">
                            <div style={step > 0 ? {visibility: "hidden"} : null}>
                                <CouponForm onSubmit={submitAction}/>
                                <CouponDescription coupon={coupon}/>
                            </div>
                        </div>
                        <div className="col-xs-6 text-right">
                            <CartTotalValues productsPrice={cart.price} coupon={coupon} shipping={step === 0 ? null : shipping}/>
                        </div>
                    </div>
                    <div className="cart-continue" style={{display: paymentMethod === "paypal" ? "none" : "flex"}}>
                        <button onClick={next} className="btn btn-dark-gray" disabled={disabled || loading}>
                            Continuar
                            <ButtonLoader loading={loading} color="black"/>
                        </button>
                    </div>
                    <div className="cart-continue" style={{display: paymentMethod === "paypal" ? "flex" : "none"}}>
                        <div id="paypal-buttons">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};