import React from 'react'
import {BeiraMarCarousel} from "./BeiraMarCarousel";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";


export const CollectionBeiraMar = () => (
    <main>
        <Header title="Deriva / Coleções / Beira Mar"
                description="Nossa nova coleção celebra a vida à beira mar. Ela nasceu de frente para o Atlântico - o oceano imenso que faz beira com o nosso litoral."
                image="https://www.somosderiva.com/img/collection/beiramar/IMG_1053.jpg"
                keywords="deriva estampa beira mar praia"
        />
        <BeiraMarCarousel/>
        <div className="container margin-top-2x">
            <div className="row margin-bottom-1x">
                <div className="col-xs-12">
                    <h2>Beira Mar</h2>
                </div>
            </div>
            <div className="row margin-bottom-1x">
                <div className="col-md-12">
                    <p>
                        Nossa nova coleção celebra a vida à beira mar.
                    </p>
                    <p>
                        Ela nasceu de frente para o Atlântico - o oceano imenso que faz beira com o nosso litoral. De
                        frente para o mar que conecta a praia de Piedade onde Lara mora, até a praia do Pina, morada de
                        Gabi. A Beira-Mar que sempre esteve diante dos nossos olhos agora se revela a vocês em formas e
                        cores através das nossas estampas.
                    </p>
                    <p>
                        Unimos o mar da memória ao mar presente na paisagem de hoje. No processo de criação mergulhamos
                        em umas das recordações mais queridas de nossas infâncias: nossas férias na praia. No início dos
                        anos 90, os tempos eram bem diferentes dos de hoje, mas aqueles dias de areia, sol e mar
                        permaneceram como uma lembrança muito viva e inspiradora.
                    </p>
                </div>
            </div>
        </div>
        <div className="container-fluid margin-bottom-1x margin-top-2x padding-none">
            <div className="">
                <div className="col-md-6 col-xs-6 margin-bottom-1x col-fluid-left">
                    <img src="/img/collection/beiramar/2-1.jpg" alt=""/>
                </div>
                <div className="col-md-6 col-xs-6 margin-bottom-1x col-fluid-right">
                    <img src="/img/collection/beiramar/2-2.jpg" alt=""/>
                </div>
            </div>
        </div>
        <div className="container margin-top-2x">
            <div className="row margin-bottom-2x">
                <div className="col-md-12">
                    <p>
                        Beira-Mar fala sobre uma vida lenta como aquela vivida nos veraneios dos tempos de criança. Foi
                        naquela época que aprendemos a reparar nas belezas que acontecem na beira da praia: desde a
                        grandeza do oceano até a miudeza de um grãozinho de areia. Educamos o olhar: sempre atento a
                        absorver as cores e formas daquele universo tropical. Quem nunca abriu um sorriso ao encontrar
                        na praia uma conchinha de beleza simples e rara? Pra gente as conchinhas são como verdadeiras
                        jóias oceânicas e motivo de inspiração.
                    </p>
                    <p>
                        Foi observando a impressão em alto relevo, gravada na areia pelo ir e vir das marés que surgiu a
                        primeira estampa da nossa coleção. A estampa Areia se inspira nessa textura: uma espécie de
                        fotografia efêmera que revela a dança das águas que passaram por ali.
                    </p>
                </div>
            </div>
        </div>
        <div className="container-fluid margin-bottom-1x margin-top-2x padding-none">
            <div className="">
                <div className="col-md-4 col-xs-4 margin-bottom-1x col-fluid-left">
                    <img src="/img/collection/beiramar/3-1.jpg" alt=""/>
                </div>
                <div className="col-md-4 col-xs-4 margin-bottom-1x col-fluid-middle">
                    <img src="/img/collection/beiramar/3-2.jpg" alt=""/>
                </div>
                <div className="col-md-4 col-xs-4 margin-bottom-1x col-fluid-right">
                    <img src="/img/collection/beiramar/3-3.jpg" alt=""/>
                </div>
            </div>
        </div>
        <div className="container margin-top-2x">
            <div className="row margin-bottom-2x">
                <div className="col-md-12">
                    <p>
                        A partir daí, seguimos nosso passeio pela praia e assim chegamos às estampas Jangada e
                        Beira-Mar. As duas trazem as linhas sinuosas das ondas que serpenteiam nossa orla. Para colorir,
                        tomamos emprestados os tons de vermelho, amarelo, verde, preto e azuis que pintam a madeira das
                        jangadas. Para completar a paisagem, faltava um pouco mais de mar e areia que estão presentes em
                        nosso listrado azul & camisetas que compõem nossa terceira coleção.
                    </p>
                    <p>
                        Esse é um convite para mergulhar no universo particular da nossa Beira-Mar que agora dividimos
                        com todos vocês.
                    </p>
                </div>
            </div>
        </div>
        <div className="container-fluid padding-none">
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/beiramar/IMG_1502.jpg" alt=""/>
                </div>
            </div>
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/beiramar/IMG_1288.jpg" alt=""/>
                </div>
            </div>
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/beiramar/IMG_1053.jpg" alt=""/>
                </div>
            </div>
            <div className="margin-bottom-none">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/beiramar/IMG_1844.jpg" alt=""/>
                </div>
            </div>
            <div className="margin-bottom-none">
                <div className="col-xs-12 padding-none">
                    <img src="/img/collection/beiramar/IMG_1810.jpg" alt=""/>
                </div>
            </div>
        </div>
    </main>
);