import React from 'react'
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import Validator, {formatError} from "../../util/validator";
import FieldComponent from "../util/FieldComponent";
import {Url} from "../../util/Url";
import {Link} from "react-router-dom";
import ButtonLoader from "../util/ButtonLoader";


class LoginForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func
    };


    static defaultProps = {
        onSubmit: x => {
            console.log(x);
        }
    };


    render() {
        const {handleSubmit, pristine, submitting} = this.props;

        return (
            <div>
                <img src="/img/login_01.png" style={{width: "auto"}} alt=""/>
                <h5>Entre na sua conta</h5>

                <div className="row margin-top-1x">
                    <div className="col-md-8 col-md-offset-2">
                        <form id="formLogin" onSubmit={handleSubmit}>

                            <div className="margin-bottom-1x">
                                <Field name="email" type="text" component={FieldComponent}
                                       className="form-control text-center"
                                       placeholder="E-mail" autoComplete="username"
                                       validate={[Validator.Required, Validator.Email]}
                                />
                            </div>

                            <div>
                                <Field name="password" type="password" component={FieldComponent}
                                       className="form-control text-center"
                                       placeholder="Senha" autoComplete="current-password"
                                       validate={[Validator.Required, Validator.LengthMin6]}
                                />
                            </div>

                            {formatError(this.props.error)}

                            <div className="margin-top-1x">
                                <button type="submit" className="btn btn-default btn-block margin-right-none"
                                        style={{width: "100%"}} disabled={pristine || submitting}>Entrar
                                    <ButtonLoader loading={submitting}/>
                                </button>
                            </div>
                        </form>
                        <div className="margin-top-2x text-left">
                            <Link to={Url.Account.ForgotPassword} className="underline-link pull-left">Esqueceu sua
                                senha?</Link>
                        </div>
                        <div style={{clear: "both"}}></div>
                        <div className="margin-top-1x text-left">
                            <Link to={Url.Account.ConfirmationResend} className="underline-link pull-left">Não recebeu o
                                e-mail de confirmação?</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


LoginForm = reduxForm({
    form: 'login'
})(LoginForm);


export default LoginForm;