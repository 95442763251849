import React from "react";

export const Pix = () => {

    return <>
        <p>
            Para criar o código PIX, clique em Continuar.
        </p>
        <p>
            Ao confirmar o pedido, o código QR Code é gerado automaticamente e expira em 10 minutos. Leia o QR Code pelo app do seu banco ou copie e cole a chave na opção PIX.
        </p>
        <p>
            A confirmação do pagamento é imediata.
        </p>
    </>
}