import React, {useEffect, useRef, useState} from "react";


export const Categories = ({title, subtitle, items}) => {
    const [init, setInit] = useState(false);
    const carousel = useRef();

    useEffect(() => {
        if (init) return;

        if (carousel.current != null) {

            const c = window.$(carousel.current);
            if (c == null) return;

            c.slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                nextArrow:
                    '<i class="slick-next material-icons chevron_right"></i>',
                prevArrow:
                    '<i class="slick-prev material-icons chevron_left"></i>',
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });

            setInit(true);
        }
    },);

    return <section className="fw-section">
        <h2 className="block-title text-center margin-bottom-2x">
            {title}
            {subtitle &&
                <small>{subtitle}</small>
            }
        </h2>

        <div className="items-carousel" data-slick='{"arrows": true, "dots": false, }' ref={carousel}>
            {items.map(x => (
                    <div className="tile tile-category" key={x.label}>
                        <a href={x.href} className="preview-box">
                            <img src={x.image} alt=""/>
                        </a>
                        <div className="tile-title">
                            <a href={x.href}>
                                {x.label}
                            </a>
                        </div>
                    </div>
                )
            )}
        </div>
    </section>;
}