import {ProgressBar, Step} from "react-step-progress-bar";
import React from "react";
import moment from "moment";


export const StepsBar = ({ current, steps }) => {

    const dateFormat = "DD/MM/YYYY";

    const progress = steps.length <= 1 ? 1 : current / (steps.length - 1) + 0.01;

    return (
        <ProgressBar percent={progress * 100} filledBackground="#4d4d4d" height={2}>
            {
                steps.map(step => <Step key={step.icon}>
                    {({accomplished}) => (
                        <div className={`order-step ${accomplished ? "accomplished" : ""}`}>

                            <i className={"material-icons " + step.icon}/>

                            <div className="order-step-date">
                                {step.date == null ? null : moment(step.date).format(dateFormat)}
                            </div>

                            <div className="order-step-title">
                                {accomplished ? step.titleComplete : step.titleStart}
                            </div>
                        </div>
                    )}
                </Step>)
            }
        </ProgressBar>
    );
}