import React, {useState} from 'react'
import ShopItem from "./ShopItem";
import ProductSelector from "../../selectors/ProductSelector";
import {useSelector} from "react-redux";
import CollectionSelector from "../../selectors/CollectionSelector";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";


const collectionsOrder = ["Amuletos","Voa","Caminhos do Jardim","Beira Mar","Matriz","Festejar"];

function distinct(value, index, self) {
    return self.indexOf(value) === index;
}


const computeFilter = (filter, target, checked) => {
    const i = filter.indexOf(target);

    if (checked) {
        if (i === -1) {
            filter = filter.concat([target]);
        }
    } else {
        if (i !== -1) {
            filter = filter.concat();
            filter.splice(i, 1);
        }
    }

    return filter;
};


function sortProductsByCollection(pA, pB) {
    return (collectionsOrder.indexOf(pB.pattern.collection.name) - collectionsOrder.indexOf(pA.pattern.collection.name)) * 1000 + (pB.patternId - pA.patternId);
}


export const Shop = () => {
    let collections = useSelector(CollectionSelector.all);
    const products = useSelector(ProductSelector.all);

    const [filterText, setFilterText] = useState(null);
    const [filterCollection, setFilterCollection] = useState([]);
    const [filterProduct, setFilterProduct] = useState([]);
    const [filterProductVariation, setFilterProductVariation] = useState([]);

    const collectionsAmounts = collections.map(x => x.patterns.flatMap(x => x.products).length);

    const productsNames = products.map(x => x.name).filter(distinct).sort();
    const productsAmounts = [];

    for (const i in productsNames) {
        const productName = productsNames[i];

        productsAmounts[i] = products.filter(x => x.name === productName).length;
    }


    const productVariations = products.flatMap(x => x.productVariations).map(x => x.size).filter(distinct);

    let filteredProducts = products;

    if (filterText !== "" && filterText != null) {
        const text = filterText;

        filteredProducts = filteredProducts.filter(x =>
            x.name.toLowerCase().indexOf(text) !== -1 ||
            x.pattern.name.toLowerCase().indexOf(text) !== -1 ||
            x.pattern.collection.name.toLowerCase().indexOf(text) !== -1
        );
    }

    if (filterCollection.length > 0) {
        filteredProducts = filteredProducts.filter(x => filterCollection.indexOf(x.pattern.collection.id) !== -1);
    }

    if (filterProduct.length > 0) {
        filteredProducts = filteredProducts.filter(x => filterProduct.indexOf(x.name) !== -1);
    }

    if (filterProductVariation.length > 0) {
        filteredProducts = filteredProducts.filter(x => {
            const available = x.productVariations.filter(y => {
                return filterProductVariation.indexOf(y.size) !== -1 && y.inventory > 0
            });

            return available.length > 0;
        });
    }

    const onChangeFilterCollection = (collection, checked) => {
        setFilterCollection(computeFilter(filterCollection, collection.id, checked));
    };

    const onChangeFilterProduct = (name, checked) => {
        setFilterProduct(computeFilter(filterProduct, name, checked));
    };

    const onChangeFilterProductVariation = (name, checked) => {
        setFilterProductVariation(computeFilter(filterProductVariation, name, checked));
    };

    const availableProducts = filteredProducts.filter(x => x.productVariations.some(x => x.inventory > 0));
    const unavailableProducts = filteredProducts.filter(x => !x.productVariations.some(x => x.inventory > 0));

    availableProducts.sort(sortProductsByCollection);
    unavailableProducts.sort(sortProductsByCollection);

    filteredProducts = availableProducts.concat(unavailableProducts);

    collections = collections.sort((a, b) => a.name.localeCompare(b.name));

    const shopItems = filteredProducts.map(x => <ShopItem key={x.id} product={x}/>);

    return (
        <main>
            <Header title="Deriva / Loja"
                    description="Conforto e originalidade traduzidos em peças únicas e estampas exclusivas. De Recife para todo o Brasil."
                    image="https://www.somosderiva.com/img/slider/3.jpg"
                    keywords="deriva loja roupa estampa vestido camisa blusa"
            />
            <div className="container margin-top-1x">
                <div className="row">

                    <div className="col-md-9 col-md-push-3 col-xs-12">
                        <div className="row">
                            {shopItems}
                        </div>
                    </div>

                    <div className="shop-filter col-md-3 col-md-pull-9 col-xs-12">
                        <div className="widget search">
                            <i className="icon material-icons search"/>
                            <input type="text" className="form-control input-sm" placeholder="Procurar"
                                   onChange={event => setFilterText(event.target.value)}/>
                        </div>

                        <div className="widget categories-widget margin-top-2x">
                            <div className="widget-title">
                                Cole&ccedil;&otilde;es
                            </div>

                            <ul className="cat-list">
                                {collections.map((collection, i) => (
                                    <li key={collection.id}>
                                        <label className="checkbox">
                                            <input type="checkbox"
                                                   onChange={event => onChangeFilterCollection(collection, event.target.checked)}/> &nbsp;{collection.name}
                                        </label>
                                        <span>{collectionsAmounts[i]}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="widget categories-widget margin-top-2x">
                            <div className="widget-title">
                                Produtos
                            </div>

                            <ul className="cat-list">
                                {productsNames.map((name, i) => (
                                    <li key={name}>
                                        <label className="checkbox">
                                            <input type="checkbox"
                                                   onChange={event => onChangeFilterProduct(name, event.target.checked)}/> &nbsp;{name}
                                        </label>
                                        <span>{productsAmounts[i]}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="widget tags-list-widget margin-top-2x margin-bottom-2x">
                            <div className="widget-title">
                                Tamanhos disponíveis
                            </div>

                            <div className="tags-list">
                                {productVariations.map(pv => {
                                    const checked = filterProductVariation.indexOf(pv) !== -1;
                                    const classname = "shop-filter-size " + (checked ? "active" : "");

                                    return <label key={pv} className={classname} onClick={() => onChangeFilterProductVariation(pv, !checked)}>
                                        <div className="label">
                                            {pv}
                                        </div>
                                    </label>;
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    );
};