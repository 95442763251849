import React from 'react'
import {StepsBar} from "../util/StepsBar";


export const CartProgress = ({current}) => {

    const steps = [
        {
            icon: "receipt",
            titleComplete: "Carrinho",
        },
        {
            icon: "local_shipping",
            titleStart: "Forma de Entrega",
            titleComplete: "Forma de Entrega",
        },
        {
            icon: "payment",
            titleStart: "Forma de pagamento",
            titleComplete: "Forma de pagamento",
        },
        {
            icon: "check_circle",
            titleStart: "Confirmação do pedido",
            titleComplete: "Pedido confirmado",
        }
    ];


    return <div className="cart-progress">
        <StepsBar steps={steps} current={current}/>
    </div>;

}