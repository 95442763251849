import React from 'react';
import {ImageCarousel} from "../../carousel/ImageCarousel";



export const MediaCarousel = ({media}) => {

    const images = [
        // `/img/slider/3-${media}.jpg`,
        `/img/slider/3.jpg`,
        `/img/slider/2.jpg`,
        `/img/slider/1-${media}.jpg`,
    ];

    return <ImageCarousel images={images} name={`home-carousel-${media}`} className="brgr"/>
};