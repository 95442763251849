import React from 'react';
import {RevolutionSlider} from "./RevolutionSlider";


export const ImageCarousel = ({images, name, className}) => {

    const options = {
        stopLoop: 'off',
        stopAfterLoops: -1,
        stopAtSlide: -1,

        sliderType: "standard",
        sliderLayout: "fullscreen",
        dottedOverlay: "none",
        disableProgressBar: "on",
        delay: 5000,
        navigation: {
            arrows: {
                enable: images != null && images.length > 1,
                hide_onleave: true
            },
            touch: {
                touchenabled: "on",
                swipe_treshold: 75,
                swipe_min_touches: 1,
                drag_block_vertical: false,
                swipe_direction: "horizontal"
            },
            onHoverStop: "off"
        },
        fullScreenOffsetContainer: ".header",
        fullScreenAutoWidth: "on",
        // fullScreenAlignForce: 'off',
        // lazyType: "all",

        // viewPort: {
        //     enable: true,
        //     outof: 'pause',
        //     visible_area: '60%',
        //     presize: false
        // },
    };

    return <RevolutionSlider name={name} options={options} className={className}>
        <ul>
            {images.map(src =>
                <li data-transition="fade" key={src}>
                    <img src={src} alt="" className="rev-slidebg"
                         data-bgposition="center center"
                         data-bgfit="cover"
                         data-bgrepeat="no-repeat"
                         data-kenburns="on"
                         data-duration="10000"
                         data-ease="Linear.easeNone"
                         data-scalestart="100"
                         data-scaleend="105"
                         data-offsetstart="0 0"
                         data-offsetend="0 0"
                         data-rotatestart="0"
                         data-rotateend="0"
                         data-basealign="slide"
                    />
                </li>
            )}
        </ul>
    </RevolutionSlider>;
};