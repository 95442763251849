import React from 'react'
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import Validator from "../../../util/validator";
import FieldComponent from "../../util/FieldComponent";
import ButtonLoader from "../../util/ButtonLoader";


class Newsletter extends React.Component {
    static propTypes = {
        subscribed: PropTypes.bool,
        onSubmit: PropTypes.func,
    };


    static defaultProps = {
        subscribed: false,
        onSubmit: x => {
            console.log(x);
        }
    };


    renderForm() {
        const {handleSubmit, pristine, submitting} = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-md-8 col-md-offset-2 col-xs-10 col-xs-offset-1 margin-bottom-1x">
                        <h4>Fique por dentro das nossas novidades</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 col-md-offset-2 margin-bottom-2x">
                        <h5 className="text-italic">
                            Se inscreve! Só enviaremos as notícias que realmente importam pra você.
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-xs-10 col-xs-offset-1">
                        <form onSubmit={handleSubmit}>

                            <Field name="name" type="text" component={FieldComponent}
                                   className="form-control text-center"
                                   placeholder="Digite seu nome"
                                   validate={[Validator.Required]}
                            />

                            <Field name="email" component={FieldComponent} type="text"
                                   className="form-control text-center margin-top-1x"
                                   style={{marginTop: "24px"}}
                                   placeholder="Digite seu e-mail"
                                   validate={[Validator.Required, Validator.Email]}
                            />

                            <div className="margin-top-1x">
                                <button type="submit" className="btn margin-right-none" disabled={pristine || submitting}>Assinar
                                    <ButtonLoader loading={submitting}/>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    }


    renderEnding() {
        return (
            <div className="col-md-6 col-md-offset-3">
                <h4 className="text-default margin-bottom-1x">Muito obrigada!</h4>
                <p className="text-italic">
                    Mandaremos notícias em breve. :)
                </p>
            </div>
        );
    }


    render() {
        let content = this.props.subscribed ? this.renderEnding() : this.renderForm();

        return (
            <section className="fw-section padding-top-3x padding-bottom-3x bg-white-smoke">

                <div className="container text-center">
                    {content}
                </div>
            </section>
        );
    }
}


Newsletter = reduxForm({
    form: 'newsletter'
})(Newsletter);

export default Newsletter;