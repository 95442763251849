import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import Validator, {formatError} from "../../../../util/validator";
import FieldComponent from "../../../util/FieldComponent";
import FieldNormalizer from "../../../util/FieldNormalizer";


class PersonalForm extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
    };


    static defaultProps = {
        user: null,
        onSubmit: x => {
            console.log(x);
        }
    };


    render() {
        const {handleSubmit, pristine, submitting, submitSucceeded, cart} = this.props;

        const colSize = cart ? "col-md-10 col-md-offset-1 col-xs-12" : "col-xs-12";

        const success = !submitSucceeded ? null : (
            <div className="panel no-pointer-events margin-bottom-1x">
                <div className="panel-heading">
                    <div className="panel-title"><i className="material-icons check_circle"/>&nbsp;Dados alterados com
                        sucesso!
                    </div>
                </div>
            </div>
        );

        return (
            <form id="formPersonal" onSubmit={handleSubmit}>
                {success}
                <div className="row">
                    <div className={colSize}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="margin-bottom-1x">
                                    <label>Nome completo *</label>
                                    <Field name="name" type="text" component={FieldComponent}
                                           className="form-control text-center"
                                           validate={[Validator.Required]}
                                    />
                                </div>

                                <div className="margin-bottom-1x">
                                    <label>E-mail *</label>
                                    <Field name="email" type="text" component={FieldComponent}
                                           className="form-control text-center"
                                           validate={[Validator.Required, Validator.Email]}
                                    />
                                </div>

                                <div className="margin-bottom-1x">
                                    <label>Telefone</label>
                                    <Field name="phone" type="text" component={FieldComponent}
                                           className="form-control text-center"
                                           normalize={FieldNormalizer.phone}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="margin-bottom-1x">
                                    <label>CPF *</label>
                                    <Field name="cpf" type="text" component={FieldComponent}
                                           className="form-control text-center"
                                           validate={[Validator.Required, Validator.Cpf]}
                                           normalize={FieldNormalizer.cpf}
                                    />
                                </div>

                                <div className="margin-bottom-1x">
                                    <label>Data de nascimento</label>
                                    <Field name="birthdayDay" type="text" component="input"
                                           className="form-control text-center input-birthday"
                                           placeholder="Dia"
                                           normalize={FieldNormalizer.day}
                                    />
                                    <Field name="birthdayMonth" type="text" component="input"
                                           className="form-control text-center input-birthday"
                                           placeholder="Mês"
                                           normalize={FieldNormalizer.month}

                                    />
                                    <Field name="birthdayYear" type="text" component="input"
                                           className="form-control text-center input-birthday"
                                           placeholder="Ano"
                                           normalize={FieldNormalizer.year}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cart-total-container">
                    <div className="row personal-buttons">
                        <div className={"margin-top-1x " + colSize}>
                            {formatError(this.props.error)}
                        </div>

                        {!cart ?
                            <div className="col-md-6">
                                <button type="submit" className="btn btn-default btn-block margin-right-none"
                                        disabled={pristine || submitting} style={{width: "100%"}}>
                                    Salvar
                                </button>
                            </div>
                            :
                            <div className={colSize}>
                                <button type="submit" className="btn btn-default margin-right-none"
                                        disabled={pristine || submitting} style={{float: "right"}}>
                                    Continuar
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </form>
        );
    }
}


PersonalForm = reduxForm({
    form: 'personal'
})(PersonalForm);


export default PersonalForm;