

export const Url = {

    Home: "/",

    Collection: (link) => `/${link}`,

    About: "/sobre",
    Contact: "/contato",
    Terms: "/termos",

    Account: {
        Login: "/entrar",

        Main: "/conta",
        Password: "/conta/alterar-senha",
        Addresses: "/conta/enderecos",
        Address: (id) => `/conta/enderecos/${id}`,
        Orders: "/conta/pedidos",
        Order: (id) => `/conta/pedidos/${id}`,

        Confirmation: "/conta/confirmacao",
        ConfirmationResend: "/conta/confirmacao/reenviar",
        ConfirmationSent: "/conta/confirmacao/enviada",
        ConfirmationComplete: "/conta/confirmada",

        ForgotPassword: "/conta/senha",
        PasswordReset: "/conta/senha/redefinir",
        PasswordSent: "/conta/senha/enviada",
        PasswordComplete: "/conta/senha/redefinida"
    },

    Shop: {
        Main: "/loja",
        Product: (link) => `/loja/${link}`,
        Cart: "/loja/carrinho",
        CartShipping: "/loja/carrinho/entrega",
        CartAddress: "/loja/carrinho/enderecos",
        CartPayment: "/loja/carrinho/pagamento",
        CartPaymentOld: "/loja/carrinho/pagamento-old"
    }


};