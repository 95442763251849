
export const belongsTo = (owner) => {
    return {
        processStrategy: (value, parent, key) => {
            let result = { ...value };

            if (Array.isArray(owner))
            {
                for (let o in owner)
                {
                    result[owner[o]] = value[`${owner[o]}Id`];
                }
            }
            else
            {
                result[owner] = value[`${owner}Id`];
            }

            return result;
        }
    }
};