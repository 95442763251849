import {RSAA} from "redux-api-middleware";

import InstagramActionType from "./InstagramActionType";
import ApplicationSelector from "../selectors/ApplicationSelector";
import {apiUrl} from "./api/util";


class InstagramAction {
    static URL = "https://cors-anywhere.herokuapp.com/https://www.instagram.com/";
    static USER = "somosderiva";


    static getFeed() {
        return (dispatch, getState) => {

            let feed = ApplicationSelector.instagram(getState());

            if (feed != null) {
                return feed;
            }

            return dispatch(this.loadFeed());
        }
    }


    static loadFeed() {
        return {
            [RSAA]: {
                endpoint: apiUrl("instagram"),
                method: 'GET',
                types: [
                    InstagramActionType.FEED_REQUEST,
                    {
                        type: InstagramActionType.FEED_SUCCESS,
                        payload: InstagramAction.parseFeed
                    },
                    InstagramActionType.FEED_FAILURE
                ]
            }
        }
    }


    static parseFeed(action, state, res)
    {
        return res.json().then(data => {
            return data;
        })
    }
}

export default InstagramAction;