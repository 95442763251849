import React, {useEffect, useRef} from "react";
import {Copyable} from "./Copyable";
import Barcode from "react-barcode";
import moment from "moment/moment";


export const PaymentDetailsBill = ({order}) => {
    const barcodeRef = useRef();

    useEffect(() => {
        if (barcodeRef.current != null) {
            const svg = barcodeRef.current.renderElementRef.current;

            if (svg != null) {
                svg.setAttribute("width", "100%");
            }
        }
    });

    const url = order.payment.file;

    const code = order.payment.barcode;
    const typableLine = order.payment.typableLine;

    const oldEnough = moment(order.payment.createdAt) < moment().add(-5, "minutes");

    let valid = (url != null && url !== "");

    if (!valid) {
        return <div className="col-xs-12 margin-top-1x">
            Para continuar com o pagamento, entre em contato conosco através do e-mail
            <a href="mailto:pedidos@somosderiva.com" target="_blank"
               rel="noopener noreferrer"> pedidos@somosderiva.com </a>
            ou do nosso
            <a href="https://wa.me/5581991919368" target="_blank"
               rel="noopener noreferrer"> WhatsApp (+55 81 99191-9368)</a>.
        </div>;
    }

    const downloadClassName = code == null ? "margin-top-1x" : "col-md-4 col-lg-5 margin-top-2x";

    return <div className="text-center">
        {code && <>
            <div className="col-xs-12 col-md-7 col-md-offset-1 col-lg-6 col-lg-offset-1 margin-top-1x">
                <Barcode value={code} displayValue={false} width={1.5} height={50} margin={10} ref={barcodeRef}/>
                <p>
                    Leia o código de barras ou copie e cole o código abaixo e efetue o pagamento pelo aplicativo ou site
                    do seu banco.
                </p>
                {!oldEnough &&
                    <p>
                        O boleto estará disponível para pagamento em até <span className="black">5 minutos</span>. Esse tempo é necessário para o
                        registro no sistema bancário.
                    </p>
                }
                {
                    typableLine &&
                    <Copyable value={typableLine} rows={1}/>
                }
            </div>
        </>
        }
        <div className={`col-xs-12 text-center ${downloadClassName}`}>
            <a href={url} target="_blank" rel="noopener noreferrer"><i className="material-icons cloud_download"
                                                                       style={{fontSize: 50, color: "gray"}}/></a><br/>
            <a href={url} target="_blank" rel="noopener noreferrer"><u>Clique aqui</u></a> para<br/>baixar o seu boleto.
        </div>
    </div>;
};