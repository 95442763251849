const PasswordActionType = {
    FORGOT_PREFIX: "FORGOT_PASSWORD",
    FORGOT_REQUEST: "FORGOT_PASSWORD_REQUEST",
    FORGOT_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_FAILURE: "FORGOT_PASSWORD_FAILURE",

    RESET_PREFIX: "RESET_PASSWORD",
    RESET_REQUEST: "RESET_PASSWORD_REQUEST",
    RESET_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_FAILURE: "RESET_PASSWORD_FAILURE"
};

export default PasswordActionType;