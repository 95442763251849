import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import FieldComponent from "../util/FieldComponent";
import Validator, {formatError} from "../../util/validator";
import FieldNormalizer from "../util/FieldNormalizer";
import ValidationError from "../util/ValidationError";
import Postmon from "../../util/Postmon";
import AddressAction from "../../actions/AddressAction";
import {validateApiResponse} from "../../actions/api/util";


let AddressForm = (props) => {
    const {onCancel} = props;
    const [loading, setLoading] = useState(false);


    const setFormData = (street = "", neighbourhood = "", city = "", state = "") => {
        props.change("street", street);
        props.change("number", "");
        props.change("complement", "");
        props.change("neighbourhood", neighbourhood);
        props.change("city", city);
        props.change("state", state);

        setLoading(false);
    }


    const onChangeZipcode = (event, newValue, previousValue) => {
        if (newValue === previousValue) {
            return;
        }

        if (newValue.length === 10) {
            setFormData();
            setLoading(true)

            Postmon.zipcode(newValue).then(result => {
                /**
                 bairro: "Boa Viagem"
                 cep: "51020231"
                 cidade: "Recife"
                 cidade_info: {area_km2: "218,435", codigo_ibge: "2611606"}
                 complemento: "de 191/192 ao fim"
                 estado: "PE"
                 estado_info: {area_km2: "98.076,001", codigo_ibge: "26", nome: "Pernambuco"}
                 logradouro: "Rua Professor Júlio Ferreira de Melo"
                 */
                setFormData(result.logradouro, result.bairro, result.cidade, result.estado);
            }).catch(() => {
                setFormData();
            })
        }
    }

    const {handleSubmit, pristine, submitting, cart} = props;
    const colSize = cart ? "col-md-10 col-md-offset-1 col-xs-12" : "col-xs-12";

    return <form id="formAddress" onSubmit={handleSubmit}>
        <Field name="id" type="hidden" component="input"/>

        <div className="row">
            <div className={colSize}>
                <div className="row">
                    <div className="margin-bottom-2x col-md-6">
                        <label>Nome do endereço *</label>
                        <Field name="name" type="text" component={FieldComponent}
                               className="form-control text-center"
                               validate={[Validator.Required]}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="margin-bottom-1x col-md-6">
                        <label>CEP *</label>
                        <Field name="zipcode" type="text" component={FieldComponent}
                               className="form-control text-center"
                               validate={[Validator.Required]}
                               normalize={FieldNormalizer.zipcode}
                               onChange={onChangeZipcode}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="margin-bottom-1x col-xs-9">
                        <label>Logradouro *</label>
                        <Field name="street" type="text" component={FieldComponent}
                               className="form-control text-center"
                               validate={[Validator.Required]}
                               disabled={loading}
                        />
                    </div>

                    <div className="margin-bottom-1x col-xs-3">
                        <label>Número</label>
                        <Field name="number" type="text" component={FieldComponent}
                               className="form-control text-center"
                               disabled={loading}
                        />
                    </div>
                </div>

                <div className="row">

                    <div className="margin-bottom-1x col-md-6">
                        <label>Complemento</label>
                        <Field name="complement" type="text" component={FieldComponent}
                               className="form-control text-center"
                               disabled={loading}
                        />
                    </div>

                    <div className="margin-bottom-1x col-md-6">
                        <label>Bairro</label>
                        <Field name="neighbourhood" type="text" component={FieldComponent}
                               className="form-control text-center"
                               disabled={loading}
                        />
                    </div>
                </div>
                <div className="row">

                    <div className="margin-bottom-1x col-md-6">
                        <label>Cidade *</label>
                        <Field name="city" type="text" component={FieldComponent}
                               className="form-control text-center"
                               validate={[Validator.Required]}
                               disabled={loading}
                        />
                    </div>

                    <div className="margin-bottom-1x col-md-6">
                        <label>Estado *</label>
                        <Field name="state" type="text" component={FieldComponent}
                               className="form-control text-center"
                               validate={[Validator.Required]}
                               disabled={loading}
                        />
                    </div>
                </div>
            </div>
        </div>

        <ValidationError>{props.error}</ValidationError>

        <div>
            <div className="row personal-buttons">
                <div className={"margin-top-1x " + colSize}>
                    {formatError(props.error)}
                </div>

                <div className={colSize}>
                    {!cart &&
                        <button type="button" className="btn btn-gray"
                                disabled={submitting} onClick={props.onCancel}>
                            Voltar
                        </button>
                    }
                    <button type="submit" className="btn btn-default margin-right-none"
                            disabled={pristine || submitting} style={{float: "right"}}>
                        {cart ? "Continuar" : "Salvar"}
                    </button>
                </div>
            </div>
        </div>
    </form>;
}


AddressForm = reduxForm({
    form: 'address',
})(AddressForm);


export default AddressForm;