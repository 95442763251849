import {createApiAction} from "./api/util";
import AddressActionType from "./AddressActionType";
import User from "../models/User";
import {Analytics} from "../analytics/Analytics";


class AddressAction {
    static upsert(id, name, zipcode, street, number, complement, neighbourhood, city, state) {

        let data = {
            address: {
                id,
                name,
                zipcode,
                street,
                number,
                complement,
                neighbourhood,
                city,
                state,
            }
        };

        Analytics.Event("update_address");

        return createApiAction(AddressActionType.UPSERT_PREFIX, 'POST', 'addresses', data, User.Schema);
    }


    static delete(id) {
        Analytics.Event("delete_address");

        return createApiAction(AddressActionType.DELETE_PREFIX, 'DELETE', `addresses/${id}`, null, User.Schema);
    }


    static setFavorite(id) {

        Analytics.Event("set_favorite_address");

        return createApiAction(AddressActionType.SET_FAVORITE_PREFIX, 'POST', `addresses/${id}/favorite`, null, User.Schema);
    }
}


export default AddressAction;