import {createSelector} from 'redux-orm';

import orm from "../models";
import {entitiesSelector} from "./index";


class ProductSelector {

    static all = createSelector(orm, entitiesSelector,
        (session) => {

            const {Product} = session;

            let products = Product.all();

            return products.toModelArray().map(product => {
                return parseProduct(product);
            });
        }
    );


    static product = (state, link) => createSelector(orm, entitiesSelector,
        (session) => {

            const {Product} = session;
            let product = Product.get({link: link});

            if (product == null) {
                return null;
            }

            return parseProduct(product);
        }
    )(state);


    static productByVariation = (state, productVariationId) => createSelector(orm, entitiesSelector,
        (session) => {

            const {ProductVariation} = session;

            let productVariation = ProductVariation.withId(productVariationId);

            if (productVariation == null) {
                return null;
            }

            return parseProduct(productVariation.product);
        }
    )(state);


    static productVariation = (state, productVariationId) => createSelector(orm, entitiesSelector,
        (session) => {

            const {ProductVariation} = session;

            return ProductVariation.withId(productVariationId);
        }
    )(state);
}

export default ProductSelector;


export const parseProduct = (product) => {
    if (product == null) {
        return null;
    }

    let collection = product.pattern == null || product.pattern.collection == null ? null : product.pattern.collection.ref;

    let pattern = product.pattern == null ? null : {
        ...product.pattern.ref,
        collection: collection,
    };

    return {
        ...product.ref,
        patternString: product.formatPattern(),
        pattern,
        productVariations: product.productVariations.toRefArray()
    }
};