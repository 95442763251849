import React from 'react';
import AccountMenu from "../AccountMenu";
import moment from "moment";
import {History} from "../../../routes/History";
import {Url} from "../../../../util/Url";
import OrderStatus from "../../../../models/OrderStatus";
import {Helmet} from "react-helmet";
import {Header} from "../../../../headers/Header";


class Orders extends React.Component {

    render() {
        return (
            <main>
                <Header title="Deriva / Conta / Pedidos"
                        description="Gerencie seus pedidos"
                />
                <section className="fw-section">
                    <div className="container margin-top-2x">
                        <div className="row">
                            <AccountMenu/>
                            <div className="col-md-10 col-sm-12 margin-bottom-2x">
                                <h2 className="margin-bottom-1x">
                                    Meus pedidos
                                </h2>
                                <div className="table-responsive margin-bottom-2x">
                                    <table className="table order-list">
                                        <thead>
                                        <tr>
                                            <th>Data</th>
                                            <th>Pedido</th>
                                            <th>Status</th>
                                            <th>Qtd. de itens</th>
                                            <th>Valor total</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            this.props.orders.map(order => {
                                                const date = moment(order.createdAt).format("DD/MM/YYYY");

                                                let status = <div className="text-center">Aguardando pagamento</div>;

                                                switch (order.status)
                                                {
                                                    case OrderStatus.payed:
                                                        status = <div className="text-center">Pagamento aprovado</div>;
                                                        break;

                                                    case OrderStatus.sent:
                                                        status = <div className="text-center">Pedido enviado</div>;
                                                        break;

                                                    case OrderStatus.delivered:
                                                        status = <div className="text-center">Entrega realizada</div>;
                                                        break;

                                                    case OrderStatus.cancelled:
                                                        status = <div className="text-center">Pedido cancelado</div>;
                                                        break;

                                                    case OrderStatus.returned:
                                                        status = <div className="text-center">Pedido retornado</div>;
                                                        break;
                                                }

                                                return (
                                                    <tr key={order.id} onClick={() => History.push(Url.Account.Order(order.id))}>
                                                        <td>{date.toLocaleString()}</td>
                                                        <td>{order.id}</td>
                                                        <td>
                                                            {status}
                                                        </td>
                                                        <td>{order.amount}</td>
                                                        <td>R$ {order.priceTotal}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default Orders;