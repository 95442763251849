import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import { apiMiddleware } from 'redux-api-middleware';

import reducer from '../reducers'

const configureStore = preloadedState => createStore(
    reducer,
    preloadedState,
    applyMiddleware(thunk, apiMiddleware)
);

export default configureStore;
