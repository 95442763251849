const ApplicationActionType = {
    INITIALIZED: "APP_INITIALIZED",

    NEWSLETTER_SUBSCRIBE_PREFIX: "NEWSLETTER_SUBSCRIBE",
    NEWSLETTER_SUBSCRIBE_REQUEST: "NEWSLETTER_SUBSCRIBE_REQUEST",
    NEWSLETTER_SUBSCRIBE_SUCCESS: "NEWSLETTER_SUBSCRIBE_SUCCESS",
    NEWSLETTER_SUBSCRIBE_FAILURE: "NEWSLETTER_SUBSCRIBE_FAILURE",

    CONTACT_PREFIX: "CONTACT",
    CONTACT_REQUEST: "CONTACT_REQUEST",
    CONTACT_SUCCESS: "CONTACT_SUCCESS",
    CONTACT_FAILURE: "CONTACT_FAILURE",
    CONTACT_RESET: "CONTACT_RESET"
};

export default ApplicationActionType;