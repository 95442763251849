import ReactGA from 'react-ga4';
import {store} from "../index";
import ProductSelector from "../selectors/ProductSelector";
import CartSelector from "../selectors/CartSelector";
import cartSelector from "../selectors/CartSelector";


export class GoogleAnalytics {


    constructor(id) {
        let debug = true;

        if (process.env.NODE_ENV === 'production') {
            debug = false;
        }

        ReactGA.initialize(id, {
            gtagOptions: {
                send_page_view: false,
                debug_mode: debug
            }
        });
    }


    PageView(title, path) {
        // ReactGA.ga("send", "pageview", {title, path});
        ReactGA.send({hitType: "pageview", page: path, title: title});
    }


    Event(name) {
        name = name.toLowerCase();

        console.log("[Google] " + name);
        ReactGA.event(name);
    }


    Login(method) {
        const data = {
            method
        };

        console.log("[Google] Login", data);
        ReactGA.event("login", data);
    }


    Register(method) {
        const data = {
            method
        };

        console.log("[Google] SignUp", data);
        ReactGA.event("sign_up", data);
    }


    Contact(method) {
        const data = {
            type: method
        };

        console.log("[Google] Contact", data);
        ReactGA.event("contact", data);
    }


    AddToCart(productVariationId, amount) {
        const state = store.getState();

        const product = ProductSelector.productByVariation(state, productVariationId);

        const data = {
            currency: "BRL",
            value: product.price * amount,
            items: [this.createItem(productVariationId, amount)]
        };

        console.log("[Google] AddToCart", data);
        ReactGA.event("add_to_cart", data);
    }


    RemoveFromCart(productVariationId, amount) {
        const state = store.getState();

        const product = ProductSelector.productByVariation(state, productVariationId);

        const data = {
            currency: "BRL",
            value: product.price * amount,
            items: [this.createItem(productVariationId, amount)]
        };

        console.log("[Google] RemoveFromCart", data);
        ReactGA.event("remove_from_cart", data);
    }


    SelectCoupon(code) {
        const data = {
            code
        };

        console.log("[Google] Select coupon", data);
        ReactGA.event('select_coupon', data);
    }


    createItem(productVariationId, amount) {
        const state = store.getState();

        const product = ProductSelector.productByVariation(state, productVariationId);
        const productVariation = ProductSelector.productVariation(state, productVariationId);

        return {
            item_id: productVariation.sku,
            item_name: product.name,
            item_brand: "Deriva",
            item_category: product.pattern.name,
            item_category2: product.pattern.collection.name,
            item_variant: productVariation.size,
            price: product.price,
            quantity: amount
        };
    }


    ViewCart() {
        const data = this.createCartData();

        ReactGA.event('view_cart', data);
    }


    ViewProduct(product) {

        const data = {
            currency: "BRL",
            value: product.price,
            items: [
                {
                    item_id: product.id,
                    item_name: product.name,
                    item_brand: "Deriva",
                    item_category: product.pattern.name,
                    item_category2: product.pattern.collection.name,
                    price: product.price
                }
            ]
        }

        ReactGA.event('view_item', data);
    }


    SelectVariation(productVariationId) {
        const state = store.getState();

        const productVariation = ProductSelector.productVariation(state, productVariationId);

        const data = {
            content_type: "size",
            content_id: productVariation.size,
            items: [this.createItem(productVariationId, 1)]
        }

        ReactGA.event('select_content', data);
    }


    InitiateCheckout() {
        const data = this.createCartData();

        ReactGA.event('begin_checkout', data);
    }


    AddShippingInfo() {
        const state = store.getState();

        const data = this.createCartData();

        const shipping = CartSelector.shipping(state);
        data.shipping_tier = shipping.name;

        ReactGA.event('add_shipping_info', data);
    }


    AddPaymentInfo(type) {
        const data = this.createCartData();

        data.payment_type = type;

        ReactGA.event('add_payment_info', data);
    }


    Purchase(orderId) {
        const data = this.createCartData();

        data.transaction_id = orderId;

        ReactGA.event('purchase', data);
    }


    createCartData()
    {
        const state = store.getState();

        const cart = CartSelector.currentDetailed(state);
        if (cart == null) return;

        const items = cart.cartItems.map(item => this.createItem(item.productVariationId, item.amount));

        const data = {
            currency: "BRL",
            value: cart.price,
            items: items
        }

        const coupon = CartSelector.coupon(state);

        if (coupon != null)
        {
            data.coupon = coupon.code;
        }

        return data;
    }
}