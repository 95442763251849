import React from 'react';
import PropTypes from 'prop-types'


class CartTooltipUpdated extends React.Component {

    static propTypes = {
        onClose: PropTypes.func,
    };


    render() {
        return (

            <div className="widget cart-widget">
                <div className="widget-title" style={{ margin: "0 12px"}}>
                    Carrinho atualizado com sucesso!
                </div>
            </div>
        );
    };
}


export default CartTooltipUpdated;
