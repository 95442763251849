import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import CartSelector from "../../selectors/CartSelector";
import {Link} from "react-router-dom";
import {Url} from "../../util/Url";
import {CartItem} from "./CartItem";
import CartAction from "../../actions/CartAction";
import {CartTotal} from "./total/CartTotal";
import {CartProgress} from "./CartProgress";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";
import {Analytics} from "../../analytics/Analytics";


export const Cart = () => {
    const cart = useSelector(CartSelector.currentDetailed);
    const [tracked, setTracked] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (cart == null) {
            dispatch(CartAction.getCurrent());
        }
    });

    if (cart == null || cart.cartItems == null || cart.cartItems.length === 0) {
        return renderEmptyCart();
    }

    if (!tracked) {
        Analytics.ViewCart();
        setTracked(true);
    }

    return (
        <main>
            <Header title="Deriva / Carrinho"
                    description="Seu carrinho"
            />
            <div className="container margin-top-2x">
                <div className="row">
                    <div className="col-xs-12">
                        <CartProgress current={0}/>
                    </div>
                    <div className="col-xs-12 col-md-10 col-md-offset-1">
                        <h2 className="margin-bottom-2x">
                            Meu carrinho
                        </h2>
                        <div className="margin-top-2x">
                            {cart.cartItems.map((x, i) => <div key={x.id} >
                                <div className="margin-top-1x">
                                    <CartItem cartItem={x}/>
                                </div>
                                <div className="margin-top-1x">
                                    {i === cart.cartItems.length - 1 ? null :
                                        <hr/>
                                    }
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
                <CartTotal step={0}/>
            </div>
        </main>
    );
}


function renderEmptyCart() {
    return <>
        <Header title="Deriva / Carrinho"
                description="Carrinho vazio"
        />
        <section className="fw-section margin-bottom-4x margin-top-2x">
            <div className="container text-center padding-top-3x padding-bottom-10x">
                <h2>Seu carrinho está vazio :(</h2>
                <p>Adicione novos itens na nossa loja.</p>
                <div className="margin-top-2x">
                    <Link to={Url.Shop.Main} className="btn btn-default margin-right-none">Loja</Link>
                </div>
            </div>
        </section>
    </>
}