import React from 'react';
import PropTypes from 'prop-types'
import CartSelector from "../../selectors/CartSelector";
import {connect} from "react-redux";
import CartTooltip from "./CartTooltip";


class CartTooltipContainer extends React.Component {

    static propTypes = {
        cartItemAdded: PropTypes.object,
        cartItemRemoved: PropTypes.object,
        updatedAt: PropTypes.object,
    };


    render() {
        return <CartTooltip {...this.props} />
    }
}


const mapStateToProps = (state) => {
    return {
        cartItemAdded: CartSelector.lastAddedCartItem(state),
        cartItemRemoved: CartSelector.lastRemovedCartItem(state),
        updatedAt: CartSelector.updatedAt(state),
    };
};


const mapPropsToDispatch = () => {
    return {};
};

export default connect(mapStateToProps, mapPropsToDispatch)(CartTooltipContainer);