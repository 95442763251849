import React from 'react'



export class ValidationError extends React.Component {


    render()
    {
        // if (this.props.children == null || React.Children.count(this.props.children) === 0)
        if (this.props.children == null)
        {
            return null;
        }

        return (
            <div className="input-error" {...this.props} >
                <i className="material-icons error"/>&nbsp;
                <div className="input-error error-text">{ this.props.children.toString() }</div>
            </div>
        );
    }

}

export default ValidationError;