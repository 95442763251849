import React from 'react';
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";


export const PasswordSent = () => (
    <main>
        <Header title="Deriva / Esqueci senha / Enviado"
                description="Você receberá um email com instruções para redefinir sua senha em breve"
        />
        <div className="container text-center margin-top-4x margin-bottom-10x">
            <div className="row">
                <div className="col-md-6 col-md-offset-3 col-xs-12">
                    <img src="/img/login_01.png" style={{width: "auto"}} alt=""/>
                    <h5>Redefina sua senha</h5>

                    <p>Você receberá um email com instruções para redefinir sua senha em breve.</p>
                </div>
            </div>
        </div>
    </main>
);