import UserSelector from "../../../selectors/UserSelector";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {CartProgress} from "../CartProgress";
import {AddressCard} from "../../address/AddressCard";
import {History} from "../../routes/History";
import {Url} from "../../../util/Url";
import CartSelector from "../../../selectors/CartSelector";
import {validateCPF} from "../../../util/validator";
import CartPersonal from "../personal/CartPersonal";
import {CartFirstAddress} from "../address/CartFirstAddress";
import CartAction from "../../../actions/CartAction";
import {ClipLoader} from "react-spinners";
import {CartTotal} from "../total/CartTotal";
import moment from "moment";
import 'moment/locale/pt-br';
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";


export const CartShipping = () => {
    const cart = useSelector(CartSelector.currentDetailed);
    const user = useSelector(UserSelector.currentUser);

    const [loading, setLoading] = useState(false);
    const shippingList = useSelector(CartSelector.shippingList);
    const shipping = useSelector(CartSelector.shipping);

    const dispatch = useDispatch();

    if (cart == null || cart.cartItems == null || cart.cartItems.length === 0) {
        History.push(Url.Shop.Cart);
        return;
    }

    if (!validateCPF(user.cpf)) {
        return <CartPersonal onBack={() => History.push(Url.Shop.Cart)}/>;
    }

    const address = cart.address;
    if (address == null) {
        return <CartFirstAddress/>;
    }


    const hasList = shippingList != null && shippingList.length > 0;

    if (!hasList) {
        if (!loading) {
            dispatch(CartAction.getShippingList());
            setLoading(true);
        }
    }


    const changeAddress = () => {
        History.push(Url.Shop.CartAddress);
    };

    const onChangeShipping = (value) => {
        dispatch(CartAction.setShipping(value));
    };


    const computeShipping = (method) => {
        if (!hasList) return null;

        return shippingList.filter(x => x.method === method).map(x => createShippingOption(x));
    };

    const createShippingOption = (value) => {
        if (value == null) return null;

        const selected = shipping != null && shipping.id === value.id;

        const deliveryDate = moment();
        deliveryDate.add(value.time, "days");
        deliveryDate.locale("pt-br");

        return <div key={value.name} className={`panel margin-bottom-1x ${selected ? "panel-selected" : ""}`}
                    onClick={() => onChangeShipping(value.id)}>
            <div className="panel-heading">
                <a className="panel-title no-icon">
                    {value.name}
                    <span className="pull-right">
                        R$ {value.price}
                    </span>
                    <div className="delivery-date">
                        <small>{value.time} dia{value.time > 1 ? "s" : ""} — {deliveryDate.format("D MMM")}</small>
                    </div>
                </a>
            </div>
        </div>;
    };


    return (
        <main>
            <Header title="Deriva / Carrinho / Entrega"
                    description="Escolha a forma de entrega"
            />
            <div className="container margin-top-2x">
                <div className="row">
                    <div className="col-xs-12">
                        <CartProgress current={1}/>
                    </div>
                    <div className="col-xs-12 col-md-10 col-md-offset-1">
                        <h2>
                            Forma de entrega
                        </h2>
                        <div className="margin-bottom-1x">
                            Escolha a forma de entrega para o endereço:
                        </div>
                        <div className="row margin-bottom-1x">
                            <div className="col-xs-12 col-sm-6 col-md-6">
                                <AddressCard
                                    address={address}
                                    onChange={changeAddress}
                                    selected={false}
                                />
                            </div>
                        </div>
                        <div>
                            <h4>
                                Entrega
                            </h4>
                            {!hasList ?
                                <ClipLoader
                                    color={"#aaa"}
                                    size={20}
                                    sizeUnits="px"
                                    loading={true}
                                />
                                :
                                computeShipping("delivery")
                            }
                            <div className="margin-bottom-1x"></div>
                            <h4>
                                Retirada
                            </h4>
                            {!hasList ?
                                <ClipLoader
                                    color={"#aaa"}
                                    size={20}
                                    sizeUnits="px"
                                    loading={true}
                                />
                                :
                                computeShipping("pickup")
                            }
                        </div>
                    </div>
                </div>
                <CartTotal step={1} disabled={shipping == null}/>
            </div>
        </main>
    );
};