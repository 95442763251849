import React, {useEffect, useRef, useState} from 'react'
import NumericInput from "react-numeric-input";
import {useDispatch, useSelector} from "react-redux";
import CartAction from "../../actions/CartAction";
import {validateApiResponse} from "../../actions/api/util";
// import ReactGA from "react-ga4";
import ProductSelector from "../../selectors/ProductSelector";
import {Confirm} from "semantic-ui-react";


export const CartItemAmount = ({cartItem}) => {
    const [confirmation, setConfirmation] = useState(false);
    const product = useSelector((s) => ProductSelector.productByVariation(s, cartItem.productVariationId));
    const dispatch = useDispatch();
    const input = useRef();

    useEffect(() => {
        if (input.current != null)
        {
            input.current.refsInput.setAttribute("disabled", true);
        }
    });

    const addItem = (k) => dispatch(CartAction.addItem(cartItem.productVariationId, k)).then(validateApiResponse);
    const removeItem = (k) => {
        if (cartItem.amount === k && !confirmation)
        {
            openConfirmation();
            return;
        }

        dispatch(CartAction.removeItem(cartItem.productVariationId, k)).then(validateApiResponse);
    }

    const openConfirmation = () => {
        setConfirmation(true);
    }

    const closeConfirmation = () => {
        setConfirmation(false);
    }

    const confirmDelete = () => {
        removeItem(cartItem.amount);
        closeConfirmation();
    }


    const productVariation = product.productVariations.find(x => x.id === cartItem.productVariationId);
    const maximum = Math.min(10, productVariation.inventory);


    const change = (value) => {

        if (value > maximum)
        {
            return;
        }

        let diff = value - cartItem.amount;

        if (diff === 0)
        {
            return;
        }

        if (diff > 0)
        {
            addItem(diff);
        }
        else
        {
            removeItem(-diff);
        }
    }

    const computeId = () => {
        return `cartItemAmount-${cartItem.id}`;
    }

    return (
        <div className="cart-item-amount">
            <Confirm open={confirmation} onCancel={closeConfirmation}
                     onConfirm={confirmDelete}
                     cancelButton="Cancelar" confirmButton="Remover"
                     content="Tem certeza que deseja remover esse item do carrinho?"
            />

            <NumericInput mobile className="form-control margin-bottom-none cart-item-amount" value={cartItem.amount}
                          min={0}
                          max={maximum}
                          onChange={change}
                          ref={input}
            />
        </div>
    );
};
