import React from 'react'
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";


export const Terms = () => (
    <main>
        <Header title="Deriva / Termos e condições"
        />
        <div className="container margin-bottom-2x">
            <div className="row margin-top-2x margin-bottom-1x">
                <div className="col-xs-12">
                    <h2>
                        Segurança &amp; Privacidade
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <p>
                        A Deriva armazena seu nome, CPF e endereço para emissão da nota fiscal. Para fins de
                        comunicação, também armazenamos e-mail, telefone e data de nascimento.
                    </p>
                    <p>
                        Não armazenamos os dados do seu cartão de crédito ou quaisquer dados sensíveis a
                        pagamento. O pagamento é feito através do PayPal, plataforma de processamento de
                        pagamentos online.
                    </p>
                    <p>
                        Nenhuma informação coletada será vendida ou cedida para terceiros.
                    </p>
                    <p>
                        Durante o seu processo de compra, te atualizaremos sobre o status do seu pedido por e-mail e
                        também no seu histórico de pedidos no nosso site. Dessa forma você estará sempre informado e
                        seguro sobre o seu processo de compra.
                    </p>
                </div>
            </div>
            <div className="row margin-top-2x margin-bottom-1x">
                <div className="col-xs-12">
                    <h2>
                        Envios
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <p>
                        O envio do seu pedido será realizado em até 3 dias úteis após a confirmação do pagamento.
                        A confirmação de pagamento para pagamentos via PayPal é de imediata. Para pagamentos via boleto,
                        transferência bancária ou depósito, acontece de 1 a 3 dias úteis. Quando o seu pedido for postado,
                        te enviaremos o código de rastreio para você acompanhar todo o caminho até a sua porta. Rapidinho chega aí :)
                    </p>
                    <p>
                        O prazo para entrega dos produtos varia de acordo com o local de entrega e tipo de envio -
                        que será através dos Correios e você poderá escolher entre Sedex ou PAC.
                    </p>
                    <p>
                        Em sua normalidade, os prazos dos Correios costumam variar da seguinte forma:
                        <ul className="margin-left-2x">
                            <li style={{color: "gray"}}>
                                Sedex - capitais de 2 a 5 dias úteis e interior em até 7 dias úteis, podendo chegar
                                antes.
                            </li>
                            <li style={{color: "gray"}}>
                                PAC - capitais de 4 a 7 dias úteis e interior em até 10 dias úteis, podendo chegar
                                antes.
                            </li>
                        </ul>
                    </p>
                    <p>
                        O valor do frete é calculado automaticamente no checkout e pode variar, dependendo do tipo
                        do envio, peso do produto e região escolhida para a entrega.
                    </p>
                    {/*<p>*/}
                    {/*Para entregas em Recife, trabalhamos com a opção de frete único e entregamos o seu pedido*/}
                    {/*em até 02 dias uteis.*/}
                    {/*</p>*/}
                    <p>
                        Se tiver com muita pressa avisa a gente aqui:&nbsp;
                        <a href="mailto:contato@somosderiva.com" target="_blank" rel="noopener noreferrer">
                            contato@somosderiva.com
                        </a>.
                    </p>
                </div>
            </div>
            <div className="row margin-top-2x margin-bottom-1x">
                <div className="col-xs-12">
                    <h2>
                        Trocas &amp; Devoluções
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <p>
                        Nossa política de troca disponibiliza 7 dias corridos após o recebimento do produto para
                        efetuação da solicitação de troca ou devolução. A solicitação de troca ou devolução de um
                        item deve ser feita pelo <a href="mailto:contato@somosderiva.com" target="_blank"
                                                    rel="noopener noreferrer">
                        contato@somosderiva.com
                    </a> com o número do pedido no assunto.
                    </p>
                    <p>
                        As condições serão aplicadas conforme abaixo:
                    </p>
                    <h5>
                        Desistência/Insatisfação
                    </h5>
                    <p>
                        É imprescindível que para os casos de troca e devolução as mercadorias sejam devolvidas no
                        mesmo estado de envio, com a embalagem completa com todos os itens enviados e sem
                        indício de uso. O custo do frete de reenvio é de responsabilidade da Deriva.
                    </p>
                    <p>
                        Não serão consideradas as devoluções que não estiverem de acordo com as exigências. Neste
                        caso, o produto devolvido será reenviado para o mesmo endereço de compra constando o
                        motivo da recusa, e custo do frete será de responsabilidade do cliente.
                    </p>
                    <p>
                        A restituição do valor será de acordo com a forma de pagamento escolhida pelo cliente no ato
                        da compra.
                    </p>
                    <h5>
                        Defeito
                    </h5>
                    <p>
                        Caso algum defeito tenha passado despercebido pelo nosso controle de qualidade, o cliente pode
                        optar por trocar o produto ou cancelar a
                        compra. É imprescindível que para os casos de troca e devolução as mercadorias sejam
                        devolvidas no mesmo estado de envio, com a embalagem completa com todos os itens
                        enviados e sem indício de uso. O custo do frete de reenvio é de responsabilidade da Deriva.
                    </p>
                    <p>
                        O reembolso é feito através do mesmo método de pagamento escolhido pelo cliente no ato da
                        compra. O prazo para restituição terá inicio após o recebimento dos itens devolvidos e devidas
                        análises das exigências citadas.
                    </p>
                    <p>
                        Qualquer dúvida, é só chamar! Estamos à disposição.
                    </p>
                </div>
            </div>
        </div>
    </main>
);