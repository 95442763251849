import React from 'react';
import {ClipLoader} from "react-spinners";

const ButtonLoader = (props) => {

    const color = props.color != null ? props.color : 'rgba(0, 0, 0, 0.75)';

    return (
        <div className="btn-loader" style={{color}}>
            <ClipLoader
                color={color}
                size={15}
                sizeUnits="px"
                loading={props.loading}
            />
        </div>
    )
};


export default ButtonLoader;