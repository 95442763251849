import React from 'react'
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import ValidationError from "../util/ValidationError";
import ProductShowcasePictures from "./ProductShowcasePictures";
import ButtonLoader from "../util/ButtonLoader";
import WashingInstructions from "./WashingInstructions";
import ReactHtmlParser from 'react-html-parser';
import {Measurement} from "./Measurement";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";
import {Analytics} from "../../analytics/Analytics";


class ProductShowcase extends React.Component {

    static propTypes = {
        cart: PropTypes.object,
        product: PropTypes.object,
        onSubmit: PropTypes.func,
        asyncError: PropTypes.object
    };



    handleVariationChange(el, value) {
        Analytics.SelectVariation(Number(value));
    }


    render() {
        const {product, cart, handleSubmit, submitting} = this.props;

        let patternName = product == null ? null : product.patternString;

        let availableProducts = product == null ? null : product.productVariations.filter(pv => {
            let inventory = pv.inventory;

            if (cart != null)
            {
                let cartItem = cart.cartItems.find(x => x.productVariationId === pv.id);

                if (cartItem != null)
                {
                    inventory -= cartItem.amount;
                }
            }

            return inventory > 0;
        });
        
        let available = availableProducts != null && availableProducts.length > 0;

        let variations = null;

        if (available) {
            let productVariations = availableProducts.map(pv => (
                <label key={pv.id} className="inline">
                    <Field name="product_variation_id" component="input" type="radio" value={pv.id.toString()} onChange={this.handleVariationChange}/>
                    <div className="label">
                        {pv.size}
                    </div>
                </label>
            ));

            variations = (
                <div className="radio-group size margin-top-1x">
                    <div className="title">Tamanhos disponíveis</div>
                    <div>
                        {productVariations}
                    </div>
                </div>
            );
        }
        else
        {
            variations = (
                <div className="bage margin-top-1x">INDISPONÍVEL</div>
            );
        }


        let error = !this.props.anyTouched ? null : <ValidationError>{this.props.error}</ValidationError>;

        return (
            <main>
                <Header title={"Deriva / Loja / " + patternName + " / " + product.name}
                        description={product.description}
                        image={product.picturesShop[0]}
                        keywords={product.name + " " + product.pattern.name + " " + product.pattern.collection.nanme}
                />
                <div className="margin-bottom-2x hidden-xs"/>
                <section className="fw-section margin-bottom-2x">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-7">
                                <ProductShowcasePictures pictures={product ? product.picturesShop : null} className={product && product.pattern.collection.name === "Voa" ? "brgr" : ""}/>
                            </div>
                            <div className="col-sm-5">
                                <div className="single-item-info">
                                    <div className="item-logo">
                                        <h2>{product ? product.name : null}</h2>
                                    </div>
                                    <div className="item-title">{patternName}</div>


                                    <div className="bages">
                                        {/*<span className="bage">Sale 50%</span>*/}

                                    </div>
                                    <div className="item-info">
                                        {product ? ReactHtmlParser(product.description) : null}
                                    </div>


                                    <form id="formBuy" onSubmit={handleSubmit}>

                                        <div className="">&nbsp;</div>
                                        {variations}
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="cost">
                                                    R$ {product ? product.price : null}
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-md-8">
                                                <div className="action-tools">
                                                    <button type="submit"
                                                            className="btn btn-gray right-icon margin-bottom-none"
                                                            disabled={submitting || !available}>
                                                        Comprar <i className="material-icons shopping_cart"/>
                                                        <ButtonLoader loading={submitting} color="black"/>
                                                    </button>
                                                    {error}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="margin-top-2x">
                                    <ul className="nav-tabs">
                                        <li className="active">
                                            <a href="#tab1" data-toggle="tab">
                                                Composição & Cuidados
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="#tab2" data-toggle="tab">
                                                Medidas
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        <div className="tab-pane transition fade active in" id="tab1">
                                            {product ? ReactHtmlParser(product.composition) : null}

                                            <div className="margin-top-1x">
                                                <WashingInstructions/>
                                            </div>
                                        </div>
                                        <div className="tab-pane transition fade" id="tab2">
                                            {product ? ReactHtmlParser(product.measurement) : null}

                                            <div className="margin-top-1x">
                                                <Measurement product={product}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}


const validate = values => {
    const errors = {};

    if (values.amount != null && values.product_variation_id == null) {
        errors._error = "Por favor, escolha um tamanho.";
    }

    return errors;
};

export default reduxForm({
    form: 'buy',
    validate,
})(ProductShowcase);