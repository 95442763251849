import {apiUrl, createApiAction, createApiHeaders} from "./api/util";
import {schema} from "normalizr";

import CartActionType from "./CartActionType";
import Cart from "../models/Cart";
import CartSelector from "../selectors/CartSelector";
import Shipping from "../models/Shipping";
import {getJSON, RSAA} from "redux-api-middleware";
import {camelizeKeys} from "humps";
import {Analytics} from "../analytics/Analytics";


class CartAction {

    static lastAddedProductVariationId = null;
    static lastRemovedProductVariationId = null;


    static addItem(productVariationId, amount) {
        this.lastAddedProductVariationId = productVariationId;

        Analytics.AddToCart(productVariationId, amount);

        const data = {
            product_variation_id: productVariationId,
            amount: amount,
        };

        return createApiAction(CartActionType.ADD_PREFIX, 'POST', 'cart/add.json', data, Cart.Schema);
    }


    static removeItem(productVariationId, amount)
    {
        this.lastRemovedProductVariationId = productVariationId;

        Analytics.RemoveFromCart(productVariationId, amount);

        const data = {
            product_variation_id: productVariationId,
            amount: amount,
        };

        return createApiAction(CartActionType.REMOVE_PREFIX, 'POST', 'cart/remove.json', data, Cart.Schema);
    }


    static setAddress(addressId)
    {
        Analytics.Event("select_address");

        const data = {
            id: addressId,
        };

        return createApiAction(CartActionType.ADDRESS_PREFIX, 'POST', 'cart/address.json', data, Cart.Schema);
    }


    static getShippingList()
    {
        return (dispatch, getState) => {
            const state = getState();

            const shipping = CartSelector.shippingList(state);
            const coupon = CartSelector.coupon(state);

            if (shipping != null && shipping.length > 0)
            {
                return Promise.resolve(shipping);
            }

            const cart = CartSelector.current(state);

            if (cart.address != null)
            {
                const couponCode = coupon == null ? null : coupon.code;

                return dispatch(this.loadShippingList(couponCode));
            }

            return Promise.resolve(null);
        }
    }


    static loadShippingList(coupon)
    {
        const data = {
            coupon: coupon,
        };

        return createApiAction(CartActionType.SHIPPING_PREFIX, 'POST', 'cart/shipping.json', data, new schema.Array(Shipping.Schema));
    }


    static setShipping(id)
    {
        return {
            type: CartActionType.SET_SHIPPING,
            payload: id
        }
    }


    // Relies on Redux Thunk middleware.
    static getCurrent() {
        return (dispatch, getState) => {

            const cart = CartSelector.current(getState());

            if (cart != null) {
                return Promise.resolve(cart);
            }

            return dispatch(CartAction.loadCurrent());
        }
    }


    static loadCurrent()
    {
        return createApiAction(CartActionType.CURRENT_PREFIX, 'POST', 'cart/current.json', null, Cart.Schema);
    }


    static setAddressError(value)
    {
        return {
            type: CartActionType.SET_ADDRESS_ERROR,
            payload: value
        }
    }


    static setPaymentError(value)
    {
        return {
            type: CartActionType.SET_PAYMENT_ERROR,
            payload: value
        }
    }


    static validateCoupon(code) {

        Analytics.SelectCoupon(code);

        const typeSuccess = {
            type: CartActionType.SET_COUPON_SUCCESS,
            payload: (action, state, res) => {
                return getJSON(res).then(camelizeKeys);
            }
        };

        const data = {
            code
        };

        let action = {
            endpoint: apiUrl('cart/coupon.json'),
            method: 'POST',
            headers: createApiHeaders(),
            types: [ CartActionType.SET_COUPON_REQUEST, typeSuccess, CartActionType.SET_COUPON_FAILURE ],
            body: JSON.stringify(data)
        };

        return {
            [RSAA]: action
        }
    }


    static removeCoupon()
    {
        return {
            type: CartActionType.SET_COUPON_SUCCESS,
            payload: null
        }
    }
}


export default CartAction;