import {ORM} from "redux-orm";

import Collection from "./Collection";
import Pattern from "./Pattern";
import Product from "./Product";
import User from "./User";
import Address from "./Address";
import ProductVariation from "./ProductVariation";
import Cart from "./Cart";
import CartItem from "./CartItem";
import Order from "./Order";
import OrderItem from "./OrderItem";
import Shipping from "./Shipping";
import PaypalPayment from "./PaypalPayment";


const orm = new ORM();

orm.register(
    Collection,
    Pattern,
    Product,
    ProductVariation,
    User,
    Address,
    Cart,
    CartItem,
    Shipping,
    Order,
    OrderItem,
    PaypalPayment,
);

export default orm;


