const AddressActionType = {
    UPSERT_PREFIX:  "ADDRESS_UPSERT",
    UPSERT_REQUEST: "ADDRESS_UPSERT_REQUEST",
    UPSERT_SUCCESS: "ADDRESS_UPSERT_SUCCESS",
    UPSERT_FAILURE: "ADDRESS_UPSERT_FAILURE",

    DELETE_PREFIX:  "ADDRESS_DELETE",
    DELETE_REQUEST: "ADDRESS_DELETE_REQUEST",
    DELETE_SUCCESS: "ADDRESS_DELETE_SUCCESS",
    DELETE_FAILURE: "ADDRESS_DELETE_FAILURE",

    SET_FAVORITE_PREFIX:  "SET_FAVORITE",
    SET_FAVORITE_REQUEST: "SET_FAVORITE_REQUEST",
    SET_FAVORITE_SUCCESS: "SET_FAVORITE_SUCCESS",
    SET_FAVORITE_FAILURE: "SET_FAVORITE_FAILURE",
};

export default AddressActionType;