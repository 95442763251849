import {combineReducers} from 'redux'

import {createReducer as ormReducer} from 'redux-orm';
import {reducer as formReducer} from 'redux-form'
import {createReducer, updateEntities} from "./util";
import orm from "../models";

import InstagramActionType from "../actions/InstagramActionType";
import ApplicationActionType from "../actions/ApplicationActionType";
import SessionActionType from "../actions/SessionActionType";
import RegistrationActionType from "../actions/RegistrationActionType";
import ConfirmationActionType from "../actions/ConfirmationActionType";
import PasswordActionType from "../actions/PasswordActionType";
import CartActionType from "../actions/CartActionType";
import CartAction from "../actions/CartAction";
import moment from "moment";
import UserSelector from "../selectors/UserSelector";
import OrderActionType from "../actions/OrderActionType";


// App State -----------------------------------------------------------------------------------------------------------


export function getUserFromLoginPayload(payload)
{
    const session = orm.mutableSession(orm.getEmptyState());
    const {User, Cart, CartItem, Order, OrderItem} = session;

    updateEntities(payload, 'users', User);
    updateEntities(payload, 'carts', Cart);
    updateEntities(payload, 'cartItems', CartItem);
    updateEntities(payload, 'orders', Order);
    updateEntities(payload, 'orderItems', OrderItem);

    return UserSelector.parseUser(User.withId(payload.result));
}


const currentUserId = createReducer("", {
    [SessionActionType.LOGIN_SUCCESS]: (state, action) => action.payload.result + "",
    [SessionActionType.LOGOUT_SUCCESS]: () => ""
});


const currentCartId = createReducer("", {
    [CartActionType.CURRENT_SUCCESS]: (state, action) => action.payload.result + "",
    [CartActionType.ADD_SUCCESS]: (state, action) => action.payload.result + "",
    [CartActionType.REMOVE_SUCCESS]: (state, action) => action.payload.result + "",
    [SessionActionType.LOGIN_SUCCESS]: (state, action) => {
        const user = getUserFromLoginPayload(action.payload);

        if (user == null || user.cart == null)
        {
            return "";
        }

        return user.cart.id;
    },
    [SessionActionType.LOGOUT_SUCCESS]: () => ""
});


const lastAddedProductVariationId = createReducer(null, {
    [CartActionType.CURRENT_REQUEST]: () => null,
    [CartActionType.REMOVE_REQUEST]: () => null,
    [CartActionType.ADD_REQUEST]: () => null,
    [CartActionType.ADD_SUCCESS]: () => CartAction.lastAddedProductVariationId
});


const lastRemovedProductVariationId = createReducer(null, {
    [CartActionType.CURRENT_REQUEST]: () => null,
    [CartActionType.REMOVE_REQUEST]: () => null,
    [CartActionType.ADD_REQUEST]: () => null,
    [CartActionType.REMOVE_SUCCESS]: () => CartAction.lastRemovedProductVariationId
});


const updatedAt = createReducer(null, {
    [CartActionType.CURRENT_REQUEST]: () => null,
    [CartActionType.REMOVE_REQUEST]: () => null,
    [CartActionType.ADD_REQUEST]: () => null,
    [CartActionType.ADD_SUCCESS]: () => moment(),
    [CartActionType.REMOVE_SUCCESS]: () => moment(),
    [OrderActionType.CREATE_FAILURE]: () => moment()
});


const shippingId = createReducer(null, {
    [CartActionType.SHIPPING_REQUEST]: () => null,
    [CartActionType.ADDRESS_REQUEST]: () => null,
    [CartActionType.SET_SHIPPING]: (state, action) => action.payload,
});


const shippingList = createReducer(null, {
    [CartActionType.SHIPPING_SUCCESS]: (state, action) => action.payload.result,
    [CartActionType.SHIPPING_REQUEST]: () => null,
    [CartActionType.ADDRESS_REQUEST]: () => null,
    [CartActionType.ADD_REQUEST]: () => null,
    [CartActionType.REMOVE_REQUEST]: () => null,
    [CartActionType.SET_COUPON_SUCCESS]: () => null,
    [SessionActionType.LOGIN_SUCCESS]: () => null,
    [SessionActionType.LOGOUT_SUCCESS]: () => null
});


const coupon = createReducer(null, {
    [CartActionType.SET_COUPON_SUCCESS]: (state, action) => action.payload,
    [CartActionType.SET_COUPON_FAILURE]: () => null,
    [SessionActionType.LOGOUT_SUCCESS]: () => null,
    [OrderActionType.CREATE_SUCCESS]: () => null
});


const cart = combineReducers({
    id: currentCartId,
    lastAddedProductVariationId,
    lastRemovedProductVariationId,
    updatedAt,
    shippingId,
    shippingList,
    coupon
});


const initialized = createReducer(false, {
    [ApplicationActionType.INITIALIZED]: () => true
});


const instagram = createReducer(null, {
    [InstagramActionType.FEED_SUCCESS]: (state, action) => action.payload
});


const newsletter = createReducer(false, {
    [ApplicationActionType.NEWSLETTER_SUBSCRIBE_SUCCESS]: () => true
});


const contact = createReducer(false, {
    [ApplicationActionType.CONTACT_SUCCESS]: () => true,
    [ApplicationActionType.CONTACT_RESET]: () => false,
});


const createErrorReducer = (prefix) => {
    return createReducer(null, {
        [prefix + "_REQUEST"]: () => null,
        [prefix + "_FAILURE"]: (state, action) => action.payload
    });
};


const login = combineReducers({
    error: createErrorReducer(SessionActionType.LOGIN_PREFIX)
});


const registration = combineReducers({
    error: createErrorReducer(RegistrationActionType.REGISTER_PREFIX)
});


const confirmation = combineReducers({
    error: createErrorReducer(ConfirmationActionType.CONFIRM_PREFIX)
});


const passwordForgot = combineReducers({
    error: createErrorReducer(PasswordActionType.FORGOT_PREFIX)
});


const passwordReset = combineReducers({
    error: createErrorReducer(PasswordActionType.RESET_PREFIX)
});

const formCartAddress = combineReducers({
    error: createErrorReducer(CartActionType.ADDRESS_PREFIX),
    loading: createReducer(false, {
        [CartActionType.SHIPPING_REQUEST]: () => true,
        [CartActionType.ADDRESS_REQUEST]: () => true,
        [CartActionType.SHIPPING_SUCCESS]: () => false,
        [CartActionType.SHIPPING_FAILURE]: () => false,
        [CartActionType.SET_ADDRESS_ERROR]: (state, action) => action.payload,
    })
});


const formCartPayment = combineReducers({
    error: createReducer(null, {
        [OrderActionType.CREATE_REQUEST]: () => null,
        [OrderActionType.CREATE_FAILURE]: (state, action) => action.payload.response,
        [CartActionType.SET_PAYMENT_ERROR]: (state, action) => action.payload,
    })
});


const forms = combineReducers({
    newsletter,
    contact,
    login,
    registration,
    confirmation,
    passwordForgot,
    passwordReset,
    cartAddress: formCartAddress,
    cartPayment: formCartPayment,
});


const application = combineReducers({
    initialized,
    instagram,
    forms,
    currentUserId,
    cart,
});


// Root ----------------------------------------------------------------------------------------------------------------

const reducer = combineReducers({
    application,
    entities: ormReducer(orm),
    form: formReducer
});


export default reducer;