import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import UserSelector from "../../../../selectors/UserSelector";
import RegistrationAction from "../../../../actions/RegistrationAction";
import PersonalForm from "./PersonalForm";
import moment from "moment";
import {validateApiResponse} from "../../../../actions/api/util";


export const PersonalEditor = ({onSave, onCancel, cart}) => {
    const user = useSelector(UserSelector.currentUser);
    const dispatch = useDispatch();

    if (user == null) return null;

    const save = (values) => {
        const day = values.birthdayDay;
        const month = values.birthdayMonth;
        const year = values.birthdayYear;

        let birthday = null;

        if (day != null && month != null && year != null)
        {
            birthday = moment().year(year).month(parseInt(month - 1)).date(day);
        }

        return dispatch(RegistrationAction.update(values.email, values.name, birthday, values.cpf, values.phone))
            .then(validateApiResponse)
            .then(onSave);
    };

    let day = null;
    let month = null;
    let year = null;

    if (user.birthday != null)
    {
        const date = moment(user.birthday);

        day = date.date();
        month = date.month();
        year = date.year();
    }

    const initialValues = {
        ...user,
        birthdayDay: day,
        birthdayMonth: (month + 1),
        birthdayYear: year,
    };

    return <PersonalForm cart={cart} onSubmit={save} onCancel={onCancel} initialValues={initialValues}/>;
};

