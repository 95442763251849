import React, {useState} from 'react';
import {AddressCard} from "./AddressCard";
import {Confirm} from "semantic-ui-react";

export const AddressList = ({user, selected, onEdit, onSelect, onDelete, onNew}) => {
    const [confirmation, setConfirmation] = useState(null);


    const openConfirmation = (address) => {
        setConfirmation(address);
    }

    const closeConfirmation = () => {
        setConfirmation(null);
    }

    const confirmDelete = () => {
        if (onDelete != null)
            onDelete(confirmation);

        closeConfirmation();
    }


    let instructions = null;
    let addresses = null;

    if (user == null || user.addresses == null || user.addresses.length === 0) {
        instructions = (
            <div className="col-xs-12 margin-bottom-2x">
                Você ainda não tem nenhum endereço cadastrado. Crie um novo endereço para sua conta agora!
            </div>
        );
    } else {
        addresses = user.addresses.map(x => (
            <div className="col-sm-6 margin-bottom-1x" key={x.id}>
                <AddressCard
                    address={x}
                    onEdit={onEdit}
                    onSelect={onSelect}
                    onDelete={openConfirmation}
                    selected={x.id === selected}
                />
            </div>
        ));
    }

    return (
        <div className="row">
            {instructions}
            {addresses}

            <div className="col-sm-6 col-xs-12 margin-bottom-1x">
                <div className="address address-card-clickable text-center" onClick={onNew}>
                    <div>
                        <h1>
                            <i className="material-icons add"/>
                        </h1>
                        <h5>
                            Novo endereço
                        </h5>
                    </div>
                </div>
            </div>

            <Confirm open={confirmation != null} onCancel={closeConfirmation}
                     onConfirm={confirmDelete}
                     cancelButton="Cancelar" confirmButton="Remover"
                     content="Tem certeza que deseja remover esse endereço?"
            />
        </div>
    );
};