import {attr, fk, Model} from 'redux-orm';
import {schema} from "normalizr";

import {updateEntities} from "../reducers/util";
import SessionActionType from "../actions/SessionActionType";
import OrderActionType from "../actions/OrderActionType";
import Address from "./Address";
import OrderItem from "./OrderItem";
import {belongsTo} from "./util";
import Shipping from "./Shipping";
import PaypalPayment from "./PaypalPayment";


export function computeDiscountProducts(price, coupon) {

    let discount = 0;

    if (coupon != null) {
        if (coupon.discountPercentage > 0) {
            discount += price * coupon.discountPercentage;
        }

        if (coupon.discountValue > 0) {
            discount += coupon.discountValue;
        }

        discount = Math.min(discount, price);
    }

    return discount;
}


export class Order extends Model
{
    static Schema = new schema.Entity('orders',
        {
            orderItems: new schema.Array(OrderItem.Schema),
            address: Address.Schema,
            shipping: Shipping.Schema,
            payment: PaypalPayment.Schema,
        },
        belongsTo(['user'])
    );


    static reducer(action, Order, session)
    {
        const {payload, type} = action;

        switch (type) {
            case SessionActionType.LOGIN_SUCCESS:
                Order.all().delete();
                updateEntities(payload, 'orders', Order);
                break;

            case SessionActionType.LOGOUT_SUCCESS:
                Order.all().delete();
                break;

            case OrderActionType.CREATE_SUCCESS:
            case OrderActionType.AUTHORIZE_SUCCESS:
                updateEntities(payload, 'orders', Order);
                break;

            default:
                break;
        }
    }


    toString() {
        return `Order: ${this.id}`;
    }


    getPriceShipping()
    {
        if (this.shipping == null)
        {
            return 0;
        }

        return this.shipping.price;
    }


    getPriceProducts() {
        if (this.orderItems == null)
        {
            return 0;
        }

        return this.orderItems.toModelArray().reduce((acc, x) => acc + x.getPriceTotal(), 0);
    }


    getDiscountProducts()
    {
        return computeDiscountProducts(this.getPriceProducts(), this.coupon);
    }


    getPriceTotal()
    {
        let total = this.getPriceProducts() - this.getDiscountProducts();

        if (this.coupon == null || !this.coupon.discountShipping) {
            total += this.getPriceShipping();
        }

        return total;
    }


    getItemsAmount()
    {
        if (this.orderItems == null)
        {
            return 0;
        }

        return this.orderItems.toModelArray().reduce((acc, x) => acc + x.amount, 0);
    }


    getPaymentMethod()
    {
        return this.paymentType === "PaypalPayment" ? "Paypal" :
            this.paymentType === "CardPayment" ? "Cartão de Crédito" :
            this.paymentType === "BillPayment" ? "Boleto" :
            this.paymentType === "TransferPayment" ? "Transferência Bancária" :
            this.paymentType === "PixPayment" ? "PIX" :
                "";
    }
}


Order.modelName = 'Order';


Order.fields = {
    id: attr(),
    status: attr(),
    user: fk('User', 'orders'),
    shipping: fk('Shipping', 'shipping'),
    address: fk('Address', 'addresss'),
    payment: fk('PaypalPayment', 'payment'),
};


export default Order;