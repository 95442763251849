import React from 'react';
import FieldNormalizer from "../util/FieldNormalizer";
import {useDispatch, useSelector} from "react-redux";
import UserSelector from "../../selectors/UserSelector";
import AddressAction from "../../actions/AddressAction";


export const AddressCard = ({address, selected, onSelect, onEdit, onDelete, onChange}) => {
    const user = useSelector(UserSelector.currentUser);
    const dispatch = useDispatch();

    if (user == null || address == null) {
        return null;
    }

    const isFavorite = user.favoriteAddressId === address.id;

    const select = () => {
        if (onSelect != null)
            onSelect(address.id);
    };

    const edit = (e) => {
        e.stopPropagation();

        if (onEdit != null)
            onEdit(address.id);
    };

    const remove = (e) => {
        e.stopPropagation();

        if (onDelete != null)
            onDelete(address.id);
    };

    const change = (e) => {
        e.stopPropagation();

        if (onChange != null)
            onChange(address.id);
    };

    const setFavorite = (e) => {
        e.stopPropagation();
        dispatch(AddressAction.setFavorite(address.id));
    };


    const className = `address ${onSelect == null ? "" : "address-card-clickable"} ${!selected ? "" : "selected"}`;

    const buttonEdit = onEdit == null ? null : (
        <button className="btn btn-gray btn-iconed" onClick={edit}>
            <i className="material-icons edit" style={{fontSize: 18}}/>
        </button>
    );

    const buttonDelete = onDelete == null ? null : <>
        <button className="btn btn-gray btn-iconed" onClick={remove}>
            <i className="material-icons delete_forever" style={{fontSize: 17}}/>
        </button>
    </>;

    const buttonChange = onChange == null ? null : (
        <button className="btn btn-gray btn-iconed" onClick={change}>
            <i className="material-icons loop" style={{fontSize: 20}}/>
        </button>
    );

    return (
        <div className={className} onClick={select}>
            <h5>
                {address.name}
            </h5>
            <div>
                {FieldNormalizer.zipcode(address.zipcode)}
            </div>
            <div>
                {address.street}, {address.number} {address.complement && "- " + address.complement}
            </div>
            <div>
                {address.neighbourhood} - {address.city} - {address.state}
            </div>

            <div className="tools">
                {buttonEdit}
                {buttonChange}
                {buttonDelete}
            </div>
            {isFavorite ?
                <div className="address-default">
                    <i className="material-icons favorite"/>
                    Favorito
                </div>
                :
                <div className="address-default address-default-clickable" onClick={setFavorite}>
                    <i className="material-icons favorite_border"/>
                    Favoritar
                </div>
            }
        </div>
    );
};