import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import UserSelector from "../../../../selectors/UserSelector";
import RegistrationAction from "../../../../actions/RegistrationAction";
import PasswordForm from "./PasswordForm";


export const PasswordEditor = () => {
    const user = useSelector(UserSelector.currentUser);
    const dispatch = useDispatch();

    if (user == null) return null;

    const save = (values) => {
        return dispatch(RegistrationAction.updatePassword(values.current_password, values.password, values.password_confirmation));
    };

    return <PasswordForm onSubmit={save} user={user}/>;
}

