import React from 'react'
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import Validator, {formatError} from "../../../util/validator";
import FieldComponent from "../../util/FieldComponent";
import {Link} from "react-router-dom";
import {Url} from "../../../util/Url";
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";


class Confirmation extends React.Component {
    static propTypes = {
        token: PropTypes.string,
        onSubmit: PropTypes.func,
        asyncErrors: PropTypes.object,
    };


    static defaultProps = {
        token: null,
        onSubmit: x => {
            console.log(x);
        },
        asyncErrors: null
    };


    //
    componentDidMount() {
        if (this.props.token != null) {
            setTimeout(() => {
                const submitter = this.props.handleSubmit(this.props.onSubmit);
                submitter(); // submits
            }, 100);
        }
    }


    render() {
        const {handleSubmit, asyncError, submitting} = this.props;

        let error = formatError(this.props.error);

        if (error === null && asyncError != null && asyncError.response != null && asyncError.response["email"] != null) {
            error = formatError("E-mail " + asyncError.response["email"]);
        }

        return (
            <main>
                <Header title="Deriva / Confirmação / Código"
                        description="Confirme o seu e-mail"
                />
                <div className="container text-center margin-top-2x margin-bottom-4x">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <img src="/img/login_01.png" style={{width: "auto"}} alt=""/>
                            <h5>Confirme o seu e-mail</h5>
                            <p>Digite abaixo o código de confirmação recebido por e-mail.</p>

                            <div className="row margin-top-1x">
                                <div className="col-md-8 col-md-offset-2">
                                    <form id="formConfirmation" ref="formConfirmation" onSubmit={handleSubmit}>

                                        <div className="margin-bottom-1x">
                                            <Field name="confirmation_token" type="text" component={FieldComponent}
                                                   className="form-control text-center"
                                                   placeholder="Código de confirmação"
                                                   validate={[Validator.Required]}
                                            />
                                        </div>

                                        {error}

                                        <div className="margin-top-1x">
                                            <button type="submit"
                                                    className="btn btn-default btn-block margin-right-none"
                                                    style={{width: "100%"}} disabled={submitting}>Enviar
                                            </button>
                                        </div>
                                    </form>
                                    <div className="margin-top-2x text-left">
                                        <Link to={Url.Account.ConfirmationResend} className="underline-link">
                                            Não recebeu o código de confirmação?
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}


Confirmation = reduxForm({
    form: 'confirmation'
})(Confirmation);


export default Confirmation;