import {attr, fk, Model} from 'redux-orm';
import {schema} from "normalizr";

import {updateEntities} from "../reducers/util";
import SessionActionType from "../actions/SessionActionType";
import CartActionType from "../actions/CartActionType";
import {belongsTo} from "./util";
import OrderActionType from "../actions/OrderActionType";
import Cart from "./Cart";



export class CartItem extends Model
{
    static Schema = new schema.Entity('cartItems',
        {
        },
        belongsTo(['cart', 'productVariation'])
    );


    static reducer(action, CartItem, session)
    {
        const {payload, type} = action;

        switch (type) {
            case CartActionType.CURRENT_SUCCESS:
            case CartActionType.ADD_SUCCESS:
            case CartActionType.REMOVE_SUCCESS:
            case CartActionType.ADDRESS_SUCCESS:
            case SessionActionType.LOGIN_SUCCESS:
                CartItem.all().delete();
                updateEntities(payload, 'cartItems', CartItem);
                break;

            case OrderActionType.CREATE_SUCCESS:
            case SessionActionType.LOGOUT_SUCCESS:
                CartItem.all().delete();
                break;

            case OrderActionType.CREATE_FAILURE:
                if (payload.result != null)
                {
                    CartItem.all().delete();
                    updateEntities(payload, 'cartItems', CartItem);
                }
                break;

            default:
                break;
        }
    }


    toString() {
        return `CartItem: ${this.id}`;
    }


    getPrice() {
        if (this.productVariation == null || this.productVariation.product == null)
        {
            return 0;
        }

        return this.amount * this.productVariation.product.price;
    }
}


CartItem.modelName = 'CartItem';


CartItem.fields = {
    id: attr(),
    productVariationId: attr(),
    amount: attr(),
    cart: fk('Cart', 'cartItems'),
    productVariation: fk('ProductVariation', 'cartItems'),
};


export default CartItem;