const CartActionType = {
    CURRENT_PREFIX: "CART_CURRENT",
    CURRENT_REQUEST: "CART_CURRENT_REQUEST",
    CURRENT_SUCCESS: "CART_CURRENT_SUCCESS",
    CURRENT_FAILURE: "CART_CURRENT_FAILURE",
    
    ADD_PREFIX: "CART_ADD",
    ADD_REQUEST: "CART_ADD_REQUEST",
    ADD_SUCCESS: "CART_ADD_SUCCESS",
    ADD_FAILURE: "CART_ADD_FAILURE",

    REMOVE_PREFIX: "CART_REMOVE",
    REMOVE_REQUEST: "CART_REMOVE_REQUEST",
    REMOVE_SUCCESS: "CART_REMOVE_SUCCESS",
    REMOVE_FAILURE: "CART_REMOVE_FAILURE",

    ADDRESS_PREFIX: "CART_ADDRESS",
    ADDRESS_REQUEST: "CART_ADDRESS_REQUEST",
    ADDRESS_SUCCESS: "CART_ADDRESS_SUCCESS",
    ADDRESS_FAILURE: "CART_ADDRESS_FAILURE",

    SHIPPING_PREFIX: "CART_SHIPPING",
    SHIPPING_REQUEST: "CART_SHIPPING_REQUEST",
    SHIPPING_SUCCESS: "CART_SHIPPING_SUCCESS",
    SHIPPING_FAILURE: "CART_SHIPPING_FAILURE",

    SET_SHIPPING: "SET_SHIPPING",
    SET_ADDRESS_ERROR: "SET_ADDRESS_ERROR",
    SET_PAYMENT_ERROR: "SET_PAYMENT_ERROR",

    SET_COUPON: "CART_SET_COUPON",
    SET_COUPON_REQUEST: "CART_SET_COUPON_REQUEST",
    SET_COUPON_SUCCESS: "CART_SET_COUPON_SUCCESS",
    SET_COUPON_FAILURE: "CART_SET_COUPON_FAILURE",
};

export default CartActionType;