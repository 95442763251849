import {createApiAction} from "./api/util";

import ConfirmationActionType from "./ConfirmationActionType";
import {Analytics} from "../analytics/Analytics";


class ConfirmationAction {


    static confirm(token) {

        Analytics.Event("confirm_account");

        let data = {
            confirmation_token: token
        };

        return createApiAction(ConfirmationActionType.CONFIRM_PREFIX, 'POST', 'users/confirmation.json', data);
    }


    static resend(email)
    {
        Analytics.Event("resend_confirmation");

        let data = {
            user: {
                email: email
            }
        };

        return createApiAction(ConfirmationActionType.RESEND_PREFIX, 'POST', 'users/confirmation/new.json', data);
    }

}


export default ConfirmationAction;