import React from 'react';
import {Helmet} from "react-helmet";
import {withRouter} from "react-router-dom";
import {Analytics} from "../analytics/Analytics";


export const Header = withRouter(({location, title, description, image, keywords}) => {

    const url = location.pathname;

    Analytics.PageView(title, url);

    if (keywords == null)
    {
        keywords = "deriva";
    }
    else
    {
        keywords += " deriva";
    }

    if (image != null)
    {
        image = "https://www.somosderiva.com" + image;
    }

    return <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description}/>}
        <meta name="keywords" content={keywords}/>
        <meta name="author" content="Deriva"/>

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={title}/>
        {description && <meta name="twitter:description" content={description}/>}
        {image && <meta name="twitter:image" content={image}/>}
        <meta name="twitter:site" content="@somosderiva"/>
        <meta name="twitter:creator" content="@somosderiva"/>

        <meta property="og:type" content="website"/>
        <meta property="og:title" content={title}/>
        {description && <meta property="og:description" content={description}/>}
        {image && <meta property="og:image" content={image}/>}
        <meta property="og:url" content={url}/>
        <meta property="og:site_name" content="Deriva"/>
        <meta property="og:locale" content="pt_BR"/>

        <meta property="fb:app_id" content={Analytics.MetaId}/>
    </Helmet>
});





