import React from "react";

export const Bill = () => {

    return <>
        <p>
            Para criar o boleto, clique em Continuar.
        </p>
        <p>
            Ao confirmar o pedido, o seu boleto será criado automaticamente e estará disponível para pagamento após um
            tempo de aproximadamente 5 minutos.
        </p>
        <p>
            A confirmação do pagamento pode levar de 1 a 3 dias úteis para ser compensada pelo banco.
        </p>
    </>
}