import React from 'react';

import {Provider} from 'react-redux'
import CookieConsent from "react-cookie-consent";

import App from "./App";
import {Url} from "./util/Url";


const Main = ({store}) => {

    return (
        <Provider store={store}>
            <App/>
            <CookieConsent
                location="bottom"
                buttonText="ACEITO"
                cookieName="somosderiva.com-consent"
                // debug={true}
                style={{background: "whitesmoke", color: "#000"}}
                buttonStyle={{
                    background: "black",
                    color: "#ccc",
                    fontSize: "13px",
                    border: "none",
                    padding: "12.5px 23px",
                    margin: "20px 20px"
                }}
                expires={360}
                contentStyle={{margin: "20px 20px 0px 20px"}}
            >
                Usamos cookies para você ter uma melhor experiência em nosso site.&nbsp;
                <a href={Url.Terms} style={{textDecoration: "underline"}}>Termos e condições</a>
            </CookieConsent>
        </Provider>
    );
};


export default Main;


Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function () {
        return !!(this.currentTime > 0 && !this.paused && !this.ended);
        // return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState >= 2);
    }
});