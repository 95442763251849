import React from "react";
import PropTypes from 'prop-types';
import Slider from "react-slick";


class ProductShowcasePictures extends React.Component {

    static propTypes = {
        pictures: PropTypes.array,
        className: PropTypes.string,
    };


    constructor(props) {
        super(props);

        this.state = {
            sliderThumbs: null,
            sliderPictures: null
        };
    }


    componentDidMount() {
        this.setState({
            sliderThumbs: this.sliderThumbs,
            sliderPictures: this.sliderPictures
        });
    }

    //
    // componentDidUpdate(prevProps)
    // {
    //     if (prevProps.pictures !== this.props.pictures)
    //     {
    //         this.render();
    //     }
    // }


    render() {
        let pictures = this.props.pictures;
        let className = this.props.className;

        let htmlThumbs = (
            <div className="no-outline">
                <div className="thumbnail">
                    <img src="" alt="Thumb"/>&nbsp;
                </div>
            </div>
        );

        let htmlPictures = (
            <div className="no-outline">
                <div className="picture">
                    <img src="" alt="Thumb"/>&nbsp;
                </div>
            </div>
        );

        let slidesToShow = 1;

        if (pictures != null)
        {
            htmlThumbs = pictures.map(p => (
                <div key={p} className="no-outline">
                    <div className="thumbnail">
                        <img src={p} alt="Thumb" className={className}/>
                    </div>
                </div>
            ));

            htmlPictures = pictures.map(p => (
                <div key={p} className="no-outline">
                    <div className="picture">
                        <img src={p} alt="Thumb" className={className}/>&nbsp;
                    </div>
                </div>
            ));

            slidesToShow = Math.min(pictures.length, 5);
        }

        return (
            <div className="row">
                <div className="hidden-md hidden-sm hidden-xs product-thumbnails">
                    <Slider
                        asNavFor={this.state.sliderPictures}
                        ref={slider => (this.sliderThumbs = slider)}
                        slidesToShow={slidesToShow}
                        dots={false}
                        arrows={false}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        vertical={true}
                        verticalSwiping={true}
                        // infinite={true} // asNavFor doesn't work with infinite=false
                        // className="thumbnail-carousel"
                        // lazyLoad={true}
                    >
                        {htmlThumbs}
                    </Slider>
                </div>
                <div className="product-picture">
                    <Slider
                        asNavFor={this.state.sliderThumbs}
                        ref={slider => (this.sliderPictures = slider)}
                        slidesToShow={1}
                        slidesToScroll={1}
                        dots={true}
                        arrows={false}
                        // infinite={true} // asNavFor doesn't work with infinite=false
                        // className="image-preview1"
                        // lazyLoad={true}
                    >
                        {htmlPictures}
                    </Slider>
                </div>
            </div>
        );
    }
}


export default ProductShowcasePictures;