import React from "react";
import {CollectionVoa} from "./CollectionVoa";
import {CollectionAmuletos} from "./CollectionAmuletos";
import {CollectionCaminhos} from "./CollectionCaminhos";
import {CollectionBeiraMar} from "./CollectionBeiraMar";


export const Collection = ({match}) => {

    switch (match.params.link)
    {
        case "voa":
            return <CollectionVoa/>;

        case "amuletos":
            return <CollectionAmuletos/>;

        case "beiramar":
            return <CollectionBeiraMar/>;

        default:
            return <CollectionCaminhos/>;
    }
}