import React from "react";
import {ProductDisplay} from "../product/ProductDisplay";


export const FrontWindow = ({title, subtitle, products, featuredProduct}) => {

    return <section className="container margin-top-3x margin-bottom-2x">
        <h2 className="block-title text-left margin-bottom-2x">
            {title}
            {subtitle &&
                <small>{subtitle}</small>
            }
        </h2>

        <div className="row">
            <div className="col-md-6">
                <div className="row">
                    <div className="col-xs-6">
                        <ProductDisplay product={products[0]}/>
                    </div>
                    <div className="col-xs-6">
                        <ProductDisplay product={products[1]}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-6">
                        <ProductDisplay product={products[2]}/>
                    </div>
                    <div className="col-xs-6">
                        <ProductDisplay product={products[3]}/>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <ProductDisplay product={featuredProduct} aspectRatio={"calc(125% + 49px)"}/>
            </div>
        </div>
    </section>;
}