import React from 'react'
import {CartProgress} from "../CartProgress";
import {AddressEditor} from "../../address/AddressEditor";
import {History} from "../../routes/History";
import {Url} from "../../../util/Url";
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";


export const CartFirstAddress = () => {

    const back = () => {
        History.goBack();
    };

    const next = () => {
        History.go(0);//push(Url.Shop.CartShipping);
    };


    return <main>
        <Header title="Deriva / Carrinho / Adicione um endereço"
                description="Para continuar, você precisa adicionar um endereço"
        />
        <div className="container margin-top-2x">
            <div className="row">
                <div className="col-xs-12">
                    <CartProgress current={0}/>
                </div>

                <div className="col-xs-12">
                    <div className="panel no-pointer-events margin-bottom-2x">
                        <div className="panel-heading">
                            <div className="panel-title"><i className="material-icons warning"/>&nbsp;
                                Para continuar com a compra, você precisa adicionar um endereço.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-md-10 col-md-offset-1 margin-bottom-1x">
                    <h2>
                        Adicione o seu endereço
                    </h2>
                </div>
                <div className="col-xs-12 cart-personal">
                    <AddressEditor cart={true} onSave={next} onCancel={back} />
                </div>
            </div>
        </div>
    </main>
}