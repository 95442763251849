import React from 'react'
import {NavLink} from "react-router-dom";
import {Url} from "../util/Url";

const Footer = () => (
    <footer className="footer padding-bottom-2x">
        <div className="container">
            <div className="row">
                <div className="col-md-3 col-sm-4">
                    <div className="widget categories-widget">
                        <div className="widget-title">
                        </div>

                        <ul className="cat-list">
                            <li>
                                <NavLink exact to={Url.Home} activeClassName="current">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to={Url.Collection("caminhos")} activeClassName="current">Coleção</NavLink>
                            </li>
                            <li>
                                <NavLink to={Url.Shop.Main} activeClassName="current">Loja</NavLink>
                            </li>
                            <li>
                                <NavLink to={Url.About} activeClassName="current">Sobre nós</NavLink>
                            </li>
                            <li>
                                <NavLink to={Url.Contact} activeClassName="current">Contato</NavLink>
                            </li>
                            <li>
                                <NavLink to={Url.Terms} activeClassName="current">Termos e condições</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-xs-12 margin-bottom-1x visible-xs">&nbsp;</div>

                <div className="col-md-3 col-md-offset-2 col-sm-4 col-sm-offset-2 margin-bottom-1x">
                    <div className="widget">
                        <div className="widget-title">
                            Segue a gente
                        </div>

                        <a href="//www.instagram.com/somosderiva" target="_blank" rel="noopener noreferrer"
                           className="btn btn-gray btn-iconed"><i className="socicon-instagram"/></a>
                        <a href="//www.pinterest.com/somosderiva" target="_blank" rel="noopener noreferrer"
                           className="btn btn-gray btn-iconed"><i className="socicon-pinterest"/></a>
                        {/*<a href="//www.youtube.com/channel/UCFrDZOKQRuUmfR3Un1faY_A/" target="_blank" rel="noopener noreferrer"*/}
                           {/*className="btn btn-gray btn-iconed"><i className="socicon-youtube"/></a>*/}
                    </div>
                </div>

                <div className="col-md-3 col-md-offset-1 col-sm-4 col-sm-offset-2">
                    <div className="widget">
                        <div className="widget-title">
                            Aceitamos
                        </div>

                        <div className="cards">
                            <img src="/img/cards.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

);

Footer.propTypes = {};

export default Footer;