import React from 'react';

import Routes from "../routes/Routes";



const Session = ({ currentUser }) => {

    return <Routes currentUser={ currentUser }/>;
};


export default Session;
