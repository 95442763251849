import {fk, attr, Model} from 'redux-orm';
import {schema} from 'normalizr';

import Product from "./Product";

import {updateEntities} from "../reducers/util";
import {belongsTo} from "./util";
import CollectionActionType from "../actions/CollectionActionType";
import ProductActionType from "../actions/ProductActionType";


export class Pattern extends Model {

    static Schema = new schema.Entity('patterns',
        {
            products: new schema.Array(Product.Schema)
        },
        belongsTo('collection')
    );


    static reducer(action, Pattern, session) {
        const {payload, type} = action;

        switch (type) {
            case CollectionActionType.FEATURED_SUCCESS:
            case CollectionActionType.ALL_SUCCESS:
            case ProductActionType.LOAD_SUCCESS:
                updateEntities(payload, 'patterns', Pattern);
                break;

            default:
                break;
        }
    }


    toString() {
        return `Pattern: ${this.id} - ${this.name}`;
    }
}


Pattern.modelName = 'Pattern';


Pattern.fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    featured: attr(),
    collection: fk('Collection', 'patterns')
};


export default Pattern;