import React, {useState, useEffect } from 'react';

const $ = window.$;


export const RevolutionSlider = ({name, options, className, children}) => {

    const [slider, setSlider] = useState(null);

    useEffect(() => {
        if (slider == null)
        {
            setSlider($("#" + name).revolution(options));
        }

        return () => {
            if (slider != null)
            {
                slider.revpause();
            }
        }
    });

    return (
        <section className="fw-section">
            <div className="rev_slider_wrapper">
                <div id={name} className={className + " rev_slider"} data-version="5.0">
                    {children}
                </div>
            </div>
        </section>
    );
};