import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {Url} from "../util/Url";
import {Header} from "../headers/Header";

class NotFound extends Component {

    render() {
        return (
            <section className="fw-section padding-bottom-2x">
                <Header title="Deriva / 404"
                        description="Página não encontrada" />
                <div className="container text-center padding-top-3x padding-bottom-10x">
                    <h1 className="">404</h1>
                    <h3 className="margin-bottom-2x">Ops! Página não encontrada.</h3>
                    <Link to={Url.Home} className="btn btn-default margin-right-none">Home</Link>
                </div>
            </section>
        );
    }
}

export default NotFound;