import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {History} from "../../routes/History";

import {validateApiResponse} from "../../../actions/api/util";
import {Url} from "../../../util/Url";
import ConfirmationAction from "../../../actions/ConfirmationAction";
import ConfirmationResend from "./ConfirmationResend";


class ConfirmationResendContainer extends React.Component {

    static propTypes = {
        onSubmit: PropTypes.func,
    };


    render() {
        return <ConfirmationResend onSubmit={this.props.onSubmit} />;
    }
}


const mapStateToProps = () => {
    return {
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
        onSubmit: (values) => {
            return dispatch(ConfirmationAction.resend(values.email))
                .then(validateApiResponse)
                .then(() => {
                    History.push(Url.Account.ConfirmationSent);
                })
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(ConfirmationResendContainer);

