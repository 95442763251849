import React from 'react';
import {ImageCarousel} from "../carousel/ImageCarousel";



export const AmuletosMediaCarousel = ({media}) => {

    const images = [
        `/img/collection/amuletos/carousel/1.jpg`,
        // `/img/slider/2-${media}.jpg`,
        // `/img/slider/3-${media}.jpg`,
    ];

    return <ImageCarousel images={images} name={`amuletos-carousel-${media}`}/>
};