import React from 'react'
import CarouselBase from "../home/carousel/CarouselBase";
import enableInlineVideo from 'iphone-inline-video';
import {ClipLoader} from "react-spinners";


class VideoFullscreen extends CarouselBase {


    constructor() {
        super();

        this.playVideo = this.playVideo.bind(this);
        this.playVideoAndUnmute = this.playVideoAndUnmute.bind(this);
        this.playVideoAndToggleMute = this.playVideoAndToggleMute.bind(this);
        this.unmuteVideo = this.unmuteVideo.bind(this);
        this.muteVideo = this.muteVideo.bind(this);
        this.updateButtons = this.updateButtons.bind(this);

        this.video = React.createRef();
        this.buttonPlay = React.createRef();
        this.buttonUnmute = React.createRef();
        this.buttonMute = React.createRef();
        this.loading = React.createRef();
    }


    getName() {
        return super.getName() + "-video";
    }


    componentDidMount() {
        super.componentDidMount();

        this.slider.on('revolution.slide.onloaded', this.playVideo);
        this.slider.on('revolution.slide.onchange', this.playVideo);
        this.slider.on('revolution.slide.onvideoplay', this.updateButtons);
        this.slider.on('revolution.slide.onvideostop', this.updateButtons);

        this.interval = setInterval(this.updateButtons, 200);

        enableInlineVideo(this.video.current);
    }


    componentWillUnmount() {
        super.componentWillUnmount();

        clearInterval(this.interval);
    }


    playVideo() {
        if (this.video.current == null || this.video.current.playing) {
            return;
        }

        this.video.current.play();
    }


    playVideoAndUnmute() {
        if (this.video.current == null) {
            return;
        }

        if (!this.video.current.playing)
        {
            const promise = this.video.current.play();

            if (promise != null)
            {
                promise.then(this.unmuteVideo);
            }
        }

        this.unmuteVideo();
    }


    playVideoAndToggleMute() {
        if (this.video.current == null) {
            return;
        }

        if (!this.video.current.playing)
        {
            const promise = this.video.current.play();

            if (promise != null)
            {
                promise.then(this.toggleMute);
            }
        }

        this.toggleMute();
    }


    unmuteVideo() {
        if (this.video.current == null) {
            return;
        }

        this.video.current.muted = false;
    }


    muteVideo() {
        if (this.video.current == null) {
            return;
        }

        this.video.current.muted = true;
    }


    toggleMute() {
        if (this.video.current == null) {
            return;
        }

        this.video.current.muted = !this.video.current.muted;
    }


    updateButtons() {
        if (this.video.current == null) {
            return;
        }

        const playing = this.video.current.playing;

        if (this.buttonPlay.current != null)
        {
            this.buttonPlay.current.style.display = playing ? "none" : "inline";
        }

        const muted = this.video.current.muted;

        if (this.buttonUnmute.current != null)
        {
            this.buttonUnmute.current.style.display = (!playing || !muted) ? "none" : "inline";
        }

        if (this.buttonMute.current != null)
        {
            this.buttonMute.current.style.display = (!playing || muted) ? "none" : "inline";
        }

        const buffering = playing && this.video.current.readyState <= 1;

        if (this.loading.current != null)
        {
            this.loading.current.style.display = !buffering ? "none" : "inline";
        }
    }


    getRevolutionOptions() {
        return {
            ...super.getRevolutionOptions(),

            // stopLoop: 'on',
            // stopAfterLoops: 0,
            // stopAtSlide: 1,

            sliderType: "standart",
            sliderLayout: "fullscreen",
            dottedOverlay: "none",
            disableProgressBar: "on",
            navigation: {
                arrows: {
                    enable: false,
                    hide_onleave: true
                },
                touch: {
                    touchenabled: "on",
                },
                onHoverStop: "off"
            },
            fullScreenOffsetContainer: ".header",
            fullScreenAlignForce: 'off'
        };
    }


    render() {
        return (
            <section className="fw-section">
                <div className="rev_slider_wrapper">
                    <div id={this.getName()} className="rev_slider" data-version="5.0">
                        <ul>
                            <li data-transition="fade">

                                <div style={{ width: "100%", height: "100%" }} onClick={this.playVideoAndToggleMute}>
                                    <video className="resizelistener" preload="auto" muted autoPlay playsInline
                                           style={{ objectFit: "cover", backgroundSize: "cover", width: "100%", height: "100%" }}
                                           ref={this.video}
                                    >
                                        <source src="/video/voa.mp4" type="video/mp4" />
                                    </video>

                                    <button onClick={this.playVideoAndUnmute} id="video-control" className="btn btn-white btn-iconed btn-lg" ref={this.buttonPlay}>
                                        <i className="material-icons play_arrow"/>
                                    </button>

                                    <button className="btn btn-white btn-iconed btn-mute video-audio" ref={this.buttonUnmute}>
                                        <i className="material-icons volume_up"/>
                                    </button>

                                    <button className="btn btn-white btn-iconed btn-mute video-audio" ref={this.buttonMute}>
                                        <i className="material-icons volume_off"/>
                                    </button>

                                    <div ref={this.loading} id="video-loading">
                                        <ClipLoader
                                            color={'rgba(255, 255, 255, 0.75)'}
                                            loading={true}
                                        />
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}


export default VideoFullscreen;