import React from 'react';

const $ = window.$;


class CarouselBase extends React.Component {

    constructor()
    {
        super();

        this.slider = null;
    }

    componentDidMount() {
        this.loadSlider();
    }


    componentWillUnmount()
    {
        if (this.slider != null)
        {
            this.slider.revpause();
            this.slider = null;
        }
    }


    getRevolutionOptions() {
        return {
            stopLoop: 'off',
            stopAfterLoops: -1,
            stopAtSlide: -1,

            sliderType: "standard",
            sliderLayout: "fullscreen",
            dottedOverlay: "none",
            disableProgressBar: "on",
            delay: 5000,
            navigation: {
                arrows: {
                    enable: true,
                    hide_onleave: true
                },
                touch: {
                    touchenabled: "on",
                    swipe_treshold: 75,
                    swipe_min_touches: 1,
                    drag_block_vertical: false,
                    swipe_direction: "horizontal"
                },
                onHoverStop: "off"
            },
            fullScreenOffsetContainer: ".header",
            fullScreenAutoWidth: "on",
            // fullScreenAlignForce: 'off',
            // lazyType: "all",

            // viewPort: {
            //     enable: true,
            //     outof: 'pause',
            //     visible_area: '60%',
            //     presize: false
            // },
        };
    }


    loadSlider() {
        this.slider = $("#" + this.getName()).revolution(this.getRevolutionOptions());
        // $('.rev_slider_wrapper').css("left", 0);
    }


    getName()
    {
        return "slider";
    }


    render() {
        return (
            <section className="fw-section">
                <div className="rev_slider_wrapper">
                    <div id={this.getName()} className="rev_slider" data-version="5.0">
                        <ul>
                            <li data-transition="fade">
                            </li>

                            <li data-transition="fade">
                            </li>

                            <li data-transition="fade">
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}


export default CarouselBase;