import {attr, Model} from 'redux-orm';
import {schema} from "normalizr";

import {updateEntities} from "../reducers/util";
import CartActionType from "../actions/CartActionType";
import SessionActionType from "../actions/SessionActionType";
import OrderActionType from "../actions/OrderActionType";


export class PaypalPayment extends Model
{
    static Schema = new schema.Entity('paypalPayments',
        {
        }
    );


    static reducer(action, PaypalPayment, session)
    {
        const {payload, type} = action;

        switch (type) {
            case OrderActionType.CREATE_SUCCESS:
            case OrderActionType.AUTHORIZE_SUCCESS:
                updateEntities(payload, 'paypalPayments', PaypalPayment);
                break;

            case SessionActionType.LOGIN_SUCCESS:
                PaypalPayment.all().delete();
                updateEntities(payload, 'paypalPayments', PaypalPayment);
                break;

            case SessionActionType.LOGOUT_SUCCESS:
                PaypalPayment.all().delete();
                break;

            default:
                break;
        }
    }


    toString() {
        return `PaypalPayment: ${this.id} - ${this.paypal_id}`;
    }
}


PaypalPayment.modelName = 'PaypalPayment';


PaypalPayment.fields = {
    id: attr(),
    paypalId: attr(),
    status: attr(),
    payeeEmail: attr(),
    payeeMerchantId: attr(),
};


export default PaypalPayment;