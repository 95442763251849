import React from 'react'
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import Validator, {formatError} from "../../../util/validator";
import FieldComponent from "../../util/FieldComponent";
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";


class PasswordReset extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        asyncError: PropTypes.object,
    };


    static defaultProps = {
        onSubmit: x => {
            console.log(x);
        },
        asyncError: null
    };


    render() {
        const {handleSubmit, asyncError, submitting} = this.props;

        let error = formatError(this.props.error);

        if (error === null && asyncError != null && asyncError.response != null && asyncError.response["reset_password_token"] != null) {
            error = formatError("Código de confirmação " + asyncError.response["reset_password_token"]);
        }

        return (
            <main>
                <Header title="Deriva / Redefinir senha"
                        description="Crie uma nova senha abaixo para acessar sua conta"
                />
                <div className="container text-center margin-top-2x margin-bottom-4x">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <img src="/img/login_01.png" style={{width: "auto"}} alt=""/>
                            <h5>Redefina sua senha</h5>
                            <p>Crie uma nova senha abaixo para acessar sua conta.</p>

                            <div className="row margin-top-1x">
                                <div className="col-md-8 col-md-offset-2">
                                    <form id="formConfirmationResend" onSubmit={handleSubmit}>
                                        <Field name="reset_password_token" type="hidden" component="input"
                                               className="form-control text-center"
                                        />

                                        <div className="margin-bottom-1x">
                                            <Field name="password" type="password" component={FieldComponent}
                                                   className="form-control text-center"
                                                   placeholder="Nova Senha"
                                                   validate={[Validator.Required, Validator.LengthMin6]}
                                            />
                                        </div>

                                        <div className="margin-bottom-1x">
                                            <Field name="password_confirmation" type="password" component={FieldComponent}
                                                   className="form-control text-center"
                                                   placeholder="Confirme a nova senha"
                                                   validate={[Validator.Required, Validator.LengthMin6, Validator.PasswordConfirmation]}
                                            />
                                        </div>

                                        {error}

                                        <div className="margin-top-1x">
                                            <button type="submit"
                                                    className="btn btn-default btn-block margin-right-none"
                                                    style={{width: "100%"}} disabled={submitting}>Enviar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}


PasswordReset = reduxForm({
    form: 'passwordReset'
})(PasswordReset);


export default PasswordReset;