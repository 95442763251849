import React from 'react';
import {ImageCarousel} from "../carousel/ImageCarousel";
import {BeiraMarCarousel} from "./BeiraMarCarousel";



export const BeiraMarMediaCarousel = ({media}) => {

    const images = [
        `/img/collection/beiramar/01.jpg`,
        // `/img/slider/2-${media}.jpg`,
        // `/img/slider/3-${media}.jpg`,
    ];

    return <ImageCarousel images={images} name={`beiramar-carousel-${media}`}/>
};