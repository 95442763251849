import ButtonLoader from "../../util/ButtonLoader";
import React from "react";
import {Field, reduxForm} from "redux-form";
import FieldComponent from "../../util/FieldComponent";
import ValidationError from "../../util/ValidationError";
import {useSelector} from "react-redux";
import CartSelector from "../../../selectors/CartSelector";


const _CouponForm = ({handleSubmit, submitting, anyTouched, error}) => {
    const coupon = useSelector(CartSelector.coupon);

    error = !anyTouched ? null : <ValidationError>{error}</ValidationError>;

    return <form id="formCoupon" onSubmit={handleSubmit}>

        <div className="row">
            <div className="col-xs-7 col-sm-4 padding-right-none">
                <Field name="code" type="text" component={FieldComponent} placeholder={"Cupom"}
                       className="form-control coupon-code-field" autoComplete="off" disabled={coupon != null}
                />
            </div>

            <div className="col-xs-5 col-sm-8">
                <div className="action-tools" style={{maxWidth: 80}}>
                    <button type="submit"
                            className="btn btn-sm margin-bottom-none full-width padding-left-none padding-right-none"
                            disabled={submitting}>

                        {coupon == null ?
                            "Ok"
                            :
                            <i className="material-icons close" style={{fontSize: 18}}/>
                        }

                        <ButtonLoader loading={submitting} color="black"/>
                    </button>
                </div>
            </div>

            <div className="col-xs-12">
                {error}
            </div>
        </div>

    </form>;
};


const validate = values => {
    const errors = {};

    if (values.code == null || values.code === "") {
        errors._error = "Cupom inválido.";
    }

    return errors;
};

export const CouponForm = reduxForm({
    form: 'coupon',
    validate,
})(_CouponForm);