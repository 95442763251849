import {attr, fk, Model} from 'redux-orm';
import {schema} from "normalizr";

import {updateEntities} from "../reducers/util";
import SessionActionType from "../actions/SessionActionType";

import AddressActionType from "../actions/AddressActionType";
import {belongsTo} from "./util";
import OrderActionType from "../actions/OrderActionType";


export class Address extends Model
{
    static Schema = new schema.Entity('addresses',
        {
        },
        belongsTo('user')
    );


    static reducer(action, Address, session)
    {
        const {payload, type} = action;

        switch (type) {
            case SessionActionType.LOGIN_SUCCESS:
            case AddressActionType.DELETE_SUCCESS:
                Address.all().delete();
                updateEntities(payload, 'addresses', Address);
                break;

            case SessionActionType.LOGOUT_SUCCESS:
                Address.all().delete();
                break;

            case AddressActionType.UPSERT_SUCCESS:
            case OrderActionType.CREATE_SUCCESS:
            case OrderActionType.AUTHORIZE_SUCCESS:
                updateEntities(payload, 'addresses', Address);
                break;

            default:
                break;
        }
    }


    toString() {
        return `Address: ${this.id} - ${this.zipcode}`;
    }
}


Address.modelName = 'Address';


Address.fields = {
    id: attr(),
    name: attr(),
    zipcode: attr(),
    street: attr(),
    number: attr(),
    complement: attr(),
    neighbourhood: attr(),
    city: attr(),
    state: attr(),
    user: fk('User', 'addresses'),
};


export default Address;