import React from 'react'
import {Url} from "../../../util/Url";
import {Link} from "react-router-dom";


const variationsOrder = ["PP", "P", "M", "G", "GG", "U"];

function sortVariations(pA, pB) {
    let iA = variationsOrder.indexOf(pA.size);
    let iB = variationsOrder.indexOf(pB.size);

    if (iA === -1) iA = 99999;
    if (iB === -1) iB = 99999;

    return iA - iB;
}


export const ProductDisplay = ({product, variations, aspectRatio}) => {
    if (product == null) return null;

    let image = product.picturesShop[0];

    const availableVariations = product.productVariations.filter(x => x.inventory > 0).sort(sortVariations);
    const isAvailable = availableVariations.length > 0;

    const price = !isAvailable ? <span className="unavailable"></span> : "R$ " + product.price;
    const opacity = isAvailable ? 1 : 0.5;

    const paddingTop = aspectRatio == null ? "125%" : aspectRatio;

    return (
        <div className="tile product-display" style={{marginBottom: 48}}>
            <div className="preview-box">
                <div className="shop-product-image-container" style={{paddingTop}}>
                    <Link to={Url.Shop.Product(product.link)}>
                        {/*<div className="shop-product-image" style={{backgroundImage: `url(${image})`}}>*/}
                        <div className="shop-product-image" style={{opacity}}>
                            <div className="flexbox-centering">
                                <img src={image} alt=""/>
                            </div>
                            {variations &&
                                <div className="available">
                                    {availableVariations.map(x => (
                                        <div className="size" key={x.size}>
                                            {x.size}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </Link>
                </div>
            </div>

            <div className="tile-title">
                <Link to={Url.Shop.Product(product.link)} style={{textTransform: "uppercase"}}>
                    {product.name}
                    <span className="cost">
                            {price}
                        </span>
                </Link>
            </div>

            <div className="tile-meta">
                <div className="meta-top">
                    <Link to={Url.Collection(product.pattern.collection.link)}>
                        {product.patternString}
                    </Link>
                </div>
                <div className="meta-bottom">
                </div>
            </div>
        </div>
    );
}