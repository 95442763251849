if (process.env.NODE_ENV === 'production') {
    let host = window.location.hostname;

    if (host === "deriva-staging.herokuapp.com")
    {
        module.exports = require('./configureStore.dev')
    }
    else {
        module.exports = require('./configureStore.prod')
    }
} else {
    module.exports = require('./configureStore.dev')
}
