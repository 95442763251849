import React from 'react';
import {PasswordEditor} from "./PasswordEditor";
import AccountMenu from "../AccountMenu";
import {Helmet} from "react-helmet";
import {Header} from "../../../../headers/Header";

export const Password = () => {
    return <main>
        <Header title="Deriva / Conta / Senha"
                description="Criar uma nova senha"
        />
        <section className="fw-section">
            <div className="container margin-top-2x">
                <div className="row">
                    <AccountMenu/>

                    <div className="col-md-10 col-sm-12 margin-bottom-2x">
                        <h2 className="margin-top-2x">
                            Criar uma nova senha
                        </h2>
                        <div className="margin-bottom-1x">
                            Caso deseje criar uma nova senha para sua conta, preencha o formulário abaixo.
                        </div>
                        <PasswordEditor/>
                    </div>
                </div>
            </div>
        </section>
    </main>
};