import React from 'react';
import Media from 'react-media';
import {AmuletosMediaCarousel} from "./AmuletosMediaCarousel";


export const AmuletosCarousel = () => {

    return <>
        <Media query="(max-width: 768px)" render={() => <AmuletosMediaCarousel media="xs"/>}/>
        <Media query="(min-width: 768px) and (max-width: 991px)" render={() => <AmuletosMediaCarousel media="sm"/>}/>
        <Media query="(min-width: 992px) and (max-width: 1199px)" render={() => <AmuletosMediaCarousel media="md"/>}/>
        <Media query="(min-width: 1200px)" render={() => <AmuletosMediaCarousel media="lg"/>}/>
    </>;
};
