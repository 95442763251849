import {createSelector} from 'redux-orm';

import orm from "../models";
import {entitiesSelector} from "./index";
import ProductSelector from "./ProductSelector";
import Shipping from "../models/Shipping";
import ApplicationSelector from "./ApplicationSelector";


class CartSelector {

    static _cartSelector = state => state.application.cart;


    static id = state => CartSelector._cartSelector(state).id;

    static current = createSelector(
        orm,
        entitiesSelector,
        CartSelector.id,
        (session, id) => {

            if (id == null) {
                return null;
            }

            const {Cart} = session;

            const cart = Cart.withId(id);

            if (cart == null) {
                return null;
            }

            const price = cart.getPrice();

            return {
                ...cart.ref,
                cartItems: cart.cartItems.toRefArray(),
                price,
            }
        }
    );

    static currentDetailed = createSelector(
        orm,
        entitiesSelector,
        CartSelector.id,
        (session, id) => {

            if (id == null) {
                return null;
            }

            const {Cart} = session;

            const cart = Cart.withId(id);

            if (cart == null) {
                return null;
            }

            const price = cart.getPrice();

            return {
                ...cart.ref,
                price,
                cartItems: cart.cartItems.toModelArray().map(cartItem => {
                    return {
                        ...cartItem.ref,
                        productVariation: {
                            ...cartItem.productVariation.ref,
                            product: {
                                ...cartItem.productVariation.product.ref,
                                pattern: {
                                    ...cartItem.productVariation.product.pattern.ref,
                                    collection: cartItem.productVariation.product.pattern.collection.ref
                                }
                            }
                        }
                    }
                }),
                address: cart.address == null ? null : cart.address.ref,
            }
        }
    );


    static shippingId = state => CartSelector._cartSelector(state).shippingId;
    static shippingIdList = state => CartSelector._cartSelector(state).shippingList;


    static shipping = createSelector(
        orm,
        entitiesSelector,
        CartSelector.shippingId,
        (session, shippingId) => {

            if (shippingId == null) {
                return null;
            }

            const {Shipping} = session;
            const shipping = Shipping.withId(shippingId);

            if (shipping == null) {
                return null;
            }

            return shipping.ref;
        }
    );


    static shippingList = createSelector(
        orm,
        entitiesSelector,
        CartSelector.shippingIdList,
        (session, ids) => {

            if (ids == null) {
                return [];
            }

            const {Shipping} = session;

            return Shipping.all().filter(s => ids.indexOf(s.id) !== -1).toRefArray();
        }
    );


    static lastAddedProductVariationId = state => CartSelector._cartSelector(state).lastAddedProductVariationId;
    static lastRemovedProductVariationId = state => CartSelector._cartSelector(state).lastRemovedProductVariationId;
    static updatedAt = state => CartSelector._cartSelector(state).updatedAt;

    static lastAddedProduct = state => ProductSelector.productByVariation(state, CartSelector.lastAddedProductVariationId(state));
    static lastRemovedProduct = state => ProductSelector.productByVariation(state, CartSelector.lastRemovedProductVariationId(state));


    static lastAddedCartItem = state => CartSelector.cartItemByProductVariation(state, CartSelector.lastAddedProductVariationId(state));
    static lastRemovedCartItem = state => CartSelector.cartItemByProductVariation(state, CartSelector.lastRemovedProductVariationId(state));


    static cartItemByProductVariation = (state, productVariationId) => createSelector(orm, entitiesSelector,
        (session) => {

            if (productVariationId == null) {
                return null;
            }

            productVariationId = parseInt(productVariationId);

            const {CartItem, ProductVariation} = session;

            let cartItem = CartItem.get({productVariationId: productVariationId});

            if (cartItem == null) {
                return null;
            }

            let productVariation = ProductVariation.withId(productVariationId);

            return {
                ...cartItem.ref,
                productVariation: {
                    ...productVariation.ref,
                    product: {
                        ...productVariation.product.ref,
                        patternString: productVariation.product.formatPattern(),
                        pattern: {
                            ...productVariation.product.pattern.ref,
                            collection: productVariation.product.pattern.collection.ref,
                        }
                    }
                }
            };
        }
    )(state);


    static coupon = state => CartSelector._cartSelector(state).coupon;
}

export default CartSelector;