import React from 'react'
import PropTypes from 'prop-types'

import LoginFormContainer from "./LoginFormContainer";
import RegistrationFormContainer from "./RegistrationFormContainer";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";


class Login extends React.Component {
    static propTypes = {
        onSuccess: PropTypes.func
    };


    static defaultProps = {
        onSuccess: null
    };


    render() {
        return (
            <main>
                <Header title="Deriva / Entrar"
                        description="Entre na sua conta"
                />
                <div className="container text-center margin-top-2x margin-bottom-2x">
                    <div className="row">
                        <div className="col-md-6 margin-bottom-4x">
                            <LoginFormContainer onSuccess={this.props.onSuccess}/>
                        </div>
                        <div className="col-md-6">
                            <RegistrationFormContainer/>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default Login;

