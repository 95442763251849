import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import PasswordReset from "./PasswordReset";
import ApplicationSelector from "../../../selectors/ApplicationSelector";
import {validateApiResponse} from "../../../actions/api/util";

import queryString from 'query-string'
import {withRouter} from "react-router-dom";
import {History} from "../../routes/History";
import {Url} from "../../../util/Url";
import PasswordAction from "../../../actions/PasswordAction";


class PasswordResetContainer extends React.Component {

    constructor()
    {
        super();
        this.token = null;
    }

    static propTypes = {
        onSubmit: PropTypes.func,
        error: PropTypes.object
    };


    componentWillMount()
    {
        let query = queryString.parse(this.props.location.search);

        this.token = query.reset_password_token;
    }


    componentDidUpdate(prevProps)
    {
        if (prevProps.error !== this.props.error)
        {
            this.render();
        }
    }


    render() {
        return <PasswordReset onSubmit={this.props.onSubmit} token={this.token} asyncError={this.props.error} initialValues={{ reset_password_token: this.token }} />;
    }
}


const mapStateToProps = (state) => {
    return {
        error: ApplicationSelector.formPasswordReset(state).error
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
        onSubmit: (values) => {
            return dispatch(PasswordAction.reset(values.reset_password_token, values.password, values.password_confirmation))
                .then(validateApiResponse)
                .then(() => {
                    History.push(Url.Account.PasswordComplete);
                })
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(withRouter(PasswordResetContainer));

