import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Confirmation from "./Confirmation";
import ApplicationSelector from "../../../selectors/ApplicationSelector";
import {validateApiResponse} from "../../../actions/api/util";

import queryString from 'query-string'
import {withRouter} from "react-router-dom";
import {History} from "../../routes/History";
import ConfirmationAction from "../../../actions/ConfirmationAction";
import {Url} from "../../../util/Url";


class ConfirmationContainer extends React.Component {

    constructor()
    {
        super();
        this.token = null;
    }

    static propTypes = {
        onSubmit: PropTypes.func,
        error: PropTypes.object
    };


    componentWillMount()
    {
        let query = queryString.parse(this.props.location.search);

        this.token = query.confirmation_token;
    }


    componentDidUpdate(prevProps)
    {
        if (prevProps.error !== this.props.error)
        {
            this.render();
        }
    }


    render() {
        return <Confirmation onSubmit={this.props.onSubmit} token={this.token} asyncError={this.props.error} initialValues={{ confirmation_token: this.token }} />;
    }
}


const mapStateToProps = (state) => {
    return {
        error: ApplicationSelector.formConfirmation(state).error
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
        onSubmit: (values) => {
            return dispatch(ConfirmationAction.confirm(values.confirmation_token))
                .then(validateApiResponse)
                .then(() => {
                    History.push(Url.Account.ConfirmationComplete);
                })
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(withRouter(ConfirmationContainer));

