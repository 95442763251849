import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux'
import './App.css';


import SessionContainer from "./containers/session/SessionContainer";
import ApplicationAction from "./actions/ApplicationAction";
import ApplicationSelector from "./selectors/ApplicationSelector";
import {Analytics} from "./analytics/Analytics";

class App extends React.Component {

    static propTypes = {
        initialized: PropTypes.bool,
        initialize: PropTypes.func
    };


    componentDidMount()
    {
        this.props.initialize();

        if (process.env.NODE_ENV === 'production') {
            Analytics.Init();
        }
        else
        {
            Analytics.Init();
        }
    }


    render() {
        if (!this.props.initialized) {
            return null;
        }

        return <SessionContainer />;
    };
}


const mapStateToProps = (state) => {
    return {
        initialized: ApplicationSelector.initialized(state)
    }
};


const mapPropsToDispatch = (dispatch) => {
    return {
        initialize: () => dispatch(ApplicationAction.initialize())
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(App);
