import React from 'react';
import PropTypes from 'prop-types'

import {Route, Router, Switch} from "react-router-dom";
import {History} from "./History";
import {connect} from "react-redux";
import {Url} from "../../util/Url";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import NotFound from "../NotFound";

import SessionAction from "../../actions/SessionAction";

import {Header} from "../header/Header";
import Footer from "../Footer";

import Login from "../login/Login";
import {Home} from "../home/Home";
import ContactContainer from "../contact/ContactContainer";
import UserSelector from "../../selectors/UserSelector";

import {About} from "../about/About";

import {ConfirmationSent} from "../account/confirmation/ConfirmationSent";
import {ConfirmationComplete} from "../account/confirmation/ConfirmationComplete";
import ConfirmationResendContainer from "../account/confirmation/ConfirmationResendContainer";
import ConfirmationContainer from "../account/confirmation/ConfirmationContainer";

import {PasswordSent} from "../account/password/PasswordSent";
import {PasswordComplete} from "../account/password/PasswordComplete";
import ForgotPasswordContainer from "../account/password/ForgotPasswordContainer";
import PasswordResetContainer from "../account/password/PasswordResetContainer";
import ProductShowcaseContainer from "../product/ProductShowcaseContainer";
import {Cart} from "../cart/Cart";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Main from "../account/account/main/Main";
import EditAddresses from "../account/account/address/EditAddresses";
import SideMenuContainer from "../header/SideMenuContainer";
import ScrollToTop from "../ScrollToTop";
import OrdersContainer from "../account/account/order/OrdersContainer";
import {Terms} from "../terms/Terms";
import {Shop} from "../shop/Shop";
import {OrderPage} from "../account/account/order/OrderPage";
import {Collection} from "../collections/Collection";
import {CartAddress} from "../cart/shipping/CartAddress";
import {CartShipping} from "../cart/shipping/CartShipping";
import {Password} from "../account/account/password/Password";
import {CartPayment} from "../cart/payment/CartPayment";


class Routes extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object
    };


    static defaultProps = {
        currentUser: null
    };


    constructor() {
        super();

        this.container = React.createRef();
    }


    render() {
        let currentUser = this.props.currentUser;

        return (
            <Router history={History}>
                <ScrollToTop container={this.container}/>
                {/*<ScrollToTop/>*/}

                <SideMenuContainer/>

                <div canvas="container" ref={this.container} id="mainContainer">
                    <div className="backdrop offcanvas-toggle"/>

                    <Header/>

                    <Switch>
                        <Route exact path={Url.Home} component={Home}/>
                        <Route exact path={Url.About} component={About}/>
                        <Route exact path={Url.Contact} component={ContactContainer}/>
                        <Route exact path={Url.Terms} component={Terms}/>

                        {/*<Route exact path={Url.Account.Login} render={this.renderLogin.bind(this)} />*/}
                        <UnauthenticatedRoute currentUser={currentUser} exact
                                              path={Url.Account.Login}
                                              component={Login}/>

                        <UnauthenticatedRoute currentUser={currentUser} exact
                                              path={Url.Account.ConfirmationComplete}
                                              component={ConfirmationComplete}/>

                        <UnauthenticatedRoute currentUser={currentUser} exact
                                              path={Url.Account.ConfirmationSent}
                                              component={ConfirmationSent}/>

                        <UnauthenticatedRoute currentUser={currentUser} exact
                                              path={Url.Account.ConfirmationResend}
                                              component={ConfirmationResendContainer}/>

                        <UnauthenticatedRoute currentUser={currentUser} exact
                                              path={Url.Account.Confirmation}
                                              component={ConfirmationContainer}/>


                        <UnauthenticatedRoute currentUser={currentUser} exact
                                              path={Url.Account.PasswordComplete}
                                              component={PasswordComplete}/>

                        <UnauthenticatedRoute currentUser={currentUser} exact
                                              path={Url.Account.PasswordSent}
                                              component={PasswordSent}/>

                        <UnauthenticatedRoute currentUser={currentUser} exact
                                              path={Url.Account.PasswordReset}
                                              component={PasswordResetContainer}/>

                        <UnauthenticatedRoute currentUser={currentUser} exact
                                              path={Url.Account.ForgotPassword}
                                              component={ForgotPasswordContainer}/>


                        <Route exact
                               path={Url.Shop.Main}
                               component={Shop}/>

                        <Route exact
                               path={Url.Shop.Cart}
                               component={Cart}/>

                        <AuthenticatedRoute currentUser={currentUser} exact
                                            path={Url.Shop.CartShipping}
                                            component={CartShipping}/>

                        <AuthenticatedRoute currentUser={currentUser} exact
                                            path={Url.Shop.CartAddress}
                                            component={CartAddress}/>

                        <AuthenticatedRoute currentUser={currentUser} exact
                                            path={Url.Shop.CartPayment}
                                            component={CartPayment}/>


                        <AuthenticatedRoute currentUser={currentUser} exact
                                            path={Url.Account.Addresses}
                                            component={EditAddresses}/>

                        <AuthenticatedRoute currentUser={currentUser} exact
                                            path={Url.Account.Orders}
                                            component={OrdersContainer}/>

                        <AuthenticatedRoute currentUser={currentUser} exact
                                            path={Url.Account.Order(":id")}
                                            component={OrderPage}/>

                        <AuthenticatedRoute currentUser={currentUser} exact
                                            path={Url.Account.Password}
                                            component={Password}/>

                        <AuthenticatedRoute currentUser={currentUser} exact
                                            path={Url.Account.Main}
                                            component={Main}/>

                        <Route exact
                               path={Url.Shop.Product(":link")}
                               component={ProductShowcaseContainer}/>

                        <Route exact path={Url.Collection(":link")} component={Collection}/>

                        <Route
                            path="*"
                            component={NotFound}/>

                    </Switch>

                    <Footer/>
                </div>
            </Router>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        currentUser: UserSelector.currentUser(state)
    }
};


const mapPropsToDispatch = (dispatch) => {
    return {
        getCurrentUser: () => {
            dispatch(SessionAction.getCurrentUser())
        }
    }
};


export default connect(mapStateToProps, mapPropsToDispatch)(Routes);