const ConfirmationActionType = {
    CONFIRM_PREFIX: "CONFIRM",
    CONFIRM_REQUEST: "CONFIRM_REQUEST",
    CONFIRM_SUCCESS: "CONFIRM_SUCCESS",
    CONFIRM_FAILURE: "CONFIRM_FAILURE",

    RESEND_PREFIX: "RESEND",
    RESEND_REQUEST: "RESEND_REQUEST",
    RESEND_SUCCESS: "RESEND_SUCCESS",
    RESEND_FAILURE: "RESEND_FAILURE"
};

export default ConfirmationActionType;