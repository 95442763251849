import React from 'react'
import {PersonalEditor} from "../../account/account/main/PersonalEditor";
import {CartProgress} from "../CartProgress";
import {History} from "../../routes/History";
import {Url} from "../../../util/Url";
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";


export const CartPersonal = () => {

    const back = () => {
        History.goBack();
    };

    const next = () => {
        History.push(Url.Shop.CartShipping);
    };

    return (
        <main>
            <Header title="Deriva / Carrinho / Complete seu cadastro"
                    description="Para continuar, você precisa preencher alguns dados restantes no seu cadastro"
            />
            <div className="container margin-top-2x">
                <div className="row">
                    <div className="col-xs-12">
                        <CartProgress current={0}/>
                    </div>

                    <div className="col-xs-12">
                        <div className="panel no-pointer-events margin-bottom-2x">
                            <div className="panel-heading">
                                <div className="panel-title"><i className="material-icons warning"/>&nbsp;
                                    Para continuar com a compra, você precisa preencher alguns dados restantes no seu
                                    cadastro.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-md-10 col-md-offset-1 margin-bottom-1x">
                        <h2>
                            Complete seus dados
                        </h2>
                    </div>
                    <div className="col-xs-12 cart-personal">
                        <PersonalEditor cart={true} onSave={next} onCancel={back} />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default CartPersonal;