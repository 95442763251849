import React from 'react'
import {Field, reduxForm} from "redux-form";
import FieldComponent from "../util/FieldComponent";
import Validator from "../../util/validator";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";
import {Analytics} from "../../analytics/Analytics";


class Contact extends React.Component {

    static propTypes = {};

    static defaultProps = {
        onSubmit: x => {
            console.log(x);
        }
    };

    render() {
        const {handleSubmit} = this.props;

        const track = (method) =>
        {
            Analytics.Contact(method);
        };

        return (
            <main>
                <Header title="Deriva / Contato"
                        description="Manda uma mensagem pra a gente"
                        keywords="contato"
                />
                <section className="fw-section padding-bottom-4x padding-top-4x" style={{backgroundImage: "url(/img/contact/bg.jpg)"}}>
                    <div className="container">
                        <h2 className="text-left margin-bottom-2x text-light">
                            Manda uma mensagem pra a gente!
                        </h2>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="comment-respond padding-bottom-2x">
                                    <form id="comment-form" className="comment-form" onSubmit={handleSubmit}>
                                        <div className="row margin-bottom-1x">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <Field name="name" component={FieldComponent} type="text"
                                                           className="form-control input-alt"
                                                           placeholder="Nome"
                                                           validate={[Validator.Required]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <Field name="email" component={FieldComponent} type="text"
                                                           className="form-control input-alt"
                                                           placeholder="E-mail"
                                                           validate={[Validator.Required, Validator.Email]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Field name="message" component={FieldComponent} type="textarea"
                                                   className="form-control input-alt" style={{resize: "none"}}
                                                   rows="10" placeholder="Digite sua mensagem"
                                                   validate={[Validator.Required]}
                                            />
                                        </div>
                                        <p className="form-submit text-center margin-top-1x">
                                            <button name="submit" type="submit" id="submit"
                                                    className="btn btn-default"
                                                    style={{width: "170px", lineHeight: "45px"}}
                                            >
                                                Enviar
                                            </button>
                                        </p>
                                    </form>
                                </div>
                            </div>

                            <div className="col-md-6 text-center margin-top-4x">
                                <div className="margin-bottom-1x">
                                    <a href="https://wa.me/5581991919368" target="_blank" rel="noopener noreferrer"
                                       className="btn left-icon"
                                       style={{textTransform: "none", width: "170px", lineHeight: "45px"}}
                                       onClick={() => track("WhatsApp")}
                                    >

                                        {/*<img src="/img/social/whatsapp.png" style={{ width: "40px", marginRight: "10px" }}/>*/}
                                        <i className="socicon-whatsapp"
                                           style={{fontSize: "32px", verticalAlign: "middle"}}/>

                                        WhatsApp
                                    </a>
                                </div>
                                <div>
                                    <a href="mailto:contato@somosderiva.com" target="_blank" rel="noopener noreferrer"
                                       className="btn left-icon"
                                       style={{textTransform: "none", width: "170px", lineHeight: "45px"}}
                                       onClick={() => track("E-mail")}
                                    >


                                        {/*<img src="/img/social/whatsapp.png" style={{ width: "40px", marginRight: "10px" }}/>*/}
                                        <i className="material-icons mail_outline"
                                           style={{fontSize: "34px", verticalAlign: "middle"}}/>

                                        E-mail
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}


Contact = reduxForm({
    form: 'contact'
})(Contact);


export default Contact;

