import React from "react";
import {AddressCard} from "../../../address/AddressCard";
import moment from "moment/moment";
import 'moment/locale/pt-br';
import OrderStatus from "../../../../models/OrderStatus";
import {PaymentDetailsPix} from "./PaymentDetailsPix";
import {PaymentDetailsTransfer} from "./PaymentDetailsTransfer";
import {PaymentDetailsBill} from "./PaymentDetailsBill";


export const PanelPayment = ({order}) => {

    const payment = order.payment;

    // order.trackingNumber = "AA123456785BR";

    let status = <span className="black">Pagamento aprovado!</span>;

    switch (order.status) {
        case OrderStatus.created:
            status = "Aguardando confirmação do pagamento."
            break;

        case OrderStatus.cancelled:
            status = "O seu pedido foi cancelado."
            break;
    }

    let details = null;

    if (order.status === OrderStatus.created) {
        switch (order.paymentType) {
            case "BillPayment":
                details = <PaymentDetailsBill order={order}/>;
                break;

            case "PixPayment":
                details = <PaymentDetailsPix order={order}/>;
                break;

            case "TransferPayment":
                details = <PaymentDetailsTransfer order={order}/>
                break;
        }
    }


    return <div className="row">
        <div className="col-xs-12 margin-bottom-1x">
            {status}
        </div>
        <div className="col-xs-12">
            Forma de pagamento:&nbsp;
            <span className="black">
                {order.paymentMethod}
            </span>
        </div>
        {details}
    </div>
};