import {createApiAction} from "./api/util";

import PasswordActionType from "./PasswordActionType";
import {Analytics} from "../analytics/Analytics";


class PasswordAction {


    static forgot(email) {

        let data = {
            user: {
                email: email
            }
        };

        Analytics.Event("forgot_password");

        return createApiAction(PasswordActionType.FORGOT_PREFIX, 'POST', 'users/password.json', data);
    }


    static reset(token, password, passwordConfirmation)
    {
        let data = {
            user: {
                reset_password_token: token,
                password: password,
                password_confirmation: passwordConfirmation
            }
        };

        Analytics.Event("save_password");

        return createApiAction(PasswordActionType.RESET_PREFIX, 'PUT', 'users/password.json', data);
    }

}


export default PasswordAction;