import React from "react";
import PropTypes from 'prop-types'
import {Redirect, Route} from "react-router-dom";


class UnauthenticatedRoute extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object
    };


    static defaultProps = {
        currentUser: null
    };


    render() {

        let currentUser = this.props.currentUser;
        let loggedIn = currentUser != null;

        let { component: Component, ...rest } = this.props;

        return (
            <Route
                {...rest}

                render={ props => !loggedIn ?
                    <Component {...props} />
                    :
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                }
            />
        );
    }
}


export default UnauthenticatedRoute;