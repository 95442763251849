import {createSelector} from 'redux-orm';

import orm from "../models";
import {entitiesSelector} from "./index";


class UserSelector {

    static currentUserId = state => state.application.currentUserId;


    static currentUser = createSelector(
        orm,
        entitiesSelector,
        UserSelector.currentUserId,
        (session, id) => {
            if (id == null) {
                return null;
            }

            const {User} = session;

            return UserSelector.parseUser(User.withId(id));
        }
    );


    static parseUser(user)
    {
        if (user == null) {
            return null;
        }

        return {
            ...user.ref,
            addresses: user.addresses.toRefArray(),
            cart: user.cart != null ? user.cart.ref : null,
            orders: user.orders.toRefArray(),
        };
    }

}

export default UserSelector;