import React, {Component} from 'react';
import {withRouter} from 'react-router';

const PREFIX = '@@scroll/';

class ScrollToTop extends Component {

    static locationVisited = {};


    constructor(props) {
        super(props);

        this.onScroll = this.onScroll.bind(this);
    }


    getContainer() {
        if (this.props.container != null)
        {
            return this.props.container.current;
        }

        return window;
    }


    componentDidMount() {
        // history.scrollRestoration = 'manual';

        const container = this.getContainer();

        if (container != null) {
            container.addEventListener('scroll', this.onScroll);
        }
    }


    componentWillUnmount() {
        // history.scrollRestoration = 'auto';

        const container = this.getContainer();

        if (container != null) {
            container.removeEventListener('scroll', this.onScroll);
        }
    }


    componentDidUpdate(prevProps)
    {
        if (prevProps.container !== this.props.container) {
            if (prevProps.container.current != null) {
                prevProps.container.current.removeEventListener('scroll', this.onScroll);
            }

            this.componentDidMount();
        }

        // const { location } = this.props;
        //
        // if (location !== prevProps.location) {
        //     // if (action === 'PUSH') {
        //         // new navigation - scroll to top
        //         this.scrollTo(0);
        //
        //     // } else {
        //     //     const key = this.getLocationKey(location.key);
        //     //
        //     //     if (ScrollToTop.locationVisited.hasOwnProperty(key)) {
        //     //         const scrollY = ScrollToTop.locationVisited[key];
        //     //         this.scrollTo(scrollY);
        //     //     }
        //     // }
        // }
    }


    onScroll() {
        const self = this;

        requestAnimationFrame(() => {
            const key = self.getLocationKey(self.props.location.key);

            ScrollToTop.locationVisited[key] = self.getContainer().scrollTop;
        });
    }


    getLocationKey(key) {
        return PREFIX + (key || '');
    }


    scrollTo(Y) {
        const self = this;

        requestAnimationFrame(() => {
            self.getContainer().scrollTop = Y;
        });
    }


    componentWillReceiveProps(nextProps) {
        // const { location, history: { action } } = nextProps;
        const { location } = nextProps;

        if (location !== this.props.location) {
            // if (action === 'PUSH') {
            //     // new navigation - scroll to top
            //     this.scrollTo(0);
            //
            // } else {
            //     const key = this.getLocationKey(location.key);
            //
            //     if (ScrollToTop.locationVisited.hasOwnProperty(key)) {
            //         const scrollY = ScrollToTop.locationVisited[key];
            //         this.scrollTo(scrollY);
            //     }
            // }
            this.scrollTo(0);
        }
    }

    render() {
        return <div id="scrollToTop"/>;
    }
}

export default withRouter(ScrollToTop);
