import React from "react";
import {computeDiscountProducts} from "../../../models/Order";


export const CartTotalValues = ({productsPrice, coupon, shipping}) => {

    let finalPrice = productsPrice;
    let discount = 0;
    let hasShipping = false;

    if (coupon != null) {
        discount = computeDiscountProducts(finalPrice, coupon);
        finalPrice -= discount;
    }

    if (shipping != null) {
        finalPrice += shipping.price;
        hasShipping = true;
    }

    return <div className="cart-total-values">
        {discount <= 0 && !hasShipping ? null :
            <div className="entry black">
                <div>Subtotal</div>
                <div className="value" style={{textDecoration: discount > 0 ? "line-through" : "none"}}>R$ {productsPrice}</div>
            </div>
        }
        {discount <= 0 ? null :
            <div className="entry">
                <div>Cupom</div>
                <div className="value">R$ -{discount}</div>
            </div>
        }
        {!hasShipping ? null :
            <div className="entry">
                <div>Frete</div>
                <div className="value">R$ {shipping.price}</div>
            </div>
        }

        <div className="entry black" style={{marginTop: 10}}>
            <div>Total</div>
            <div className="value">R$ {finalPrice}</div>
        </div>
    </div>;
}