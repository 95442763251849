import React from "react";


export const CouponDescription = ({coupon}) => {

    if (coupon == null) {
        return null;
    }

    const description = [];

    if (coupon.discountShipping) {
        description.push("Frete grátis");
    }

    if (coupon.discountPercentage > 0) {
        description.push(Math.floor(coupon.discountPercentage * 100) + "% de desconto");
    }

    if (coupon.discountValue > 0) {
        description.push("R$ " + coupon.discountValue + " de desconto");
    }

    return <div style={{marginTop: 2}}>
        { description.map(d => <span key={d}>+ {d}<br/></span>) }
    </div>;
}