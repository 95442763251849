import React from "react";


export const PaymentDetailsTransfer = () => {

    return <div className="col-xs-12 margin-top-1x">
        Para continuar com o pagamento, entre em contato conosco através do e-mail
        <a href="mailto:pedidos@somosderiva.com" target="_blank"
           rel="noopener noreferrer"> pedidos@somosderiva.com </a>
        ou do nosso
        <a href="https://wa.me/5581991919368" target="_blank"
           rel="noopener noreferrer"> WhatsApp (+55 81 99191-9368)</a>.
    </div>;
}