import React from 'react';
import PropTypes from 'prop-types'
import {Link, withRouter} from "react-router-dom";
import {Url} from "../../util/Url";
import CartTooltipContainer from "./CartTooltipContainer";


class CartMenu extends React.Component {

    static propTypes = {
        currentCart: PropTypes.object,
    };


    renderCounter() {
        let cart = this.props.currentCart;

        if (cart == null) {
            return null;
        }

        if (cart.cartItems == null || cart.cartItems.length === 0) {
            return null;
        }

        let amount = cart.cartItems.reduce((acc, x) => acc + x.amount, 0);

        return <span className="counter">{amount}</span>;
    }


    render() {
        return (
            <div className="cart-container">
                <Link to={Url.Shop.Cart} onClick={this.close} className="header-tools-link cart-link">
                    <i className="material-icons shopping_cart"/>
                    {this.renderCounter()}
                </Link>
                <CartTooltipContainer/>
            </div>
        );
    }
}


export default withRouter(CartMenu);