import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import Validator, {formatError} from "../../util/validator";
import FieldComponent from "../util/FieldComponent";
import ButtonLoader from "../util/ButtonLoader";


class RegistrationForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func
    };


    static defaultProps = {
        onSubmit: x => {
            console.log(x);
        }
    };


    render() {
        const {handleSubmit, pristine, submitting} = this.props;

        return (
            <div>
                <img src="/img/login_02.png" style={{width: "auto"}} alt=""/>
                <h5>Crie sua conta</h5>

                <div className="row margin-top-1x">
                    <div className="col-md-8 col-md-offset-2">
                        <form id="formRegistration" onSubmit={handleSubmit}>

                            <div className="margin-bottom-1x">
                                <Field name="name" type="text" component={FieldComponent}
                                       className="form-control text-center"
                                       placeholder="Nome completo"
                                       validate={[Validator.Required]}
                                />
                            </div>

                            <div className="margin-bottom-1x">
                                <Field name="email" type="text" component={FieldComponent}
                                       className="form-control text-center"
                                       placeholder="E-mail" autoComplete="username"
                                       validate={[Validator.Required, Validator.Email]}
                                />
                            </div>

                            <div className="margin-bottom-1x">
                                <Field name="password" type="password" component={FieldComponent}
                                       className="form-control text-center"
                                       placeholder="Senha" autoComplete="new-password"
                                       validate={[Validator.Required, Validator.LengthMin6]}
                                />
                            </div>

                            <div className="margin-bottom-1x">
                                <Field name="passwordConfirmation" type="password" component={FieldComponent}
                                       className="form-control text-center"
                                       placeholder="Confirme sua Senha" autoComplete="new-password"
                                       validate={[Validator.Required, Validator.LengthMin6, Validator.PasswordConfirmation]}
                                />
                            </div>

                            {formatError(this.props.error)}

                            <div className="margin-top-1x">
                                <button type="submit" className="btn btn-default btn-block margin-right-none"
                                        style={{width: "100%"}} disabled={pristine || submitting}>Cadastrar

                                    <ButtonLoader loading={submitting}/>
                                </button>
                            </div>

                            <div>
                                <label className="checkbox pull-left">
                                    <Field name="newsletter" type="checkbox" component="input"/>
                                    &nbsp;Quero receber as novidades
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


RegistrationForm = reduxForm({
    form: 'registration',
    initialValues: {newsletter: true}
})(RegistrationForm);


export default RegistrationForm;