import {apiUrl, createApiHeaders, createTypeAndParse} from "./api/util";
import ReactGA from 'react-ga4';
import OrderActionType from "./OrderActionType";
import {getJSON, RSAA} from "redux-api-middleware";
import {normalize} from "normalizr";
import {camelizeKeys} from "humps";
import Order from '../models/Order';
import Cart from "../models/Cart";
import {Analytics} from "../analytics/Analytics";


class OrderAction
{
    static create(shippingId, coupon, payment, data) {

        const typeSuccess = {
            type: OrderActionType.CREATE_SUCCESS,
            payload: (action, state, res) => {
                if (res.redirected) {
                    window.location.href = res.url;
                }

                return getJSON(res).then((json) => {
                    const schema = normalize(camelizeKeys(json), Order.Schema);

                    Analytics.Purchase(schema.result);

                    return schema;
                });
            }
        };

        const typeFailure = {
            type: OrderActionType.CREATE_FAILURE,
            payload: (action, state, res) => {
                return getJSON(res).then((json) => {
                    console.log("OrderAction.create error: ");
                    console.log(json);

                    let response = {};

                    if (json.cart != null) {
                        response = normalize(camelizeKeys(json.cart), Cart.Schema);
                    }

                    response.response = json.response || json.error;

                    return response;
                });
            }
        };

        data = {
            shipping_id: shippingId,
            coupon,
            payment,
            data
        };

        let action = {
            endpoint: apiUrl('orders/create.json'),
            method: 'POST',
            headers: createApiHeaders(),
            types: [ OrderActionType.CREATE_REQUEST, typeSuccess, typeFailure ],
            body: JSON.stringify(data)
        };

        return {
            [RSAA]: action
        }
    }
}



export default OrderAction;