import React, {useState} from "react";


export const Copyable = ({value, rows}) => {
    const [complete, setComplete] = useState(false);

    if (rows == null) {
        rows = 1;
    }

    const getInputId = () => {
        return "copyable-input-" + value;
    };

    const getStatusId = () => {
        return "copyable-status-" + value;
    };


    const copyToClipboard = () => {
        const copyText = document.getElementById(getInputId());
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");

        copyText.blur();

        const copyStatus = document.getElementById(getStatusId());
        copyStatus.textContent = "Copiado!";

        console.log("Copied to clipboard: " + value);

        setComplete(true);
    };


    return <div onClick={copyToClipboard} style={{cursor: "pointer"}}>
        <textarea id={getInputId()} value={value} className="black text-center" readOnly={true} rows={rows}
                  style={{border: "1px solid rgba(0, 0, 0, 0.15)", resize: "none", width: "100%"}}
                  onClick={copyToClipboard}/>
        <div style={{textAlign: "center"}} className={!complete ? "black" : ""}>
            <i className="material-icons content_copy copy-clipboard" onClick={copyToClipboard}/>&nbsp;
            <span id={getStatusId()}><u>Copiar</u></span>
        </div>
    </div>;
};