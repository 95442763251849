import { Modal } from "semantic-ui-react";
import React, {useState} from "react";


export const Measurement = ({product}) => {
    const [isOpen, setOpen] = useState(false);

    if (product == null || (product.measurementDrawing == null && product.measurementTable == null))
    {
        return null;
    }


    const open = () => {
        setOpen(true);
    };

    const close = () => {
        setOpen(false);
    };


    return <>
        <div style={{
            "textDecoration": "underline",
            "cursor": "pointer",
            "display": "inline"
        }} onClick={open}>
            <img src="/img/store/measurementIcon.svg" style={{
                "opacity": 0.33,
                "width": 32,
                "marginTop": -8,
                "verticalAlign": "top"
            }}/>Guia de Medidas
        </div>
        <Modal
            closeIcon
            size="large"
            open={isOpen}
            onClose={close}
            style={{"overflow": "visible"}}
        >
            <Modal.Header>
                <h2>
                    {product.name}
                    <small>
                    <span style={{"float": "right", "fontVariant": "all-petite-caps", "marginTop": 5}}>
                        {product.patternString}
                    </span>
                    </small>
                </h2>
            </Modal.Header>
            <Modal.Content>
                <div className="row measurement-modal">
                    <div className="col-xs-12 col-lg-6 margin-top-1x margin-bottom-1x">
                        <img src={product.measurementDrawing}/>
                    </div>
                    <div className="col-xs-12 col-lg-6 margin-top-2x margin-bottom-1x measurement-table">
                        <img src={product.measurementTable} className="crisp-edges"/>
                    </div>
                </div>
            </Modal.Content>
        </Modal>
    </>;
}