import React, {useEffect, useState} from "react";
import QRCode from "react-qr-code";
import {Copyable} from "./Copyable";
import moment from "moment/moment";


export const PaymentDetailsPix = ({order}) => {
    const calculateTimeRemaining = function () {
        if (order.payment.expiresAt == null) {
            return -1;
        }

        return moment(order.payment.expiresAt).diff(moment(), 'minutes') + 1;
    };

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

    useEffect(() => {
        if (timeRemaining <= 0) {
            return;
        }

        const interval = setInterval(() => {
            const t = calculateTimeRemaining();

            if (timeRemaining !== t) {
                setTimeRemaining(t);
            }

        }, 10000);

        return () => clearInterval(interval);
    }, []);


    let valid = timeRemaining > 0;

    const code = order.payment.copyPaste;
    valid = valid && (code != null && code !== "");

    if (!valid)
    {
        return <div className="col-xs-12 margin-top-1x">
            O seu QR Code expirou. Para continuar com o pagamento, entre em contato conosco através do e-mail
            <a href="mailto:pedidos@somosderiva.com" target="_blank"
               rel="noopener noreferrer"> pedidos@somosderiva.com </a>
            ou do nosso
            <a href="https://wa.me/5581991919368" target="_blank"
               rel="noopener noreferrer"> WhatsApp (+55 81 99191-9368)</a>.
        </div>;
    }

    return <div>
        <div className="col-md-6 col-xs-12 margin-top-1x">
            <div style={{width: 220, height: 220, margin: "auto", padding: 10, background: "white"}}>
                <QRCode value={code} size={200} bgColor="#ffffff" />
            </div>
        </div>
        <div className="col-md-6 col-xs-12 margin-top-1x">
            <p>
                Leia o QR Code ou copie e cole o código abaixo e efetue o pagamento pelo aplicativo ou site do seu
                banco.<br/>
                O QR Code é válido por <span className="black">{timeRemaining} minuto{timeRemaining === 1 ? "" : "s"}</span>.<br/>
            </p>
            <Copyable value={code} rows={4}/>
        </div>
    </div>;
};