import React from 'react';

// import {validate as validateCPF} from "gerador-validador-cpf";
import ValidationError from "../containers/util/ValidationError";


const Validator = {

    Required: value => (value || typeof value === 'number' ? undefined : 'obrigatório'),

    LengthMax: max => value => value && value.length > max ? `máximo de ${max} caracteres` : undefined,
    LengthMin: min => value => value && value.length < min ? `mínimo de ${min} caracteres` : undefined,
    LengthMin6: value => Validator.LengthMin(6)(value),

    Numeric: value => value && isNaN(Number(value)) ? 'Must be a number' : undefined,

    ValueMin: min => value => value && value < min ? `Must be at least ${min}` : undefined,
    ValueMax: max => value => value && value > max ? `Must be maximum of ${max}` : undefined,

    Email: value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'e-mail inválido' : undefined,

    PasswordConfirmation: (value, allValues) => (value !== allValues.password) ? 'senhas não conferem' : undefined,

    Cpf: value => validateCPF(value) ? null : "CPF inválido",

    CheckAsync: (asyncError) => {
        return (asyncError == null || asyncError.response == null || asyncError.response.message == null) ? "" : (
            <ValidationError>
                {asyncError.response.message}
            </ValidationError>
        );
    }
};


export default Validator;


export const formatError = (error) => {
    if (error == null || error === "") {
        return null;
    }

    return (
        <ValidationError>
            {error}
        </ValidationError>
    );
};


export function validateCPF(cpf) {
    if (cpf === null)
    {
        return false;
    }

    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf === '') return false;

    // Elimina CPFs invalidos conhecidos
    if (cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999") {
        return false;
    }

    // Valida 1o digito
    let add = 0;

    for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
        rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(9))) {
        return false;
    }

    // Valida 2o digito
    add = 0;

    for (let i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
    }

    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
        rev = 0;
    }

    return rev === parseInt(cpf.charAt(10));
}