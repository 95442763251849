import React from 'react'

import Carousel from './carousel/Carousel'
import NewsletterContainer from "./newsletter/NewsletterContainer";
import {FeaturedCollectionShowcase} from "./showcase/FeaturedCollectionShowcase";
import Instagram from "./instagram/Instagram";
import {useSelector} from "react-redux";
import CollectionSelector from "../../selectors/CollectionSelector";
import ProductSelector from "../../selectors/ProductSelector";
import {FrontWindow} from "../../views/sections/front_window/FrontWindow";
import {Categories} from "../../views/sections/categories/Categories";
import ArrayUtil from "../../util/ArrayUtil";
import {Url} from "../../util/Url";
import moment from "moment/moment";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";


export const Home = () => {
    const collections = useSelector(CollectionSelector.all);
    const products = useSelector(ProductSelector.all);

    var p0 = 76;
    var p1 = 77;
    var p2 = 91;
    var p3 = 89;
    var f = 94;

    const getProduct = (id) => {
        return products.find(x => x.id === id);
    };

    const p = [
        getProduct(p0),
        getProduct(p1),
        getProduct(p2),
        getProduct(p3),
    ];

    f = getProduct(f);


    products.sort((a, b) => b.id - a.id);

    const categories = [];

    for (let i in products) {
        const product = products[i];


        if (product.inventory === 0) continue;
        //
        // const pattern = ArrayUtil.random(patterns);
        // console.log(pattern);
        // if (pattern == null) continue;

        const name = product.name.toUpperCase();
        if (categories.find(x => x.label === name) != null) continue;

        categories.push({
            image: product.picturesShop[0],
            label: name,
            href: Url.Shop.Main
        })
    }

    return (
        <main>
            <Header title="Deriva"
                    description="Conforto e originalidade traduzidos em peças únicas e estampas exclusivas. De Recife para todo o Brasil."
                    image="https://www.somosderiva.com/img/slider/3.jpg"
                    keywords="deriva roupa estampa vestido camisa blusa"
            />
            <Carousel/>
            <div className="margin-bottom-1x hidden-md hidden-lg"/>

            <FrontWindow title="Novidades" products={p} featuredProduct={f}/>
            <Categories title="Categorias" items={categories}/>
            {/*<FeaturedCollectionShowcase/>*/}
            <Instagram/>
            <NewsletterContainer/>
        </main>
    );
}



