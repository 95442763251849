import React from "react";
import OrderStatus from "../../../../models/OrderStatus";

export const OrderInfo = ({order}) => {

    let title = "";
    let description = "";

    switch (order.status) {
        case OrderStatus.created:
            title = "Pedido realizado!";
            description = "O seu pedido foi realizado com sucesso.";
            break;

        case OrderStatus.payed:
            title = "Pagamento confirmado!";
            description = "Estamos preparando o seu pedido e iremos enviá-lo para você o mais rápido possível.";
            break;

        case OrderStatus.sent:
            title = "Pedido enviado!";
            description = "O seu pedido está a caminho endereço de entrega.";
            break;

        case OrderStatus.delivered:
            title = "Pedido entregue!";
            description = "Ficamos felizes que o seu pedido ja está com você. Conta pra gente o que achou!";
            break;

        case OrderStatus.cancelled:
            title = "Pedido cancelado!";
            description = "O seu pedido foi cancelado.";
            break;

    }

    return <div>
        <h3 className="margin-bottom-1x">
            {title}
        </h3>
        <p>
            {description}
        </p>
    </div>
};