import React from 'react';
import {updateObject} from "../../reducers/util";
import {ValidationError} from "./ValidationError";

const FieldComponent = (data) => {
    let meta = data.meta;
    let input = data.input;

    let htmlData = updateObject({}, data);

    delete htmlData.meta;
    delete htmlData.input;

    let style = htmlData.hasOwnProperty("style") ? updateObject({}, htmlData.style) : null;
    let marginBottom = null;

    if (style == null)
    {
        style = {};
    }
    else
    {
        marginBottom = style.marginBottom;
    }

    style.marginBottom = "5px";

    let inputComponent = <input {...input} {...htmlData} style={style}/>;

    if (htmlData.type === "textarea")
    {
        inputComponent = <textarea {...input} {...htmlData} style={style}/>;
    }


    style = {};

    if (marginBottom != null)
    {
        style.marginBottom = marginBottom;
    }

    let error = !meta.touched || !meta.error ? "" :
        (
            <ValidationError style={style}>
                {meta.error}
            </ValidationError>
        );

    return (
        <div>
            {inputComponent}
            {error}
        </div>
    );
};


export default FieldComponent;