import {createApiAction} from "./api/util";

import User from "../models/User";
import RegistrationActionType from "./RegistrationActionType";
import {Analytics} from "../analytics/Analytics";


class RegistrationAction {


    static register(email, password, passwordConfirmation, name, newsletter) {

        Analytics.Register("site");

        let data = {
            user: {
                email,
                password,
                password_confirmation: passwordConfirmation,
                name,
            },
            newsletter
        };

        return createApiAction(RegistrationActionType.REGISTER_PREFIX, 'POST', 'users.json', data, User.Schema);
    }


    static update(email, name, birthday, cpf, phone) {

        Analytics.Event("update_account");

        let data = {
            user: {
                email,
                name,
                birthday,
                cpf,
                phone,
            }
        };

        return createApiAction(RegistrationActionType.UPDATE_PREFIX, 'PATCH', 'users.json', data, User.Schema);
    }


    static updatePassword(currentPassword, password, passwordConfirmation) {

        Analytics.Event("update_password");

        let data = {
            user: {
                current_password: currentPassword,
                password,
                password_confirmation: passwordConfirmation,
            }
        };

        return createApiAction(RegistrationActionType.REGISTER_PREFIX, 'PUT', 'users.json', data);
    }


}


export default RegistrationAction;