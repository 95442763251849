import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import CartAction from "../../actions/CartAction";
import CartMenu from "./CartMenu";
import CartSelector from "../../selectors/CartSelector";


class CartMenuContainer extends React.Component {

    static propTypes = {
        currentCart: PropTypes.object,
    };


    componentDidMount()
    {
        this.props.loadData();
    }


    render() {
        return <CartMenu {...this.props}/>
    }
}


const mapStateToProps = (state) => {
    return {
        currentCart: CartSelector.current(state),
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
        loadData: () => {
            return dispatch(CartAction.getCurrent())
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(CartMenuContainer);

