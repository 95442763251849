import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import LoginForm from "./LoginForm";
import SessionAction from "../../actions/SessionAction";
import {validateApiResponse} from "../../actions/api/util";
import {History} from "../routes/History";


class LoginFormContainer extends React.Component {

    static propTypes = {
        redirectTo: PropTypes.string,
        onSubmit: PropTypes.func
    };


    render() {
        return <LoginForm onSubmit={this.props.onSubmit}/>;
    }
}


const mapStateToProps = () => {
    return {
    };
};


const mapPropsToDispatch = (dispatch, props) => {
    return {
        onSubmit: (values) => {
            return dispatch(SessionAction.login(values.email, values.password))
                .then(validateApiResponse)
                .then((u) => {
                    if (props.onSuccess != null)
                    {
                        props.onSuccess();
                        return;
                    }

                    // console.log("-=-=-=-===-=-=-=-=-=-=");
                    // console.log(u);
                })
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(LoginFormContainer);

