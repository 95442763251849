import {store} from "../index";
import ProductSelector from "../selectors/ProductSelector";
import CartSelector from "../selectors/CartSelector";
import ReactGA from "react-ga4";

const fbq = window.fbq;

export class MetaAnalytics {

    constructor(id) {
        fbq('set', 'autoConfig', false, id);
        fbq('init', id);
    }


    PageView(title, path) {
        fbq('track', 'PageView', {
            content_name: title
        });
        // fbq('track', 'ViewContent', {
        //     content_name: title
        // });
    }


    Event(name) {
        const words = name.split("_");

        words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);

        name = words.join(' ');

        console.log("[Meta] " + name);
        fbq('trackCustom', name);
    }


    Contact(method) {
        const data = {
            content_type: method
        };

        console.log("[Meta] Contact", data);
        fbq('track', 'Contact', data);
    }


    Login(method) {
        const data = {
            content_category: method
        };

        console.log("[Meta] Login", data);
        fbq('trackCustom', 'Login', data);
    }


    Register(method) {
        const data = {
            content_category: method,
            status: true
        };

        console.log("[Meta] CompleteRegistration", data);
        fbq('track', 'CompleteRegistration', data);
    }


    AddToCart(productVariationId, amount) {
        const state = store.getState();

        const product = ProductSelector.productByVariation(state, productVariationId);

        const data = {
            currency: "BRL",
            value: product.price * amount,
            content_type: "product",
            contents: [this.createContent(productVariationId, amount)]
        };

        console.log("[Meta] AddToCart", data);
        fbq('track', 'AddToCart', data);
    }


    createContent(productVariationId, amount) {
        const state = store.getState();

        const product = ProductSelector.productByVariation(state, productVariationId);
        const productVariation = ProductSelector.productVariation(state, productVariationId);

        return {
            id: productVariation.sku,
            quantity: amount,
            name: product.name,
            category: product.pattern.name,
            category2: product.pattern.collection.name,
            variant: productVariation.size,
            price: product.price
        };
    }


    RemoveFromCart(productVariationId, amount) {
        const state = store.getState();

        const product = ProductSelector.productByVariation(state, productVariationId);
        // const productVariation = ProductSelector.productVariation(state, productVariationId);

        const data = {
            // content_ids: [productVariation.sku],
            // content_name: product.name,
            // content_category: product.patternString,
            // content_variant: productVariation.size,
            // content_type: "product",
            // content_quantity: amount,
            // currency: "BRL",
            // value: product.price * amount

            currency: "BRL",
            value: product.price * amount,
            content_type: "product",
            contents: [this.createContent(productVariationId, amount)]
        };

        console.log("[Meta] RemoveFromCart", data);
        fbq('trackCustom', 'Remove from cart', data);
    }


    SelectCoupon(code) {
        const data = {
            content_name: code
        };

        console.log("[Meta] Select coupon", data);
        fbq('trackCustom', 'Select coupon', data);
    }


    ViewCart() {
        const state = store.getState();

        const cart = CartSelector.currentDetailed(state);
        if (cart == null) return;

        const contents = cart.cartItems.map(item => this.createContent(item.productVariationId, item.amount));

        const data = {
            currency: "BRL",
            value: cart.price,
            content_name: "Carrinho",
            content_category: "Carrinho",
            content_type: "product",
            contents: contents
        }

        console.log("[Meta] ViewContent", data);
        fbq('track', 'ViewContent', data);
    }


    ViewProduct(product) {

        const data = {
            currency: "BRL",
            value: product.price,
            content_type: "product",
            contents: [
                {
                    id: product.id,
                    quantity: 1,
                    name: product.name,
                    category: product.pattern.name,
                    category2: product.pattern.collection.name,
                    price: product.price
                }
            ]
        }

        console.log("[Meta] ViewContent", data);
        fbq('track', 'ViewContent', data);
    }


    SelectVariation(productVariationId) {
        const state = store.getState();

        const productVariation = ProductSelector.productVariation(state, productVariationId);

        const data = {
            content_category: "Size",
            content_name: productVariation.size,
            content_type: "product",
            contents: [ this.createContent(productVariationId, 1) ]
        }

        console.log("[Meta] CustomizeProduct", data);
        fbq('track', 'CustomizeProduct', data);
    }


    InitiateCheckout() {
        const data = this.createCartData();

        console.log("[Meta] InitiateCheckout", data);
        fbq('track', 'InitiateCheckout', data);
    }


    AddShippingInfo() {
        const state = store.getState();

        const data = this.createCartData();

        const shipping = CartSelector.shipping(state);
        data.delivery_type = shipping.name;
        data.delivery_category = shipping.method === "delivery" ? "home_delivery" : "curbside";

        console.log("[Meta] Add shipping info", data);
        fbq('trackCustom', 'Add shipping info', data);
    }


    AddPaymentInfo(type) {
        const data = this.createCartData();

        data.payment_type = type;

        console.log("[Meta] AddPaymentInfo", data);
        fbq('track', 'AddPaymentInfo', data);
    }


    Purchase(orderId) {
        const data = this.createCartData();

        data.transaction_id = orderId;

        console.log("[Meta] Purchase", data);
        fbq('track', 'Purchase', data);
    }


    createCartData()
    {
        const state = store.getState();

        const cart = CartSelector.currentDetailed(state);
        if (cart == null) return;

        const contents = cart.cartItems.map(item => this.createContent(item.productVariationId, item.amount));

        const data = {
            currency: "BRL",
            value: cart.price,
            num_items: contents.length,
            content_type: "product",
            contents: contents
        }

        const coupon = CartSelector.coupon(state);

        if (coupon != null)
        {
            data.coupon = coupon.code;
        }

        return data;
    }
}