import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import {Url} from "../../../util/Url";


class PatternShowcase extends React.Component {

    SECTION_PREVIEW = 0;
    SECTION_PROMOTION = 1;


    static propTypes = {
        reverse: PropTypes.bool,
        pattern: PropTypes.object
    };


    render() {
        let reverseClassName = this.props.reverse ? "reverse" : "";

        let first = this.props.reverse ? this.SECTION_PREVIEW : this.SECTION_PROMOTION;
        let second = !this.props.reverse ? this.SECTION_PREVIEW : this.SECTION_PROMOTION;

        return (
            <div className={`section-row ${reverseClassName}`}>
                {this.renderSectionItem(first)}
                {this.renderSectionItem(second)}
            </div>
        );
    }


    renderSectionItem(section) {
        let pattern = this.props.pattern;
        let link = this.props.link;

        switch (section) {
            case this.SECTION_PREVIEW:
                return (
                    <div className="section-item info-tile">
                        <div className="inner padding-top-3x padding-bottom-3x">
                            <h2>{pattern.name}</h2>
                            <p>{pattern.description}</p>

                            <div className="tile">
                                <div className="preview-box">
                                    <img src={pattern.pictureShowcase} alt="" className="brgr" />
                                </div>

                                {/*<div className="tile-title">*/}
                                {/*<Link to={Url.Shop.Product(product.link)} style={{ textTransform: "uppercase"}}>*/}
                                {/*{ product.name }*/}
                                {/*</Link>*/}
                                {/*</div>*/}
                            </div>

                            <div>
                                <Link to={link} className="btn inline">
                                    Saiba mais
                                </Link>
                                <Link to={Url.Shop.Main} className="btn left-icon inline">
                                    <i className="material-icons shopping_cart"/>
                                    Comprar
                                </Link>
                            </div>
                        </div>
                    </div>
                );
            case this.SECTION_PROMOTION:
                return (
                    <div className="section-item">
                        <img src={pattern.pictureCover} alt="" className="brgr pattern-cover"/>
                    </div>
                );
            default:
                break;
        }

        return null;
    }
}

export default PatternShowcase;