import React, {useEffect} from 'react';
import InstagramPost from "./InstagramPost";
import {useDispatch, useSelector} from "react-redux";
import ApplicationSelector from "../../../selectors/ApplicationSelector";
import InstagramAction from "../../../actions/InstagramAction";


export const Instagram = () => {
    const data = useSelector(ApplicationSelector.instagram);
    const dispatch = useDispatch();

    useEffect(() => {
        if (data == null) {
            dispatch(InstagramAction.getFeed());
        }
    });

    if (data == null) {
        return null;
    }

    let posts = data.map(post => <InstagramPost key={post.id} post={post}/>);

    return (
        <section className="fw-section margin-top-3x margin-bottom-1x">
            <div className="container-fluid">
                <a href="https://www.instagram.com/somosderiva" target="_blank">
                <h2 className="block-title text-center margin-bottom-2x">
                    @somosderiva <i className="socicon-instagram instagram-title"></i>
                    <small>Segue a gente!</small>
                </h2>
                </a>
                <br/>
                <br/>

                <div className="row">
                    {posts}
                </div>
            </div>
        </section>
    );
}


export default Instagram;
