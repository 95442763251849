import UserSelector from "../../../selectors/UserSelector";
import {useDispatch, useSelector} from "react-redux";
import React, {useRef, useState} from "react";
import {CartProgress} from "../CartProgress";
import {History} from "../../routes/History";
import {Url} from "../../../util/Url";
import CartSelector from "../../../selectors/CartSelector";
import {validateCPF} from "../../../util/validator";
import CartAction from "../../../actions/CartAction";
import {CartTotal} from "../total/CartTotal";
import OrderAction from "../../../actions/OrderAction";
import {validateApiResponse} from "../../../actions/api/util";
import {PayPalCard} from "./PayPalCard";
import ApplicationSelector from "../../../selectors/ApplicationSelector";
import ValidationError from "../../util/ValidationError";
import {PayPalButton} from "./PayPalButton";
import {Bill} from "./Bill";
import {Pix} from "./Pix";
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";
import {Analytics} from "../../../analytics/Analytics";


window.validateCart = function (user, cart, shipping) {
    if (user == null) {
        History.push(Url.Home);
        return false;
    }

    if (!validateCPF(user.cpf)) {
        History.push(Url.Shop.Cart);
        return false;
    }

    if (cart == null || cart.cartItems == null || cart.cartItems.length === 0) {
        History.push(Url.Shop.Cart);
        return false;
    }

    if (cart.address == null || shipping == null) {
        History.push(Url.Shop.CartShipping);
        return false;
    }

    return true;
}


export const CartPayment = () => {
    const user = useSelector(UserSelector.currentUser);
    const cart = useSelector(CartSelector.currentDetailed);
    const shipping = useSelector(CartSelector.shipping);
    const coupon = useSelector(CartSelector.coupon);
    const formState = useSelector(ApplicationSelector.formCartPayment);
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState(null);
    const paypalCard = useRef(null);

    const dispatch = useDispatch();

    console.log("here");
    console.trace();

    if (!window.validateCart(user, cart, shipping)) {
        return null;
    }


    const createOrder = function (payment, data) {
        if (!window.validateCart(user, cart, shipping)) {
            return;
        }

        setLoading(true);

        const shippingId = shipping == null ? null : shipping.id;
        const code = coupon == null ? null : coupon.code;

        dispatch(OrderAction.create(shippingId, code, payment, data))
            .then(validateApiResponse)
            .then((response) => {
                // console.log("onCreateResponse");
                // console.log(response);

                // setLoading(false);

                History.push(Url.Account.Order(response.payload.result));
            })
            .catch((error) => {
                // console.error("onCreateError");
                // console.error(error);

                setLoading(false);
            });
    }


    const handleError = function (error) {
        dispatch(CartAction.setPaymentError(error));
        setLoading(false);
    }


    const finish = function () {
        setLoading(true);

        switch (method) {
            case "pix":
                createOrder("pix");
                break;
            case "bill":
                createOrder("bill");
                break;
            case "card":
                paypalCard.current.process();
                break;
        }
    }


    const handleMethodClicked = function(id)
    {
        switch (id)
        {
            case "pix":
                Analytics.AddPaymentInfo("Pix");
                break;
            case "bill":
                Analytics.AddPaymentInfo("Boleto");
                break;
            case "card":
                Analytics.AddPaymentInfo("Cartão de Crédito");
                break;
            case "paypal":
                Analytics.AddPaymentInfo("PayPal");
                break;
        }

        setMethod(id);
    }


    const createMethod = function (id, title, body) {
        return <div className={`panel ${method === id ? "panel-selected" : ""}`}
                    onClick={() => handleMethodClicked(id)}>
            <div className="panel-heading">
                <a className="panel-title collapsed" data-toggle="collapse" data-parent="#payment-method"
                   href={`#panel-${id}`}>
                    {title}
                </a>
            </div>
            <div id={`panel-${id}`} className="panel-collapse collapse">
                <div className="panel-body">
                    {
                        body
                    }
                </div>
            </div>
        </div>
    }


    return (
        <main>
            <Header title="Deriva / Carrinho / Pagamento"
                    description="Escolha a forma de pagamento"
            />
            <div className="container margin-top-2x">
                <div className="row">
                    <div className="col-xs-12">
                        <CartProgress current={2}/>
                    </div>
                    <div className="col-xs-12 col-md-10 col-md-offset-1">
                        <h2>
                            Forma de pagamento
                        </h2>

                        <ValidationError>
                            {formState.error}
                        </ValidationError>

                        <div className="margin-bottom-1x">
                            Escolha a forma de pagamento:
                        </div>
                        <div className="panel-group" id="payment-method">
                            {createMethod("pix",
                                <>
                                    <img src="/img/payment/logo_pix.png" className="payment-method"/> Pix
                                </>,
                                <Pix/>
                            )}
                            {createMethod("bill",
                                <>
                                    <img src="/img/payment/barcode.png" className="payment-method"/> Boleto
                                </>,
                                <Bill/>
                            )}
                            {createMethod("card",
                                <>
                                    <img src="/img/payment/card.png" className="payment-method"/> Cartão de Crédito
                                </>,
                                <PayPalCard ref={paypalCard} onComplete={(data) => createOrder("card", data)}
                                            onError={handleError}/>
                            )}
                            {createMethod("paypal",
                                <>
                                    <img src="/img/payment/paypal.png" className="payment-method"/> PayPal
                                </>,
                                <PayPalButton onComplete={(data) => createOrder("paypal", data)} onError={handleError}/>
                            )}
                        </div>
                    </div>
                </div>
                <CartTotal step={2} onContinue={finish} paymentMethod={method} loading={loading} disabled={method == null}/>
            </div>
        </main>
    );
};