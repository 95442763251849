import React from 'react';
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";


export const ConfirmationSent = () => (
    <main>
        <Header title="Deriva / Confirmação / Enviada"
                description="Você receberá um email com instruções para confirmar sua conta em breve"
        />
        <div className="container text-center margin-top-4x margin-bottom-10x">
            <div className="row">
                <div className="col-md-8 col-md-offset-2 col-xs-12">
                    <img src="/img/login_02.png" style={{width: "auto"}} alt=""/>
                    <h5>Confirme seu e-mail</h5>

                    <p>Você receberá um email com instruções para confirmar sua conta em breve.</p>
                </div>
            </div>
        </div>
    </main>
);