import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {History} from "../routes/History";

import RegistrationForm from "./RegistrationForm";
import RegistrationAction from "../../actions/RegistrationAction";
import {validateApiResponse} from "../../actions/api/util";
import {Url} from "../../util/Url";


class RegistrationFormContainer extends React.Component {

    static propTypes = {
        onSubmit: PropTypes.func,
    };


    render() {
        return <RegistrationForm onSubmit={this.props.onSubmit} />;
    }
}


const mapStateToProps = () => {
    return {
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
        onSubmit: (values) => {
            return dispatch(RegistrationAction.register(values.email, values.password, values.passwordConfirmation, values.name, values.newsletter))
                .then(validateApiResponse)
                .then(() => {
                    History.push(Url.Account.ConfirmationSent);
                })
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(RegistrationFormContainer);

