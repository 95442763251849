import {createSelector} from 'redux-orm';

import orm from "../models";
import {entitiesSelector} from "./index";


class OrderSelector {


    static all = createSelector(orm, entitiesSelector,
        (session) => {

            const {Order} = session;

            const orders = Order.all();

            return orders.toModelArray().map(order => {

                return {
                    ...order.ref,
                    priceTotal: order.getPriceTotal(),
                    amount: order.getItemsAmount(),
                    paymentMethod: order.getPaymentMethod(),
                }

            }).sort((a, b) => (a.id < b.id) ? 1 : -1);
        }
    );


    static order = (state, id) => createSelector(orm, entitiesSelector,
        (session) => {

            const {Order} = session;

            const order = Order.withId(id);

            return {
                ...order.ref,
                priceTotal: order.getPriceTotal(),
                priceProducts: order.getPriceProducts(),
                amount: order.getItemsAmount(),
                paymentMethod: order.getPaymentMethod(),
                address: order.address.ref,
                shipping: order.shipping.ref,
                payment: order.payment.ref,
                orderItems: order.orderItems.toModelArray().map(orderItem => {
                    return {
                        ...orderItem.ref,
                        productVariation: {
                            ...orderItem.productVariation.ref,
                            product: {
                                ...orderItem.productVariation.product.ref,
                                pattern: {
                                    ...orderItem.productVariation.product.pattern.ref,
                                    collection: orderItem.productVariation.product.pattern.collection.ref
                                }
                            }
                        }
                    }
                })
            };
        }
    )(state);
}

export default OrderSelector;


// export const parseAddress = (address) => {
//     if (address == null) {
//         return null;
//     }
//
//     return {
//         ...address.ref,
//     }
// };