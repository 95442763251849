import React from 'react';
import AccountMenu from "../AccountMenu";
import {AddressManager} from "../../../address/AddressManager";
import {Header} from "../../../../headers/Header";


class EditAddresses extends React.Component {


    render() {

        return (
            <main>
                <Header title="Deriva / Conta / Endereços"
                        description="Gerencie seus endereços cadastrados."
                />
                <section className="fw-section">
                    <div className="container margin-top-2x">
                        <div className="row">
                            <AccountMenu/>
                            <div className="col-md-10 col-sm-12 margin-bottom-2x">
                                <h2>
                                    Meus endereços
                                </h2>
                                <AddressManager/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default EditAddresses;