import React from 'react'
import {OrderProgress} from "./OrderProgress";
import OrderStatus from "../../../../models/OrderStatus";
import {OrderInfo} from "./OrderInfo";
import {PanelShipping} from "./PanelShipping";
import {PanelPayment} from "./PanelPayment";
import {PanelDetails} from "./PanelDetails";
import {Helmet} from "react-helmet";
import {Header} from "../../../../headers/Header";


export const Order = ({order}) => {
    let s = null;

    switch (order.status) {
        case OrderStatus.created:
            s = "payment";
            break;

        default:
            s = "shipping";
            break;
    }

    const createPanel = function(id, title, body)
    {
        const isSelected = id === s;

        return <div className="panel panel-selected-light">
            <div className="panel-heading">
                <a className={`panel-title ${isSelected ? "" : "collapsed"}`} data-toggle="collapse" data-parent="#order-view"
                   href={`#panel-${id}`}>
                    {title}
                </a>
            </div>
            <div id={`panel-${id}`} className={`panel-collapse collapse ${isSelected ? "in" : ""}`}>
                <div className="panel-body">
                    {
                        body
                    }
                </div>
            </div>
        </div>;
    }

    const panelShipping = createPanel("shipping", "Entrega", <PanelShipping order={order}/>);
    const panelPayment = createPanel("payment", "Pagamento", <PanelPayment order={order}/>);
    const panelDetails = createPanel("details", "Detalhes", <PanelDetails order={order}/>);

    return (
        <div className="order">
            <Header title="Deriva / Conta / Detalhes do pedido"
                    description="Detalhes do pedido"
            />
            <h2 className="margin-bottom-4x">
                Pedido {order.id}
            </h2>

            <div className="row margin-bottom-4x margin-top-4x">
                <div className="visible-xs">
                    &nbsp;
                </div>
                <div className="col-xs-10 col-xs-offset-1">
                    <OrderProgress order={order}/>
                </div>
            </div>
            <div className="visible-xs">
                &nbsp;
            </div>

            <div style={{textAlign: "center"}} className="margin-bottom-2x">
                <OrderInfo order={order}/>
            </div>

            {s === "payment" ? panelPayment : panelShipping}
            <br/>
            {s === "payment" ? panelShipping : panelPayment}
            <br/>
            {panelDetails}

            <br/>
            <br/>
        </div>
    );
}

export default Order;