import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import Validator, {formatError} from "../../../../util/validator";
import FieldComponent from "../../../util/FieldComponent";


class PasswordForm extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        onSubmit: PropTypes.func
    };


    static defaultProps = {
        user: null,
        onSubmit: x => {
            console.log(x);
        }
    };


    render() {
        const {handleSubmit, pristine, submitting, submitSucceeded} = this.props;

        const success = !submitSucceeded ? null : (
            <div className="panel no-pointer-events margin-bottom-2x">
                <div className="panel-heading">
                    <div className="panel-title"><i className="material-icons check_circle"/>&nbsp;Dados alterados com sucesso!</div>
                </div>
            </div>
        );

        return (
            <form id="formPassword" onSubmit={handleSubmit}>
                {success}
                <div className="row">
                    <div className="margin-bottom-1x col-md-6">
                        <label>Senha atual *</label>
                        <Field name="current_password" type="password" component={FieldComponent}
                               className="form-control text-center" autoComplete="current-password"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="margin-bottom-1x col-md-6">
                        <label>Nova senha *</label>
                        <Field name="password" type="password" component={FieldComponent}
                               className="form-control text-center" autoComplete="new-password"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="margin-bottom-1x col-md-6">
                        <label>Confirme sua nova senha *</label>
                        <Field name="password_confirmation" type="password" component={FieldComponent}
                               className="form-control text-center" autoComplete="new-password"
                               validate={[Validator.PasswordConfirmation]}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="margin-top-1x col-md-6">
                        {formatError(this.props.error)}

                        <button type="submit" className="btn btn-default btn-block margin-right-none"
                                style={{width: "100%"}} disabled={pristine || submitting}>Salvar
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}


PasswordForm = reduxForm({
    form: 'password'
})(PasswordForm);


export default PasswordForm;