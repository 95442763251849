import "@babel/polyfill";

import React from 'react';
import ReactDOM from 'react-dom';

import configureStore from './store/configureStore';
import Main from "./Main";



export const store = configureStore();

ReactDOM.render(<Main store={store} />, document.getElementById('app'));
