import React from 'react';
import AddressAction from "../../actions/AddressAction";
import {validateApiResponse} from "../../actions/api/util";
import AddressForm from "./AddressForm";
import {useDispatch} from "react-redux";


export const AddressEditor = ({address, onSave, onCancel, cart}) => {
    const dispatch = useDispatch();

    const save = (values) => {
        return dispatch(AddressAction.upsert(values.id, values.name, values.zipcode,
            values.street, values.number, values.complement, values.neighbourhood, values.city, values.state))
            .then(validateApiResponse)
            .then((response) => {

                let id = values.id;

                if (id == null)
                {
                    const addresses = Object.keys(response.payload.entities.addresses);

                    if (addresses.length > 0)
                    {
                        id = parseInt(addresses[addresses.length - 1]);
                    }
                }

                if (onSave != null && id != null) {
                    onSave(id);
                }
            });
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <AddressForm cart={cart} onSubmit={save} onCancel={onCancel} initialValues={address}/>
            </div>
        </div>
    );
}