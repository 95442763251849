import React, {forwardRef, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import UserSelector from "../../../selectors/UserSelector";
import CartSelector from "../../../selectors/CartSelector";
import {ClipLoader} from "react-spinners";
import {computeDiscountProducts} from "../../../models/Order";
import {Analytics} from "../../../analytics/Analytics";

const paypal = window.paypal;


export const PayPalButton = forwardRef(({onComplete, onError}, ref) => {
    const user = useSelector(UserSelector.currentUser);
    const cart = useSelector(CartSelector.currentDetailed);
    const shipping = useSelector(CartSelector.shipping);
    const coupon = useSelector(CartSelector.coupon);

    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);


    useEffect(() => {
        // const self = this;

        if (initialized) return;

        paypal.Buttons({
            style: {
                layout: 'horizontal',
                color:  'black',
                shape:  'rect',
                label:  'pay',
                tagline: false,
                height: 48
            },

            createOrder: function (data, actions) {
                if (user == null || cart == null || shipping == null) return;

                setLoading(true);

                const payload = createPayload(user, cart, shipping, coupon);
                return actions.order.create(payload);
            },

            onApprove: function (data, actions) {
                setLoading(true);

                return actions.order.capture()
                    .then(() => {
                        onComplete(data);

                        console.log(data);
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            },

            onCancel: () => {
                setLoading(false);
            },

            onError: function (err) {
                // Show an error page here, when an error occurs
                // console.log("PAYPAL ERROR");
                console.error(err);
                onError(err);
                setLoading(false);
            }
        }).render('#paypal-buttons');

        setInitialized(true);
    });


    return <>
        Para prosseguir com o pagamento via Paypal, clique no botão abaixo para ser redirecionado para a plataforma.
        {/*<div id="paypal-buttons">*/}
        {/*</div>*/}
        <div className="payment-option-loader">
            <ClipLoader
                color={"#aaa"}
                size={20}
                sizeUnits="px"
                loading={loading}
            />
        </div>
    </>;
});


function createPayload(user, cart, shipping, coupon) {
    const address = cart.address;

    let priceTotal = cart.price;
    const hasCoupon = coupon != null;

    let discount = null;

    if (hasCoupon) {
        let discountProducts = 0;

        discountProducts = computeDiscountProducts(priceTotal, coupon);
        priceTotal -= discountProducts;

        if (discountProducts > 0) {
            discount = {
                currency_code: 'BRL',
                value: discountProducts
            };
        }
    }

    if (hasCoupon && coupon.discountShipping) {
        shipping = {
            name: 'Grátis',
            price: 0
        }
    } else {
        priceTotal += shipping.price;
    }


    return {
        intent: 'CAPTURE',
        application_context: {
            brand_name: 'DERIVA',
            landing_page: 'BILLING',
            shipping_preference: 'SET_PROVIDED_ADDRESS',
            user_action: 'PAY_NOW'
        },
        purchase_units: [
            {
                soft_descriptor: 'DERIVA',

                amount: {
                    currency_code: 'BRL',
                    value: priceTotal,
                    breakdown: {
                        item_total: {
                            currency_code: 'BRL',
                            value: cart.price
                        },
                        shipping: {
                            currency_code: 'BRL',
                            value: shipping.price
                        },
                        discount
                    }
                },
                items: cart.cartItems.map(cartItem => {
                    return {
                        name: cartItem.productVariation.product.name,
                        description: cartItem.productVariation.size,
                        sku: cartItem.productVariation.sku,
                        unit_amount: {
                            currency_code: 'BRL',
                            value: cartItem.productVariation.product.price
                        },
                        quantity: cartItem.amount,
                        category: 'PHYSICAL_GOODS'
                    }
                }),
                shipping: {
                    method: shipping.name,
                    address: {
                        name: {
                            full_name: user.name,
                        },
                        address_line_1: address.street + ', ' + address.number,
                        address_line_2: address.complement,
                        admin_area_2: address.city,
                        admin_area_1: address.state,
                        postal_code: address.zipcode,
                        country_code: 'BR'
                    }
                }
            }
        ]
    }
}