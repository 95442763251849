import React from 'react';
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import {Url} from "../../util/Url";
import {History} from "../routes/History";


class AccountMenu extends React.Component {

    static propTypes = {
        currentUser: PropTypes.object,
        logout: PropTypes.func
    };


    renderWelcome() {
        return (
            <div className="lvl-1" style={{ marginRight: "14px" }}>
                <Link to={Url.Account.Login} className="header-tools-link">
                    <span className="text hidden-xs hidden-sm">
                        Entrar
                    </span>
                    &nbsp;
                    <i className="material-icons person_outline"/>
                </Link>
            </div>
        );
    }


    renderMenu() {
        return (
            <div className="dropdown lvl-1">
                <Link to="#" className="header-tools-link">
                    <span className="text hidden-xs hidden-sm">
                        Olá, {this.props.currentUser.name + ""}
                    </span>
                    &nbsp;
                    <i className="material-icons person"/>
                </Link>

                <ul className="sub-menu dropdown-menu" style={{right: 0, top: "calc(100% - 13px)", width:180}}>
                    <li className="nav-item lvl-2">
                        <Link to={Url.Account.Main}>Minha conta</Link>
                    </li>
                    <div className="nav-item-divider"/>

                    <li className="nav-item lvl-2">
                        <Link to="#" onClick={this.props.logout}>Sair</Link>
                    </li>
                </ul>
            </div>
        );
    }


    render() {
        return this.props.currentUser == null ? this.renderWelcome() : this.renderMenu();
    }
}


export default AccountMenu;