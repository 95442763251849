import React from "react";
import 'moment/locale/pt-br';
import {OrderItem} from "./OrderItem";
import {CartTotalValues} from "../../../cart/total/CartTotalValues";


export const PanelDetails = ({order}) => {

    return <div>
        <div className="row">
            <div className="col-xs-12">
                {
                    order.orderItems.map((x, i) => <div key={x.id} >
                        <div className="margin-top-1x">
                            <OrderItem orderItem={x}/>
                        </div>
                        <div className="margin-top-1x">
                            {i === order.orderItems.length - 1 ? null :
                                <hr/>
                            }
                        </div>
                    </div>)
                }
            </div>
        </div>

        <hr/>
        <div className="row">
            <div className="col-xs-12 black text-right" style={{color: "gray"}}>
                <CartTotalValues productsPrice={order.priceProducts} coupon={order.coupon} shipping={order.shipping}/>
            </div>
        </div>
    </div>
};