import {API_ROOT} from "./apiRoot";
import {ApiError, getJSON, RSAA} from "redux-api-middleware";
import {camelizeKeys} from "humps";
import {normalize} from "normalizr";
import DerivaCookies from "../../util/DerivaCookies";
import {SubmissionError} from "redux-form";


export const apiUrl = function (path) {
    return (path.indexOf(API_ROOT) === -1) ? API_ROOT + path : path;
};


export const createApiAction = function (actionsPrefix, method, path, data, resultType) {

    let typeRequest = actionsPrefix + "_REQUEST";
    let typeSuccess = actionsPrefix + "_SUCCESS";
    let typeFailure = actionsPrefix + "_FAILURE";

    if (resultType != null)
    {
        typeSuccess = createTypeAndParse(typeSuccess, resultType);
    }

    let action = {
        endpoint: apiUrl(path),
        method: method,
        headers: createApiHeaders(),
        types: [ typeRequest, typeSuccess, typeFailure ]
    };

    if (data != null)
    {
        action.body = JSON.stringify(data)
    }

    return {
        [RSAA]: action
    }
};


export const createApiHeaders = function()
{
    let headers = {
        'Content-Type': 'application/json'
    };

    let token = DerivaCookies.get("_token", { path: "/" });

    if (token != null && token !== "")
    {
        headers['Authorization'] = 'Bearer ' + token;
    }

    return headers;
};


export const createApiLoginAction = function (actionsPrefix, method, path, data, resultType)
{
    let typeSuccess = actionsPrefix + "_SUCCESS";
    let typeFailure = actionsPrefix + "_FAILURE";

    let action = createApiAction(actionsPrefix, method, path, data, resultType);

    action[RSAA].types[1] = {
        type: typeSuccess,
        payload: (action, state, res) => {

            try {
                let token = res.headers.get('Authorization');

                token = token.substring("Bearer ".length);

                DerivaCookies.set("_token", token, {path: "/"});
            }
            catch (e)
            {
                throw new Error("Can't get autorization token", e);
            }

            return getJSON(res).then((json) => {
                return normalize(camelizeKeys(json), resultType);
            });
        }
    };

    action[RSAA].types[2] = {
        type: typeFailure,
        payload: (action, state, res) => {
            return getJSON(res).then((json) => {
                return new ApiError(res.status, res.statusText, json)
            });
        }
    };

    return action;
};



export const createApiLogoutAction = function (actionsPrefix, method, path, data, resultType)
{
    let typeSuccess = actionsPrefix + "_SUCCESS";
    // let typeFailure = actionsPrefix + "_FAILURE";

    let action = createApiAction(actionsPrefix, method, path, data, resultType);

    action[RSAA].types[1] = {
        type: typeSuccess,
        payload: (action, state, res) => {

            DerivaCookies.remove("_token", { path: "/" });

            return getJSON(res).then((json) => {
                return json;
            });
        }
    };

    return action;
};



export function createTypeAndParse(type, schema) {
    return {
        type: type,
        payload: (action, state, res) => {
            if (res.redirected)
            {
                window.location.href = res.url;
            }

            return getJSON(res).then((json) => {
                return normalize(camelizeKeys(json), schema);
            });
        }
    };
}




export const validateApiResponse = (res) => {
    if (res.hasOwnProperty("error") && res.error === true)
    {
        throw new SubmissionError(res.payload.response);
    }

    return res;
};