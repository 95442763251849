import React, {useState} from 'react';

import {useDispatch, useSelector} from "react-redux";

import AddressAction from "../../actions/AddressAction";
import {AddressList} from "./AddressList";

import UserSelector from "../../selectors/UserSelector";
import {AddressEditor} from "./AddressEditor";


export const AddressManager = ({selected, onSelect, onEdit}) => {
    const user = useSelector(UserSelector.currentUser);
    const [currentAddress, setCurrentAddress] = useState(null);
    const [flash, setFlash] = useState(null);
    const dispatch = useDispatch();

    const create = () => {
        _setCurrentAddress("new");
    };

    const edit = (id) => {
        _setCurrentAddress(id);
    };

    const cancelEdit = () => {
        _setCurrentAddress(null);
    }

    const _setCurrentAddress = (id) => {
        setCurrentAddress(id);
        setFlash(null);

        if (onEdit != null) {
            onEdit(id);
        }
    };

    const remove = (id) => {
        dispatch(AddressAction.delete(id)).then(() => {
            setFlash(createFlashPanel("Endereço apagado com sucesso!"));
        })
    }

    const onSave = (address) => {
        console.log("onSave ", address);
        if (onSelect != null) {
            onSelect(address);
        }

        setCurrentAddress(null);
        setFlash(createFlashPanel("Endereço salvo com sucesso!"));
    }


    const createFlashPanel = (text) => {
        return (
            <div className="panel no-pointer-events margin-bottom-2x">
                <div className="panel-heading">
                    <div className="panel-title"><i className="material-icons check_circle"/>&nbsp;{text}
                    </div>
                </div>
            </div>
        );
    }

    const address = currentAddress == null || currentAddress === "new" || user == null ? null :
        user.addresses.find(x => x.id === currentAddress);

    return (
        <div>
            {flash}
            {
                currentAddress == null ?
                    <AddressList user={user} onNew={create} onEdit={edit} onDelete={remove}
                                 onSelect={onSelect} selected={selected}/>
                    :
                    <AddressEditor address={address} onSave={onSave} onCancel={cancelEdit}/>
            }
        </div>
    );
}
