import {fk, attr, Model} from 'redux-orm';
import {schema} from "normalizr";

import {updateEntities} from "../reducers/util";
import {belongsTo} from "./util";
import CollectionActionType from "../actions/CollectionActionType";
import ProductActionType from "../actions/ProductActionType";
import ProductVariation from "./ProductVariation";


export class Product extends Model
{
    static Schema = new schema.Entity('products',
        {
            productVariations: new schema.Array(ProductVariation.Schema)
        },
        belongsTo('pattern')
    );


    static reducer(action, Product, session)
    {
        const {payload, type} = action;

        switch (type) {
            case CollectionActionType.ALL_SUCCESS:
            case ProductActionType.LOAD_SUCCESS:
                updateEntities(payload, 'products', Product);
                break;

            default:
                break;
        }
    }


    toString() {
        return `Product: ${this.id} - ${this.name}`;
    }


    formatPattern()
    {
        if (this.pattern == null)
        {
            return null;
        }

        let collection = this.pattern.collection == null ? null : this.pattern.collection.name;
        let pattern = this.pattern.name;

        return `${collection} / ${pattern}`;
    }
}


Product.modelName = 'Product';


Product.fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    measurement: attr(),
    price: attr(),
    picturesShop: attr(),
    pattern: fk('Pattern', 'products')
};


export default Product;