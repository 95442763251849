import {attr, fk, Model} from 'redux-orm';
import {schema} from "normalizr";

import {updateEntities} from "../reducers/util";
import CartActionType from "../actions/CartActionType";
import SessionActionType from "../actions/SessionActionType";
import OrderActionType from "../actions/OrderActionType";
import PaypalPayment from "./PaypalPayment";


export class Shipping extends Model
{
    static Schema = new schema.Entity('shipping',
        {
        }
    );


    static reducer(action, Shipping, session)
    {
        const {payload, type} = action;

        switch (type) {
            case CartActionType.SHIPPING_SUCCESS:
            case OrderActionType.CREATE_SUCCESS:
            case OrderActionType.AUTHORIZE_SUCCESS:
                updateEntities(payload, 'shipping', Shipping);
                break;

            case SessionActionType.LOGIN_SUCCESS:
                Shipping.all().delete();
                updateEntities(payload, 'shipping', Shipping);
                break;

            case SessionActionType.LOGOUT_SUCCESS:
                Shipping.all().delete();
                break;

            default:
                break;
        }
    }


    toString() {
        return `Shipping: ${this.id} - ${this.name}`;
    }
}


Shipping.modelName = 'Shipping';


Shipping.fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    price: attr(),
    time: attr(),
    method: attr(),
};


export default Shipping;