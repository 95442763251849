import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {withRouter} from "react-router-dom";
import ProductShowcase from "./ProductShowcase";
import CartAction from "../../actions/CartAction";
import ProductSelector from "../../selectors/ProductSelector";
import ProductAction from "../../actions/ProductAction";
import {validateApiResponse} from "../../actions/api/util";
import CartSelector from "../../selectors/CartSelector";
import {Analytics} from "../../analytics/Analytics";


class ProductShowcaseContainer extends React.Component {

    static propTypes = {
        product: PropTypes.object,
        cart: PropTypes.object,
        onBuy: PropTypes.func,
        error: PropTypes.object
    };


    constructor() {
        super();
        this.link = null;
        this.tracked = false;
    }


    componentWillMount() {
        this.props.loadData();
    }


    componentDidMount() {
        if (!this.tracked && this.props.product != null) {
            this.tracked = true;
            Analytics.ViewProduct(this.props.product);
        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.render();
        }
    }


    render() {
        let initialValues = {amount: 1};

        if (this.props.product == null) {
            return null;
        }

        if (this.props.product.productVariations.length === 1) {
            initialValues.product_variation_id = this.props.product.productVariations[0].id.toString();
        }

        return <ProductShowcase onSubmit={this.props.onBuy} asyncError={this.props.error}
                                initialValues={initialValues}
                                product={this.props.product}
                                cart={this.props.cart}
        />;
    }
}


const mapStateToProps = (state, props) => {
    return {
        product: ProductSelector.product(state, props.match.params.link),
        cart: CartSelector.current(state),
    };
};


const mapPropsToDispatch = (dispatch, props) => {
    return {
        loadData: () => {
            return dispatch(ProductAction.getProduct(props.match.params.link));
        },
        onBuy: (values) => {
            return dispatch(CartAction.addItem(values.product_variation_id, values.amount))
                .then(validateApiResponse)
                .then(() => {
                    window.$("#mainContainer").scrollTop(0);
                })
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(withRouter(ProductShowcaseContainer));

