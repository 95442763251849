import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import CartAction from "../../actions/CartAction";
import CartSelector from "../../selectors/CartSelector";
import UserSelector from "../../selectors/UserSelector";
import SideMenu from "./SideMenu";
import SessionAction from "../../actions/SessionAction";
import {History} from "../routes/History";
import {Url} from "../../util/Url";
import CollectionSelector from "../../selectors/CollectionSelector";


class SideMenuContainer extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };


    componentDidMount()
    {
        this.props.loadData();
    }


    render() {
        return <SideMenu user={this.props.user} collections={this.props.collections} logout={this.props.logout}/>
    }
}


const mapStateToProps = (state) => {
    return {
        user: UserSelector.currentUser(state),
        collections: CollectionSelector.all(state),
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
        loadData: () => {
            return dispatch(CartAction.getCurrent())
        },
        logout: () => {
            dispatch(SessionAction.logout()).then(() => {
                History.push(Url.Home);
            })
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(SideMenuContainer);

