import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

// import { createLogger } from 'redux-logger'
import { apiMiddleware } from 'redux-api-middleware';

import reducer from '../reducers'

import {composeWithDevTools} from "redux-devtools-extension";

const configureStore = preloadedState => {
    const store = createStore(
        reducer,
        preloadedState,
        composeWithDevTools(
            // applyMiddleware(thunk, apiMiddleware, createLogger()),
            applyMiddleware(thunk, apiMiddleware)
        )
    );

    return store;
};

export default configureStore;