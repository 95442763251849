import React, {useState} from 'react'
import {AddressManager} from "../../address/AddressManager";
import ValidationError from "../../util/ValidationError";
import {useDispatch, useSelector} from "react-redux";
import CartSelector from "../../../selectors/CartSelector";
import ApplicationSelector from "../../../selectors/ApplicationSelector";
import CartAction from "../../../actions/CartAction";
import {validateApiResponse} from "../../../actions/api/util";
import {History} from "../../routes/History";
import {Url} from "../../../util/Url";
import {CartProgress} from "../CartProgress";
import {ClipLoader} from "react-spinners";
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";


export const CartAddress = () => {
    const cart = useSelector(CartSelector.current);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const dispatch = useDispatch();

    const back = () => {
        History.goBack();
    }

    const select = (id) => {
        setLoading(true);

        dispatch(CartAction.setAddress(id))
            .then(validateApiResponse)
            .then(() => History.push(Url.Shop.CartShipping));
    }

    const onEdit = (id) => {
        setEditing(id != null);
    }

    return (
        <main>
            <Header title="Deriva / Carrinho / Endereços"
                    description="Escolha ou adicione um novo endereço para entrega"
            />
            <div className="container margin-top-2x margin-bottom-2x">
                <div className="row">
                    <div className="col-xs-12">
                        <CartProgress current={1}/>
                    </div>
                    <div className="col-xs-12 col-md-10 col-md-offset-1">
                        <h2>
                            Endereço de entrega
                        </h2>
                        <div className="margin-bottom-1x">
                            Escolha ou adicione um novo endereço para entrega.
                        </div>

                        <AddressManager onSelect={select} selected={cart.address} onEdit={onEdit}/>

                        {!editing &&
                            <div className="cart-bottom-tools">
                                <button onClick={back} className="btn btn-gray">
                                    Voltar
                                </button>
                            </div>
                        }
                    </div>
                </div>
            {loading &&
                <div className="loading-backdrop">
                    <div className="col-xs-12 text-center margin-top-1x">
                        <ClipLoader
                            color="rgba(0, 0, 0, 0.75)"
                            size={36}
                            sizeUnits="px"
                            loading={loading}
                        />
                    </div>
                </div>
            }
            </div>
        </main>
    );
}