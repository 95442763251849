import React from 'react';
import PropTypes from 'prop-types'

import {connect} from "react-redux";

import UserSelector from "../../selectors/UserSelector";
import SessionAction from "../../actions/SessionAction";
import Session from "./Session";


class SessionContainer extends React.Component {

    static propTypes = {
        getCurrentUser: PropTypes.func,
        currentUser: PropTypes.object
    };


    static defaultProps = {
        currentUser: null
    };


    componentDidMount()
    {
        this.props.getCurrentUser();
    }


    render() {
        return <Session currentUser={ this.props.currentUser } />;
    }
}



const mapStateToProps = (state) => {
    return {
        currentUser: UserSelector.currentUser(state)
    }
};


const mapPropsToDispatch = (dispatch) => {
    return {
        getCurrentUser: () => dispatch(SessionAction.getCurrentUser())
    }
};


export default connect(mapStateToProps, mapPropsToDispatch)(SessionContainer);