import React from 'react'
import PropTypes from 'prop-types';


class WashingInstructions extends React.Component {

    static propTypes = {
        icon: PropTypes.string,
        description: PropTypes.string,
    };


    constructor() {
        super();

        this.data = [
            {
                icon: "icon-wh-washing-hand",
                description: "Lavar somente a mão",
            },
            {
                icon: "icon-wh-bleaching-not-allowed",
                description: "Não utilizar alvejante",
            },
            {
                icon: "icon-wh-drying-tumble-not-allowed",
                description: "Não utilizar máquina de secar",
            },
            {
                icon: "icon-wh-drying-drip-dry",
                description: "Secar no sentido vertical sem torcer",
            },
            {
                icon: "icon-wh-iron-low",
                description: "Passar a ferro a temperatura máx. 110°C",
            },
            {
                icon: "icon-wh-drycleaning-not-allowed",
                description: "Não lavar à seco",
            }
        ];


        this.state = {over: null, selected: null};
        this.locked = false;

        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onClick = this.onClick.bind(this);
    }


    onMouseOver(target) {
        this.setState({over: target});
    }


    onMouseOut(target) {
        this.setState({over: null});
    }


    onClick(target) {
        if (this.state.selected === target) {
            this.setState({selected: null, over: null})
        }
        else {
            this.setState({selected: target});
        }
    }


    render() {
        const {over, selected} = this.state;
        const target = selected == null ? over : selected;

        const showTooltip = target != null;

        const display = showTooltip ? "" : "none";
        const description = !showTooltip ? null : this.data.find(x => x.icon === target).description;

        return (
            <div className="washing-instructions">
                <div className="icons">
                    {
                        this.data.map(x => (
                            <i key={x.icon} className={x.icon + (x.icon !== target ? "" : " active")}
                               onClick={() => this.onClick(x.icon)}
                               onMouseOver={() => this.onMouseOver(x.icon)}
                               onMouseOut={() => this.onMouseOut(x.icon)}
                            />))
                    }
                </div>
                <div className="tooltip" style={{display: display}}>
                    {description}
                </div>
            </div>
        );
    }
}

export default WashingInstructions;