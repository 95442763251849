import {attr, fk, Model} from 'redux-orm';
import {schema} from "normalizr";

import {updateEntities} from "../reducers/util";
import SessionActionType from "../actions/SessionActionType";

import Cart from './Cart'
import Address from './Address'
import AddressActionType from "../actions/AddressActionType";
import moment from "moment";
import Order from "./Order";
import RegistrationActionType from "../actions/RegistrationActionType";


export class User extends Model
{
    static Schema = new schema.Entity('users',
        {
            cart: Cart.Schema,
            addresses: new schema.Array(Address.Schema),
            orders: new schema.Array(Order.Schema),
            favoriteAddress: Address.Schema
        }
    );


    static reducer(action, User, session)
    {
        const {payload, type} = action;

        switch (type) {
            case SessionActionType.LOGIN_SUCCESS:
                User.all().delete();
                updateEntities(payload, 'users', User);
                break;

            case SessionActionType.LOGOUT_SUCCESS:
                User.all().delete();
                break;

            case AddressActionType.UPSERT_SUCCESS:
                updateEntities(payload, 'users', User);
                // User.withId(User.first().id).update({ updatedAt: moment() });
                break;

            case AddressActionType.SET_FAVORITE_SUCCESS:
            case RegistrationActionType.UPDATE_SUCCESS:
            case AddressActionType.DELETE_SUCCESS:
                updateEntities(payload, 'users', User);
                break;

            default:
                break;
        }
    }


    toString() {
        return `User: ${this.id} - ${this.email}`;
    }
}


User.modelName = 'User';


User.fields = {
    id: attr(),
    email: attr(),
    name: attr(),
    favoriteAddress: fk('Address', 'favorite_address'),
    cart: fk('Cart', 'cart'),
};


export default User;