import React, {useState} from 'react'

import {Link, NavLink} from "react-router-dom";
import AccountMenuContainer from "./AccountMenuContainer";
import {Url} from "../../util/Url";
import CartMenuContainer from "./CartMenuContainer";
import {useSelector} from "react-redux";
import CollectionSelector from "../../selectors/CollectionSelector";
import moment from "moment";


export const Header = () => {
    const collections = useSelector(CollectionSelector.all);

    collections.sort((a, b) => moment(b.launchedAt).diff(moment(a.launchedAt)));


    return (
        <header className="header">

            <div className="navbar">
                <div className="container">
                    <div className="navbar-inner">
                        <div className="column"/>

                        <div className="column text-center">
                            <Link to={Url.Home} className="logo">
                                <img src="/img/logo.png" alt=""/>
                            </Link>
                        </div>

                        <div className="column">
                            <div className="header-tools text-right">
                                <AccountMenuContainer/>
                                <CartMenuContainer/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mega-menu-container">
                <div className="container">
                    <div className="mega-menu-inner">
                        <div className="lvl-1">
                            <NavLink exact to={Url.Home} activeClassName="current">Home</NavLink>
                        </div>

                        <div className="lvl-1">

                            <li className="nav-item dropdown lvl-1">
                                <NavLink to="#">Coleções</NavLink>

                                <ul className="sub-menu">
                                    {collections.filter(x => x.link != null && x.link !== "").map(collection => (
                                        <li className="nav-item lvl-2" key={collection.id}>
                                            <NavLink exact to={Url.Collection(collection.link)} activeClassName="current">{collection.name}</NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>

                            {/*<NavLink exact to={Url.Collection.Voa} activeClassName="current">Coleção</NavLink>*/}
                        </div>

                        <div className="lvl-1">
                            <NavLink to={Url.Shop.Main} activeClassName="current">Loja</NavLink>
                        </div>

                        <div className="lvl-1">
                            <NavLink exact to={Url.About} activeClassName="current">Sobre Nós</NavLink>
                        </div>

                        <div className="lvl-1">
                            <NavLink exact to={Url.Contact} activeClassName="current">Contato</NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-view">
                <div className="container">
                    <Link to="#" className="offcanvas-toggle">
                        <i className="material-icons menu"/>
                    </Link>

                    <Link to={Url.Home} className="logo">
                        <img src="/img/logo.png" alt=""/>
                    </Link>

                    <div className="mobile-tools">
                        <CartMenuContainer/>
                    </div>
                </div>
            </div>
        </header>
    );
};
