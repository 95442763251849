import React from 'react'
import VideoFullscreen from "./VideoFullscreen";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";


export const CollectionVoa = () => (
    <main>
        <Header title="Deriva / Coleções / Voa"
                description="Nossa primeira coleção é inspirada nessa potência da natureza. Misturas vibrantes de cores, formas orgânicas e texturas encontradas nas mariposas e borboletas."
                image="https://www.somosderiva.com/img/collection/voa/pic-3.jpg"
                keywords="deriva estampa voa natureza"
        />
        <Helmet>
            <title>Deriva / Coleções / Voa</title>
        </Helmet>
        <VideoFullscreen/>
        <div className="container margin-top-2x">
            <div className="row margin-bottom-1x">
                <div className="col-xs-12">
                    <h2>Voa</h2>
                </div>
            </div>
            <div className="row margin-bottom-1x">
                <div className="col-md-12">
                    <p>
                        Crescemos ouvindo que o tempo voa. Que o tempo cura tudo. Desconfiamos ser mesmo verdade.
                        Como já disse Caetano Veloso: “Tempo, Tempo, Tempo, Tempo... És um dos deuses mais lindos...”
                        Poderoso que é, como ele não seria capaz de transformar? Cabe aqui otimismo em ver que, com o
                        passar do tempo, você ganhou maturidade, ainda mais força e discernimento. Um novo olhar.
                        Ciclos de evolução nos dão o poder da mudança e o tempo é nosso aliado.
                    </p>
                </div>
            </div>
        </div>
        <div className="container-fluid margin-bottom-1x margin-top-2x padding-none">
            <div className="">
                <div className="col-md-4 col-xs-4 margin-bottom-1x col-fluid-left">
                    <img src="/img/collection/voa/mood-board-1.jpg" alt=""/>
                </div>
                <div className="col-md-4 col-xs-4 margin-bottom-1x col-fluid-middle">
                    <img src="/img/collection/voa/mood-board-2.jpg" alt=""/>
                </div>
                <div className="col-md-4 col-xs-4 margin-bottom-1x col-fluid-right">
                    <img src="/img/collection/voa/mood-board-3.jpg" alt=""/>
                </div>
            </div>
        </div>
        <div className="container margin-top-2x">
            <div className="row margin-bottom-2x">
                <div className="col-md-12">
                    <p>
                        Por quantas transformações passamos durante nossas vidas?
                        Difícil responder...
                        Estamos constantemente passando por ciclos de evolução.
                        E ainda bem né!?
                        Nos renovamos continuamente renascendo todos os dias.
                        Não existe fim nem começo.
                    </p>
                    <p>
                        Observe como acontece com as lagartas e as borboletas:
                        Elas parecem seres completamente distintos em cada uma das fases de sua metamorfose,
                        mas na verdade se tratam de apenas um único ser. Com a gente é do mesmo jeito.
                        As mudanças são inevitáveis, mas nossa verdade e essência permanecem.
                        Somos sempre outro, porém, também é certo dizer que somos sempre o mesmo.
                    </p>

                    <p>
                        Nos alimentando e nos nutrindo como uma lagarta que inicia, ela própria,
                        seu processo de transformação ao se enclausurar em um casulo.
                        Ou livres e exuberantes como as borboletas - cada um de nós está em diferentes fases dessa
                        jornada.
                        E tá tudo certo, cada coisa tem seu tempo.
                    </p>
                </div>
            </div>
        </div>

        <div className="container-fluid margin-bottom-1x margin-top-1x padding-none">
            <div className="">
                <div className="col-xs-6 col-fluid">
                    <img src="/img/collection/voa/making-of-1.jpg" alt=""/>
                </div>
                <div className="col-xs-6 col-fluid">
                    <img src="/img/collection/voa/making-of-2.jpg" alt=""/>
                </div>
            </div>
        </div>

        {/*<div className="container-fluid margin-bottom-2x padding-none">*/}
            {/*<div className="">*/}
                {/*<div className="col-md-12 padding-none">*/}
                    {/*<div className="video">*/}
                        {/*<div className="video-container">*/}
                            {/*<iframe title="video" src="//www.youtube.com/embed/ScMzIvxBSi4"*/}
                                    {/*allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                                    {/*frameBorder="0" allowFullScreen className="video-frame"/>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            {/*</div>*/}
        {/*</div>*/}

        <div className="container margin-top-2x">
            <div className="row margin-bottom-2x">
                <div className="col-md-12">
                    <p>
                        Como a natureza é sábia...
                    </p>
                    <p>
                        Para a DERIVA é tempo de abandonar o casulo e voar alto e livre!
                    </p>
                    <p>
                        Nossa primeira coleção é inspirada nessa potência da natureza. Misturas vibrantes de cores,
                        formas orgânicas e texturas encontradas nas mariposas e borboletas. As combinações parecem ser
                        infinitas, cada uma mais exuberante do que a outra. Escolhemos esse tema não somente pelo apelo
                        visual que arrebata os olhos, mas também por ser capaz de expressar tão claramente o nosso
                        momento de se lançar ao mundo.
                    </p>
                    <p>
                        As imagens das borboletas, em todas as suas fases, tornaram-se referência e fio condutor
                        criativo. Foi o ponto de partida para o processo de abstração artística, onde foi possível criar
                        uma linguagem própria. Em nenhum momento tivemos a intenção de fazer cópia ou decalque da arte
                        final da natureza. Quem é curioso, observador e atento, pode ler essa mensagem em nossas
                        estampas Asas, Insecta e Poá.
                    </p>

                </div>
            </div>
        </div>
        <div className="container-fluid padding-none">
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/voa/slider/1-md.jpg" alt="" className="brgr"/>
                </div>
            </div>
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/voa/slider/2-lg.jpg" alt="" className="brgr"/>
                </div>
            </div>
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/voa/slider/3-md.jpg" alt="" className="brgr"/>
                </div>
            </div>
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/voa/pic-1.jpg" alt="" className="brgr"/>
                </div>
            </div>
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/voa/pic-2.jpg" alt="" className="brgr"/>
                </div>
            </div>
            <div className="margin-bottom-none">
                <div className="col-xs-12 padding-none">
                    <img src="/img/collection/voa/pic-3.jpg" alt="" className="brgr"/>
                </div>
            </div>
        </div>
    </main>
);