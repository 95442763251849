import React from 'react';
import PropTypes from 'prop-types'
import {Link, NavLink} from "react-router-dom";

import {Url} from "../../util/Url";
import moment from "moment";

// import $ from 'jquery';

const $ = window.$;
const slidebars = window.slidebars;


class SideMenu extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        logout: PropTypes.func,
    };


    constructor() {
        super();

        this.controller = null;

    }


    componentDidMount() {
        this.controller = new slidebars();
        let controller = this.controller;

        // Initialize Slidebars
        // var menuInitHeight
        controller.init();


        $('.offcanvas-toggle').on('click', function (event) {

            // Set initial menu height value
            menuInitHeight = $('.offcanvas-navigation .menu').height();

            // Stop default action and bubbling
            event.stopPropagation();
            event.preventDefault();

            // Toggle the Slidebar with id 'id-2'
            controller.toggle('id-1');
        });


        $('.offcanvas-toggle-link').on('click', function (event) {

            // Set initial menu height value
            menuInitHeight = $('.offcanvas-navigation .menu').height();

            // Toggle the Slidebar with id 'id-2'
            controller.toggle('id-1');
        });

        // Offcanvas Navigation
        // --------------------

        // Back Button
        // -----------
        let menuInitHeight,
            backBtnText = $('.offcanvas-navigation').data('back-btn-text'),
            subMenu = $('.offcanvas-navigation .sub-menu');


        $('.offcanvas-toggle').on('click', function () {
            menuInitHeight = $('.offcanvas-navigation .menu').height()
        });

        subMenu.each(function () {
            $(this).prepend('<li class="back-btn"><a href="#">' + backBtnText + '</a></li>');
        });

        let hasChildLink = $('.menu-item-has-children > a'),
            backBtn = $('.offcanvas-navigation .sub-menu .back-btn');

        backBtn.on('click', function (e) {
            let self = this,
                parent = $(self).parent(),
                siblingParent = $(self).parent().parent().siblings().parent(),
                menu = $(self).parents('.menu');

            parent.removeClass('in-view');
            siblingParent.removeClass('off-view');
            if (siblingParent.attr("class") === "menu") {

                menu.velocity({height: menuInitHeight}, 100);
            } else {
                menu.velocity({height: siblingParent.height()}, 100);
            }

            e.stopPropagation();
        });

        hasChildLink.on('click', function (e) {
            let self = this,
                parent = $(self).parent().parent(),
                menu = $(self).parents('.menu');

            parent.addClass('off-view');
            $(self).parent().find('> .sub-menu').addClass('in-view');
            menu.velocity({height: $(self).parent().find('> .sub-menu').height()}, 100);

            e.preventDefault();
            return false;
        });
    }


    render() {

        const {user, collections} = this.props;

        if (collections != null) {
            collections.sort((a, b) => moment(b.launchedAt).diff(moment(a.launchedAt)));
        }

        const hasUser = user != null;

        return (
            <div off-canvas="id-1 left push" className="off-canvas-cont">

                <nav className="offcanvas-navigation" role="navigation" data-back-btn-text="Voltar">
                    <div className="menu-site-menu-container">
                        <ul className="menu">
                            <li className="menu-item">
                                <NavLink exact to={Url.Home} className="offcanvas-toggle-link"
                                         activeClassName="current">Home</NavLink>
                            </li>

                            <li className="menu-item menu-item-has-children">
                                <a href="#">Cole&ccedil;&atilde;o</a>

                                <ul className="sub-menu">
                                    {collections.filter(x => x.link != null && x.link !== "").map(collection => (
                                        <li key={collection.id}>
                                            <NavLink exact to={Url.Collection(collection.link)} activeClassName="current"
                                                className="offcanvas-toggle-link">{collection.name}</NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>

                            <li>
                                <NavLink to={Url.Shop.Main} className="offcanvas-toggle-link"
                                         activeClassName="current">Loja</NavLink>
                            </li>

                            <li>
                                <NavLink exact to={Url.About} className="offcanvas-toggle-link"
                                         activeClassName="current">Sobre nós</NavLink>
                            </li>

                            <li>
                                <NavLink exact to={Url.Contact} className="offcanvas-toggle-link"
                                         activeClassName="current">Contato</NavLink>
                            </li>
                            <li className="divider"/>
                            <li>
                                <NavLink exact to={Url.Account.Login}
                                         className={`offcanvas-toggle-link ${hasUser ? "hidden" : ""}`}
                                         activeClassName="current">
                                    Entrar
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to={Url.Account.Main}
                                         className={`offcanvas-toggle-link ${!hasUser ? "hidden" : ""}`}
                                         activeClassName="current">
                                    Minha conta
                                </NavLink>
                            </li>
                            <li>
                                <Link to="#" className={`offcanvas-toggle-link ${!hasUser ? "hidden" : ""}`}
                                      onClick={this.props.logout}>Sair</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}


export default SideMenu;