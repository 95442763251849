import {attr, Model} from 'redux-orm';
import {schema} from 'normalizr';

import {updateEntities} from "../reducers/util";
import Pattern from "./Pattern";
import CollectionActionType from "../actions/CollectionActionType";
import ProductActionType from "../actions/ProductActionType";



export class Collection extends Model
{
    static Schema = new schema.Entity('collections',
        {
            patterns: new schema.Array(Pattern.Schema)
        }
    );


    static reducer(action, Collection, session)
    {
        const {payload, type} = action;

        switch (type) {
            case CollectionActionType.ALL_SUCCESS:
            case ProductActionType.LOAD_SUCCESS:
                updateEntities(payload, 'collections', Collection);
                break;

            default:
                break;
        }
    }


    toString()
    {
        return `Collection: ${this.id} - ${this.name}`;
    }


    getClassName()
    {
        return this.name === "Voa" ? "brgr" : "";
    }
}


Collection.modelName = 'Collection';


Collection.fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    launched_at: attr(),
    featured: attr()
};


export default Collection;