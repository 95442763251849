const RegistrationActionType = {
    REGISTER_PREFIX: "REGISTER",
    REGISTER_REQUEST: "REGISTER_REQUEST",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_FAILURE: "REGISTER_FAILURE",
    
    UPDATE_PREFIX: "UPDATE",
    UPDATE_REQUEST: "UPDATE_REQUEST",
    UPDATE_SUCCESS: "UPDATE_SUCCESS",
    UPDATE_FAILURE: "UPDATE_FAILURE"
};

export default RegistrationActionType;