import React from 'react';
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";
import {Url} from "../../util/Url";
import moment from "moment";
import CartTooltipUpdated from "./CartTooltipUpdated";
import CartTooltipProduct from "./CartTooltipProduct";


class CartTooltip extends React.Component {

    static propTypes = {
        cartItemAdded: PropTypes.object,
        cartItemRemoved: PropTypes.object,
        updatedAt: PropTypes.object,
    };


    constructor(props) {
        super(props);

        this.state = {
            active: false
        };

        this.interval = null;
        this.lastLocation = null;

        this.close = this.close.bind(this);
        this.update = this.update.bind(this);
    }


    componentDidUpdate(prevProps) {
        // If the page changes, close the tooltip.
        if (this.props.location.pathname !== this.lastLocation)
        {
            this.lastLocation = this.props.location.pathname;

            this.close();

            return;
        }

        if (prevProps.updatedAt !== this.props.updatedAt)
        {
            this.start();
        }
    }


    start()
    {
        this.clearInterval();

        const active = this.updateState();

        if (active)
        {
            this.interval = setInterval(this.update, 1000);
        }
    }


    update()
    {
        const active = this.updateState();

        if (!active)
        {
            this.clearInterval();
        }
    }


    updateState()
    {
        const updatedAt = this.props.updatedAt;

        const now = moment().subtract(5, 'seconds');
        const active = updatedAt != null && updatedAt.isSameOrAfter(now);

        if (this.state.active !== active)
        {
            this.setState({ active: active });
        }

        return active;
    }


    clearInterval() {
        if (this.interval != null) {
            clearInterval(this.interval);
        }
    }


    close() {
        if (!this.state.active)
        {
            return;
        }

        this.clearInterval();

        this.setState({ active: false });
    }


    isLocationCart() {
        const pathname = this.props.location.pathname;

        return pathname === Url.Shop.Cart ||
            pathname === Url.Shop.CartAddress ||
            pathname === Url.Shop.CartPayment;
    }


    render() {
        let content = null;

        if (this.isLocationCart()) {
            content = <CartTooltipUpdated onClose={this.close}/>
        }
        else {
            const cartItem = this.props.cartItemAdded;
            const product = cartItem == null ? null : cartItem.productVariation.product;

            content = <CartTooltipProduct onClose={this.close} product={product}/>
        }

        let active = this.state.active ? "cart-tooltip-active" : "";

        return (
            <div className={`cart-tooltip ${active}`}>
                {content}
            </div>
        );
    }
}

export default withRouter(CartTooltip);
