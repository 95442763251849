import React from 'react'
import "react-step-progress-bar/styles.css";
import moment from "moment";
import OrderStatus from "../../../../models/OrderStatus";
import {StepsBar} from "../../../util/StepsBar";


export const OrderProgress = ({order}) => {

    const createdAt = moment(order.createdAt);
    const paymentAt = order.payment == null ? null : order.payment.approvedAt;
    const sentAt = order.shipping == null ? null : order.shipping.sentAt;

    let deliveryAt = order.shipping == null ? null : order.shipping.deliveredAt;

    if (deliveryAt == null) {
        if (order.shipping.time != null) {
            deliveryAt = moment(createdAt);
            deliveryAt = deliveryAt.add(order.shipping.time, 'days');
            deliveryAt = deliveryAt.add(2, 'days'); // tempo de preparo

            if (paymentAt == null) {
                deliveryAt = deliveryAt.add(2, 'days'); // tempo de pagamento
            }
        }
    }

    const steps = [
        {
            icon: "receipt",
            date: order.createdAt,
            titleComplete: "Pedido Criado",
        },
        {
            icon: "payment",
            date: paymentAt,
            titleStart: "Aguardando pagamento",
            titleComplete: "Pagamento aprovado",
        },
        {
            icon: "local_shipping",
            date: sentAt,
            titleStart: "Aguardando envio",
            titleComplete: "Pedido enviado",
        },
        {
            icon: "check_circle",
            date: deliveryAt == null ? "(calculando)" : deliveryAt,
            titleStart: "Entrega estimada",
            titleComplete: "Entrega realizada",
        }
    ];


    let currentStep = 0;

    switch (order.status) {
        case OrderStatus.delivered:
            currentStep = 3;
            break;

        case OrderStatus.sent:
            currentStep = 2;
            break;

        case OrderStatus.payed:
            currentStep = 1;
            break;
    }


    return <StepsBar steps={steps} current={currentStep}/>;
};
