import React from 'react';
import Media from 'react-media';
import {CaminhosMediaCarousel} from "./CaminhosMediaCarousel";


export const CaminhosCarousel = () => {

    return <>
        <Media query="(max-width: 768px)" render={() => <CaminhosMediaCarousel media="xs"/>}/>
        <Media query="(min-width: 768px) and (max-width: 991px)" render={() => <CaminhosMediaCarousel media="sm"/>}/>
        <Media query="(min-width: 992px) and (max-width: 1199px)" render={() => <CaminhosMediaCarousel media="md"/>}/>
        <Media query="(min-width: 1200px)" render={() => <CaminhosMediaCarousel media="lg"/>}/>
    </>;
};
