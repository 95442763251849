import React from 'react'
import PropTypes from 'prop-types'


class InstagramPost extends React.Component {

    static propTypes = {
        post: PropTypes.object
    };


    render() {
        const post = this.props.post;
        const imageUrl = post.media_type === "VIDEO" ? post.thumbnail_url : post.media_url;

        const url = post.permalink;

        const open = function(url)
        {
            window.open(url,"_blank");
        }

        return (
            <div className="col-md-2 col-sm-4 col-xs-6">
                <div className="tile tile-insta">
                    <div className="tile-insta-container" style={{backgroundImage: "url(" + imageUrl +")"}} onClick={() => open(url)}>
                    </div>
                    {/*<a href={url} className="preview-box" target="_blank" rel="noopener noreferrer">*/}
                    {/*    <img src={imageUrl} alt=""/>*/}

                    {/*    /!*<span className="insta-meta" style={{ marginLeft: "-25px" }}>*!/*/}
                    {/*    /!*            <i className="material-icons favorite"/>&nbsp;*!/*/}
                    {/*    /!*    {this.props.post.likes}*!/*/}
                    {/*    /!*        </span>*!/*/}
                    {/*    /!*<span className="insta-meta" style={{ marginLeft: "25px" }}>*!/*/}
                    {/*    /!*            <i className="material-icons mode_comment"/>&nbsp;*!/*/}
                    {/*    /!*    {this.props.post.comments}*!/*/}
                    {/*    /!*        </span>*!/*/}
                    {/*</a>*/}
                </div>
            </div>
        );
    }
}

export default InstagramPost;