import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {History} from "../../routes/History";

import {validateApiResponse} from "../../../actions/api/util";
import {Url} from "../../../util/Url";
import PasswordAction from "../../../actions/PasswordAction";
import ForgotPassword from "./ForgotPassword";


class ForgotPasswordContainer extends React.Component {

    static propTypes = {
        onSubmit: PropTypes.func,
    };


    render() {
        return <ForgotPassword onSubmit={this.props.onSubmit} />;
    }
}


const mapStateToProps = () => {
    return {
    };
};


const mapPropsToDispatch = (dispatch) => {
    return {
        onSubmit: (values) => {
            return dispatch(PasswordAction.forgot(values.email))
                .then(validateApiResponse)
                .then(() => {
                    History.push(Url.Account.PasswordSent);
                })
        }
    };
};

export default connect(mapStateToProps, mapPropsToDispatch)(ForgotPasswordContainer);



