import {attr, fk, Model} from 'redux-orm';
import {schema} from "normalizr";

import {updateEntities} from "../reducers/util";
import SessionActionType from "../actions/SessionActionType";
import CartActionType from "../actions/CartActionType";
import CartItem from './CartItem';
import OrderActionType from "../actions/OrderActionType";
import {belongsTo} from "./util";
import AddressActionType from "../actions/AddressActionType";


export class Cart extends Model
{
    static Schema = new schema.Entity('carts',
        {
            cartItems: new schema.Array(CartItem.Schema)
        },
        belongsTo('address')
    );


    static reducer(action, Cart, session)
    {
        const {payload, type} = action;

        switch (type) {
            case CartActionType.CURRENT_SUCCESS:
            case CartActionType.ADD_SUCCESS:
            case CartActionType.REMOVE_SUCCESS:
            case CartActionType.ADDRESS_SUCCESS:
            case AddressActionType.DELETE_SUCCESS:
            case SessionActionType.LOGIN_SUCCESS:
                Cart.all().delete();
                updateEntities(payload, 'carts', Cart);
                break;

            case OrderActionType.CREATE_SUCCESS:
            case SessionActionType.LOGOUT_SUCCESS:
                Cart.all().delete();
                break;

            case OrderActionType.CREATE_FAILURE:
                if (payload.result != null)
                {
                    Cart.all().delete();
                    updateEntities(payload, 'carts', Cart);
                }
                break;

            default:
                break;
        }
    }


    toString() {
        return `Cart: ${this.id}`;
    }


    getPrice() {
        if (this.cartItems == null)
        {
            return 0;
        }

        return this.cartItems.toModelArray().reduce((acc, x) => acc + x.getPrice(), 0);
    }
}


Cart.modelName = 'Cart';


Cart.fields = {
    id: attr(),
    address: fk('Address', 'address'),
};


export default Cart;