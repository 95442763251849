import React from 'react'
import {CaminhosCarousel} from "./CaminhosCarousel";
import {Helmet} from "react-helmet";
import {Header} from "../../headers/Header";


export const CollectionCaminhos = () => (
    <main>
        <Header title="Deriva / Coleções / Caminhos do Jardim"
                description="Caminhar num jardim de flores, parar e ver o tempo passar. Nossa coleção veio da vontade de olhar para a natureza como uma fonte de equilíbrio e belezas."
                image="https://www.somosderiva.com/img/collection/caminhos/carousel/1-lg.jpg"
                keywords="deriva estampa caminhos jardim poa listrado"
        />
        <CaminhosCarousel/>
        <div className="container margin-top-2x">
            <div className="row margin-bottom-1x">
                <div className="col-xs-12">
                    <h2>Caminhos do Jardim</h2>
                </div>
            </div>
            <div className="row margin-bottom-1x">
                <div className="col-md-12">
                    <p>
                        Nossa coleção Caminhos do Jardim foi batizada assim porque o seu desenvolvimento não seguiu uma
                        trilha linear, que muitas vezes supomos quando vemos o produto de um processo criativo. A
                        verdade é que raramente na vida as coisas são assim na arte – e na vida. Desconfio inclusive que
                        são as paradas e ajustes de rotas que nos permitem chegar a melhores destinos.
                    </p>
                    <p>
                        O desejo de criar uma estampa floral me acompanha há um bom tempo. Desde que a Deriva nasceu, na
                        verdade. Ao pensar na nossa terceira coleção, senti que havia chegado o momento de buscar essas
                        flores. Os últimos meses, um tanto caóticos, intensificaram a minha vontade de olhar para a
                        natureza, fonte de equilíbrio e paz. Também já havia um tempo que andava encantada com as
                        pinturas de impressionistas como Monet e Renoir. As obras me prendiam a atenção - em tempos de
                        isolamento domiciliar, essas pinturas nos fazem sonhar com a vida do lado de fora, ao ar livre,
                        cercada de verde e flores infinitas. Mesmo entre quatro paredes, muitas vezes imaginei como
                        seria viver aquela cena dos quadros: fazer nada em um jardim, ver o tempo passar.
                    </p>
                </div>
            </div>
        </div>
        <div className="container-fluid margin-bottom-1x margin-top-2x padding-none">
            <div className="">
                <div className="col-md-4 col-xs-4 margin-bottom-1x col-fluid-left">
                    <img src="/img/collection/caminhos/vangogh.jpg" alt=""/>
                </div>
                <div className="col-md-4 col-xs-4 margin-bottom-1x col-fluid-middle">
                    <img src="/img/collection/caminhos/monet.jpg" alt=""/>
                </div>
                <div className="col-md-4 col-xs-4 margin-bottom-1x col-fluid-right">
                    <img src="/img/collection/caminhos/room.jpg" alt=""/>
                </div>
            </div>
        </div>
        <div className="container margin-top-2x">
            <div className="row margin-bottom-2x">
                <div className="col-md-12">
                    <p>
                        Comecei então a desenhar flores livremente... Experimentei criá-las com caneta, com aquarela,
                        lápis de cor... Foram muitas as tentativas, mas nenhuma me entusiasmava. Até que resolvi
                        construir essas flores de uma forma diferente: com recortes de papel. Deu certo. Aos poucos fui
                        cortando e colando pétalas e miolos. Tudo saído da imaginação mesmo (que nenhum botânico venha
                        aqui me perguntar as espécies dessas flores). Foi aí que o nosso jardim floriu. A primavera
                        chegou em junho, durante a quarentena.
                    </p>
                    <p>
                        Colhidas as flores, era hora de criar o arranjo (leia-se montar a estampa). Tudo fez sentido:
                        não tinha como não lembrar das flores que meu pai sempre trazia para alegrar a casa ou celebrar
                        datas especiais como aniversários. Assim, eu e Gabi, mesmo fisicamente distantes, criamos as
                        estampas desta coleção lado a lado, unidas por essa memória comum.
                    </p>
                </div>
            </div>
        </div>
        <div className="container-fluid margin-bottom-1x margin-top-2x padding-none row">
            <div className="margin-bottom-1x col-fluid" style={{"width": "34%"}}>
                <img src="/img/collection/caminhos/arranjo.jpg" alt=""/>
            </div>
            <div className="margin-bottom-1x col-fluid" style={{"width": "22%"}}>
                <img src="/img/collection/caminhos/proc1.png" alt=""/>
            </div>
            <div className="margin-bottom-1x col-fluid" style={{"width": "22%"}}>
                <img src="/img/collection/caminhos/proc2.png" alt=""/>
            </div>
            <div className="margin-bottom-1x col-fluid" style={{"width": "22%"}}>
                <img src="/img/collection/caminhos/proc3.png" alt=""/>
            </div>
        </div>
        <div className="container margin-top-2x">
            <div className="row margin-bottom-2x">
                <div className="col-md-12">
                    <h5>"Nem tudo são flores"</h5>
                    <p>
                        Inspiradas nas obras dos mestres impressionistas, partimos de cores que normalmente observamos
                        em seus quadros. Mas nenhuma combinação parecia funcionar. Mesmo gostando da estampa que
                        começava a tomar forma, eu e Gabi não conseguíamos chegar em um consenso sobre as cores. Foi um
                        pós-impressionista, Van Gogh, quem nos apontou o caminho. Suas pinturas com tons de amarelo ocre
                        e alaranjados terrosos em contraste com azul nos convenceram de que nossas estampas também
                        deveriam ser parte daquele universo. Mas, ainda um pouco apreensivas, pedimos a opinião de
                        amigos. Em uma das conversas, um comentário nos chamou a atenção: essas cores parecem muito com
                        o interior de um palácio japonês. Fomos atrás de fotos e descobrimos que a paleta de cores era
                        mesmo bastante similar: dourados, ocres, tons de verde e azuis meio “queimados”, tal qual
                        havíamos colorido a nossa estampa Jardineira. Curiosas com as semelhanças, bastou um pouco de
                        pesquisa para descobrirmos que Van Gogh (aquele que me ajudou com as cores) havia estudado com
                        afinco ilustrações japonesas. Assim, o artista passou a adotar em seus trabalhos características
                        estéticas que observou nas centenas de gravuras japonesas que colecionou.
                        Nossas “andanças” nos proporcionaram a alegria de colecionar essas pequenas e inesperadas
                        descobertas que somaram significados à nossa história.
                    </p>
                    <p>
                        Enfim estávamos contentes no jardim ao qual chegamos: a estampa Jardineira estava pronta. Como
                        um tipo de estampa-mãe, a partir de seus elementos cores e histórias, deu vida à 3 estampas:
                        Crisântemo, mosquitinho e listrado.
                    </p>
                </div>
            </div>
        </div>
        <div className="container-fluid padding-none">
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/caminhos/pic0.jpg" alt=""/>
                </div>
            </div>
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/caminhos/pic1.jpg" alt=""/>
                </div>
            </div>
            <div className="">
                <div className="col-xs-12 margin-bottom-1x padding-none">
                    <img src="/img/collection/caminhos/pic2.jpg" alt=""/>
                </div>
            </div>
            <div className="margin-bottom-none">
                <div className="col-xs-12 padding-none">
                    <img src="/img/collection/caminhos/pic3.jpg" alt=""/>
                </div>
            </div>
        </div>
    </main>
);