import React from 'react'
import {Url} from "../../util/Url";
import {Link} from "react-router-dom";
import {CartItemAmount} from "./CartItemAmount";
import ProductSelector from "../../selectors/ProductSelector";
import {useSelector} from "react-redux";


export const CartItem = ({cartItem}) => {
    const product = useSelector((s) => ProductSelector.productByVariation(s, cartItem.productVariationId));

    if (cartItem == null || product == null) {
        return null;
    }

    let priceTotal = product.price * cartItem.amount;
    let productVariation = product.productVariations.find(x => x.id === cartItem.productVariationId);

    return (
        <div className="row cart-item">
            <div className="col-sm-2 col-xs-4">
                <Link to={Url.Shop.Product(product.link)}>
                    <img src={product.picturesShop[0]} alt="" style={{maxHeight: 165, maxWidth: "100%", width: "auto"}}/>
                </Link>
            </div>
            <div className="col-sm-4 col-xs-8">
                <div className="title">
                    <Link to={Url.Shop.Product(product.link)}>
                        {product.name}
                    </Link>
                </div>
                <div className="margin-bottom-1x">
                    <Link to={Url.Collection(product.pattern.collection.link)} className="category">
                        {product.patternString}
                    </Link>
                </div>
                <div className="margin-bottom-1x">
                    <div className="size black">
                        Tamanho<br/>
                        <span>{productVariation.size}</span>
                    </div>
                </div>
            </div>

            <div className="clearfix visible-xs-block margin-bottom-2x"></div>

            <div className="col-sm-3 col-xs-7">
                <CartItemAmount cartItem={cartItem}/>
            </div>
            <div className="col-sm-3 col-xs-5 cart-item-cost">
                R$ {priceTotal}
            </div>
        </div>
    );
};
