import React from 'react';
import Media from 'react-media';
import {BeiraMarMediaCarousel} from "./BeiraMarMediaCarousel";


export const BeiraMarCarousel = () => {

    return <>
        <Media query="(max-width: 768px)" render={() => <BeiraMarMediaCarousel media="xs"/>}/>
        <Media query="(min-width: 768px) and (max-width: 991px)" render={() => <BeiraMarMediaCarousel media="sm"/>}/>
        <Media query="(min-width: 992px) and (max-width: 1199px)" render={() => <BeiraMarMediaCarousel media="md"/>}/>
        <Media query="(min-width: 1200px)" render={() => <BeiraMarMediaCarousel media="lg"/>}/>
    </>;
};
