import moment from "moment";
import {computeDiscountProducts} from "../models/Order";

export class PayPalClient {

    static sandbox_url = "https://api-m.sandbox.paypal.com/v1/";
    static api_url = "https://api-m.paypal.com/v1/";
    static requests = {};

    static authenticationData = null;
    static authenticationExpiresAt = moment();


    static async authenticate() {

        if (this.authenticationData != null) {
            const now = moment();

            if (this.authenticationExpiresAt > now) {
                return;
            }

            this.authenticationData = null;
        }

        const endpoint = "oauth2/token";
        const url = this.prepareRequest(endpoint);

        const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_PAYPAL_CLIENT_SECRET;

        const response = await fetch(url, {
            method: 'POST',
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'max-age=60',
                'Authorization': 'Basic ' + Buffer.from(`${clientId}:${clientSecret}`).toString('base64')
            },
            referrerPolicy: 'no-referrer',
            body: new URLSearchParams({
                'grant_type': 'client_credentials'
            }),
            signal: this.requests[endpoint].signal
        });

        this.releaseRequest(endpoint);

        this.authenticationData = await response.json();
        this.authenticationExpiresAt = moment().add(this.authenticationData.expires_in, "seconds");
        console.log(this.authenticationData, this.authenticationExpiresAt);
    }


    static async createPaymentRequest(user, cart, shipping, coupon) {

        if (user == null || cart == null || shipping == null) {
            return null;
        }

        const address = cart.address;

        let priceTotal = cart.price;
        const hasCoupon = coupon != null;

        let discount = 0;
        let discountProducts = 0;

        if (hasCoupon) {

            discountProducts = computeDiscountProducts(priceTotal, coupon);
            priceTotal -= discountProducts;

            discount += discountProducts;
        }

        priceTotal += shipping.price;

        const data = {
            intent: "sale",
            payer: {
                payment_method: "paypal"
            },
            transactions: [{
                amount: {
                    currency: "BRL",
                    total: priceTotal,
                    details: {
                        shipping: shipping.price,
                        subtotal: cart.price,
                        discount: discount
                        // shipping_discount: "1.00",
                        // insurance: "1.00",
                        // handling_fee: "1.00",
                        // tax: "6.00"
                    }
                },
                description: "Deriva",
                payment_options: {
                    allowed_payment_method: "IMMEDIATE_PAY"
                },
                item_list: {
                    shipping_address: {
                        recipient_name: user.name,
                        line1: address.street + ', ' + address.number,
                        line2: address.complement,
                        city: address.city,
                        state: address.state,
                        // phone: "0800-761-0880",
                        country_code: "BR",
                        postal_code: address.zipcode.replace(".", ""),
                    },
                    items: cart.cartItems.map(cartItem => {
                        return {
                            name: cartItem.productVariation.product.name,
                            description: cartItem.productVariation.size,
                            sku: cartItem.productVariation.sku,
                            quantity: cartItem.amount,
                            price: cartItem.productVariation.product.price,
                            currency: "BRL"
                        };
                    })
                }
            }],
            redirect_urls: {
                return_url: "https://somosderiva.com/return",
                cancel_url: "https://somosderiva.com/cancel"
            }
        }

        const result = await this.postRequest("payments/payment", data);

        const paymentId = result.id;

        const approvalUrl = result.links.find(x => x.rel === "approval_url");

        if (approvalUrl == null) {
            return null;
        }

        return {
            paymentId,
            approvalUrl: approvalUrl.href
        };
    }


    static async executePayment(paymentId, payerId)
    {
        const data = {
            payer_id: payerId
        };

        const result = await this.postRequest(`payments/payment/${paymentId}/execute/`, data);

        const approved = result != null && result.state === "approved";

        if (!approved)
        {
            throw new Error(result.message);
        }

        return approved;
    }


    static async postRequest(endpoint, data = {}) {
        await this.authenticate();

        const url = this.prepareRequest(endpoint);

        const response = await fetch(url, {
            method: 'POST',
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'max-age=60',
                'Authorization': `Bearer ${this.authenticationData == null ? null : this.authenticationData.access_token}`
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data),
            signal: this.requests[endpoint].signal
        });

        this.releaseRequest(endpoint);

        return response.json(); // parses JSON response into native JavaScript objects
    }


    static prepareRequest(endpoint) {
        const uri = (process.env.NODE_ENV === 'production') ? this.api_url : this.sandbox_url;
        const url = uri + endpoint;// + "?" + new URLSearchParams(data);

        if (this.requests[endpoint] != null) {
            this.requests[endpoint].abort();
        }

        this.requests[endpoint] = new AbortController();

        return url;
    }


    static releaseRequest(endpoint) {
        this.requests[endpoint] = null;
    }
}