import React from 'react'
import {Link} from "react-router-dom";
import {Url} from "../../util/Url";


const Result = () => (
    <main>
        <section className="fw-section padding-bottom-10x" style={{backgroundImage: "url(/img/contact/bg.jpg)"}}>
            <div className="container text-center padding-top-3x padding-bottom-10x">
                <h2 className="text-light margin-bottom-2x">Muito obrigada!</h2>
                <h4 className="text-light text-italic margin-bottom-2x">
                    Muito obrigada por entrar em contato conosco. Retornaremos assim que possível!
                </h4>

                <Link to={Url.Home} className="btn btn-default margin-right-none">Voltar</Link>
            </div>
        </section>
    </main>
);


export default Result;