class FieldNormalizer {

    static phone(value, previousValue) {
        if (!value) {
            return value
        }

        const numbers = FieldNormalizer.numeric(value);

        if (numbers.length === 0) {
            return "";
        }

        if (numbers.length === 1) {
            return "(" + numbers;
        }

        if (!previousValue || value.length > previousValue.length) {

            if (numbers.length === 2) {
                return "(" + numbers + ") ";
            }
        }
        else {
            if (numbers.length === 2) {
                return "(" + numbers;
            }
        }

        if (numbers.length >= 3) {
            return "(" + numbers.slice(0, 2) + ") " + numbers.slice(2, 11);
        }
    }


    static cpf(value, previousValue) {
        if (!value) {
            return value
        }

        const numbers = FieldNormalizer.numeric(value);

        if (numbers.length === 0) {
            return "";
        }

        if (!previousValue || value.length > previousValue.length) {

            if (numbers.length >= 3 && numbers.length < 6) {
                return numbers.slice(0, 3) + "." + numbers.slice(3, 6);
            }

            if (numbers.length >= 6 && numbers.length < 9) {
                return numbers.slice(0, 3) + "." + numbers.slice(3, 6) + "." + numbers.slice(6, 9);
            }

            if (numbers.length >= 9) {
                return numbers.slice(0, 3) + "." + numbers.slice(3, 6) + "." + numbers.slice(6, 9) + "-" + numbers.slice(9, 11);
            }
        }
        else {
            if (numbers.length > 9) {
                return numbers.slice(0, 3) + "." + numbers.slice(3, 6) + "." + numbers.slice(6, 9) + "-" + numbers.slice(9, 11);
            }

            if (numbers.length > 6) {
                return numbers.slice(0, 3) + "." + numbers.slice(3, 6) + "." + numbers.slice(6, 9);
            }

            if (numbers.length > 3) {
                return numbers.slice(0, 3) + "." + numbers.slice(3, 6);
            }
        }

        return numbers;
    }


    static numeric(value) {
        if (!value) {
            return value
        }

        return value.replace(/[^\d]/g, '');
    }


    static day(value)
    {
        if (value != null)
        {
            value = value.substr(0, 2);
        }

        return clampFieldValue(value, 1, 31);
    }


    static month(value)
    {
        if (value != null)
        {
            value = value.substr(0, 2);
        }

        return clampFieldValue(value, 1, 12);
    }


    static year(value)
    {
        if (value != null)
        {
            value = value.substr(0, 4);
        }

        return clampFieldValue(value, 1, new Date().getFullYear());
    }



    static zipcode(value, previousValue) {
        if (!value) {
            return value;
        }

        const numbers = FieldNormalizer.numeric(value);

        if (numbers.length === 0) {
            return "";
        }

        if (!previousValue || value.length > previousValue.length) {

            if (numbers.length >= 2 && numbers.length < 5) {
                return numbers.slice(0, 2) + "." + numbers.slice(2, 5);
            }

            if (numbers.length >= 5) {
                return numbers.slice(0, 2) + "." + numbers.slice(2, 5) + "-" + numbers.slice(5, 8);
            }
        }
        else {

            if (numbers.length > 5) {
                return numbers.slice(0, 2) + "." + numbers.slice(2, 5) + "-" + numbers.slice(5, 8);
            }

            if (numbers.length > 2) {
                return numbers.slice(0, 2) + "." + numbers.slice(2, 5);
            }
        }

        return numbers;
    }

}


export default FieldNormalizer;


function clampFieldValue(value, min, max)
{
    const numbers = FieldNormalizer.numeric(value);
    const n = parseInt(numbers);

    if (isNaN(n))
    {
        return "";
    }

    if (n === 0)
    {
        return "";
    }

    return Math.min(Math.max(min, n), max)
}