import React from 'react';
import {ImageCarousel} from "../carousel/ImageCarousel";



export const CaminhosMediaCarousel = ({media}) => {

    const images = [
        `/img/collection/caminhos/carousel/1-${media}.jpg`,
        // `/img/slider/2-${media}.jpg`,
        // `/img/slider/3-${media}.jpg`,
    ];

    return <ImageCarousel images={images} name={`caminhos-carousel-${media}`}/>
};