import ProductSelector from "../selectors/ProductSelector";
import {createApiAction} from "./api/util";
import ProductActionType from "./ProductActionType";
import Collection from "../models/Collection";



class ProductAction
{

    static getProduct(link)
    {
        return (dispatch, getState) => {

            let product = ProductSelector.product(getState(), link);

            if (product != null) {
                return Promise.resolve(product);
            }

            return dispatch(this.loadProduct(link));
        }
    }


    static loadProduct(link)
    {
        return createApiAction(ProductActionType.LOAD_PREFIX, 'GET', `products/${link}`, null, Collection.Schema);
    }
}



export default ProductAction;