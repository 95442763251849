import {fk, attr, Model} from 'redux-orm';
import {schema} from "normalizr";

import {updateEntities} from "../reducers/util";
import {belongsTo} from "./util";
import CollectionActionType from "../actions/CollectionActionType";
import ProductActionType from "../actions/ProductActionType";


export class ProductVariation extends Model
{
    static Schema = new schema.Entity('productVariations',
        {
        },
        belongsTo('product')
    );


    static reducer(action, ProductVariation, session)
    {
        const {payload, type} = action;

        switch (type) {
            case CollectionActionType.ALL_SUCCESS:
            case ProductActionType.LOAD_SUCCESS:
                updateEntities(payload, 'productVariations', ProductVariation);
                break;

            default:
                break;
        }
    }


    toString() {
        return `Product Variation: ${this.id} - ${this.size}`;
    }
}


ProductVariation.modelName = 'ProductVariation';


ProductVariation.fields = {
    id: attr(),
    product_id: attr(),
    sku: attr(),
    size: attr(),
    inventory: attr(),
    product: fk('Product', 'productVariations')
};


export default ProductVariation;