import React from 'react'
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import Validator, {formatError} from "../../../util/validator";
import FieldComponent from "../../util/FieldComponent";
import {Link} from "react-router-dom";
import {Url} from "../../../util/Url";
import {Helmet} from "react-helmet";
import {Header} from "../../../headers/Header";


class ConfirmationResend extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        asyncErrors: PropTypes.object,
    };


    static defaultProps = {
        onSubmit: x => {
            console.log(x);
        },
        asyncErrors: null
    };


    render() {
        const {handleSubmit, submitting} = this.props;

        let error = formatError(this.props.error);

        return (
            <main>
                <Header title="Deriva / Confirmação / Reenviar"
                        description="Digite o seu e-mail abaixo para reenviarmos o código de confirmação"
                />
                <div className="container text-center margin-top-2x margin-bottom-4x">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <img src="/img/login_02.png" style={{width: "auto"}} alt=""/>
                            <h5>Reenvie o e-mail de confirmação</h5>
                            <p>Digite o seu e-mail abaixo para reenviarmos o código de confirmação.</p>

                            <div className="row margin-top-1x">
                                <div className="col-md-8 col-md-offset-2">
                                    <form id="formConfirmationResend" onSubmit={handleSubmit}>

                                        <div className="margin-bottom-1x">
                                            <Field name="email" type="text" component={FieldComponent}
                                                   className="form-control text-center"
                                                   placeholder="E-mail"
                                                   validate={[Validator.Required, Validator.Email]}
                                            />
                                        </div>

                                        {error}

                                        <div className="margin-top-1x">
                                            <button type="submit"
                                                    className="btn btn-default btn-block margin-right-none"
                                                    style={{width: "100%"}} disabled={submitting}>Enviar
                                            </button>
                                        </div>
                                    </form>
                                    <div className="margin-top-2x text-left">
                                        <Link to={Url.Account.Confirmation} className="underline-link pull-left">
                                            Já recebeu o código de confirmação?
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}


ConfirmationResend = reduxForm({
    form: 'confirmationResend'
})(ConfirmationResend);


export default ConfirmationResend;