

export default class OrderStatus {
    static created = "created";
    static payed = "payed";
    static sent = "sent";
    static delivered = "delivered";
    static cancelled = "cancelled";
    static returned = "returned";

}