import React from "react";
import {AddressCard} from "../../../address/AddressCard";
import moment from "moment/moment";
import 'moment/locale/pt-br';
import OrderStatus from "../../../../models/OrderStatus";
import {Copyable} from "./Copyable";


export const PanelShipping = ({order}) => {

    const shipping = order.shipping;
    const payment = order.payment;

    const createHeaderDiv = (label, date) => {
        const m = moment(date);
        m.locale("pt-br");

        return <>{label}:&nbsp;
            <span className="black">
                {date == null ? `${shipping.time} dia${shipping.time > 1 ? "s" : ""}` : m.format("D [de] MMMM")}
            </span>
            &nbsp;&mdash;&nbsp;{shipping.name}
        </>;
    }

    const createTrackingDiv = (fallback) => {
        if (shipping.method !== "delivery" || shipping.name === "Entrega Local") {
            return null;
        }

        if (order.trackingNumber == null || order.trackingNumber === "") {
            return fallback;
        }

        return <>
            <div className="margin-bottom-1x">
                Código de rastreamento:<br/>
                <Copyable value={order.trackingNumber}/>
            </div>
            <div>
                Acompanhe o envio no site dos <a
                href="https://www2.correios.com.br/sistemas/rastreamento/" target="_blank"
                rel="noopener noreferrer"><u>Correios</u></a>.
            </div>
        </>;
    }

    // order.trackingNumber = "AA123456785BR";

    const sentAt = shipping.sentAt;
    const deliveredAt = shipping.deliveredAt;

    let date;
    let header = null;
    let tracking = null;

    switch (order.status) {
        case OrderStatus.created:
            header = createHeaderDiv("Previsão de entrega");
            tracking = null;
            break;

        case OrderStatus.payed:
            date = moment(payment.approvedAt);
            date.add(shipping.time, "days");

            header = createHeaderDiv("Previsão de entrega", date);
            tracking = createTrackingDiv(<><br/>Em breve enviaremos o código de rastreamento.</>);
            break;

        case OrderStatus.sent:
            date = moment(sentAt);
            date.add(shipping.time, "days");
            // date.add(-2, "days"); // Tempo de preparo

            header = createHeaderDiv("Previsão de entrega", date);
            tracking = createTrackingDiv(<><br/>Em breve enviaremos o código de rastreamento.</>);
            break;

        case OrderStatus.delivered:
        case OrderStatus.returned:
            header = createHeaderDiv("Entregue em", deliveredAt);
            tracking = createTrackingDiv(null);
            break;

        case OrderStatus.cancelled:
            if (deliveredAt != null) {
                header = createHeaderDiv("Entregue em", deliveredAt);
                tracking = createTrackingDiv(null);
            } else if (sentAt != null) {
                date = moment(sentAt);
                date.add(shipping.time, "days");
                // date.add(-2, "days"); // Tempo de preparo

                header = createHeaderDiv("Previsão de entrega", date);
                tracking = createTrackingDiv(null);
            } else {
                header = <>Seu pedido foi cancelado antes de ser enviado.</>;
            }
            break;
    }


    return <div className="row">
        <div className="col-xs-12">
            {header}
        </div>
        {shipping.method === "delivery" ?
            <>
                <div className="col-md-6 col-xs-12 margin-top-1x">
                    Endereço:<br/>
                    <AddressCard address={order.address}/>
                </div>
                <div className="col-md-6 col-xs-12 margin-top-1x">
                    {tracking}
                </div>
            </>
            :
            <div className="col-xs-12 margin-top-1x">
                Você optou por retirar o seu pedido.
                { order.status != OrderStatus.delivered && order.status != OrderStatus.cancelled ?
                    <>&nbsp;Entraremos em contato assim que ele estiver pronto.</> : null
                }
            </div>
        }
    </div>
};