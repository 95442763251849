import {createApiAction} from "./api/util";

import Collection from "../models/Collection";
import CollectionSelector from "../selectors/CollectionSelector";
import CollectionActionType from "./CollectionActionType";
import {schema} from "normalizr";



class CollectionAction
{

    static getAll() {

        return (dispatch, getState) => {
            let collections = CollectionSelector.all(getState());

            if (collections != null) {
                return Promise.resolve(collections);
            }

            return dispatch(this.loadAll());
        }
    }


    static loadAll() {
        return createApiAction(CollectionActionType.ALL_PREFIX, 'GET', 'collections/all', null, new schema.Array(Collection.Schema));
    }
}



export default CollectionAction;